import React from 'react';
import { Typography, useTheme } from '@mui/material';

const WelcomeTourSteps = () => {
  const theme = useTheme();
  return [
    {
      content: (
        <>
          <Typography variant="h6">Welcome to SE Mod BoK!</Typography>
          <Typography>
            You can begin here with a keyword search or...
          </Typography>
        </>
      ),
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      selector: '[tour="search-bar"]',
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Cross-Cutting Enablers</Typography>
          <Typography>
            <b>Choose one more many cross-cutting enablers.</b>
          </Typography>
        </>
      ),
      selector: '[tour="cross-cutting-enabler-menu"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        node.focus();
      },
    },
    {
      content: (
        <>
          <Typography variant="h6">Select an area</Typography>
          <Typography>
            <b>Choose an Area of interest</b>
          </Typography>
        </>
      ),

      selector: '[tour="area-menu-list"]',
      style: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      action: (node) => {
        node.focus();
      },
    },
  ];
};

export default WelcomeTourSteps;
