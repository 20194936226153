import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, useTheme } from '@mui/material';
import ValidationCheckList from '../ValidationCheckList';

const UserField = ({
  state,
  setState,
  id,
  label,
  errorText,
  requiredLength,
  isUsernameTaken,
  ...otherProps
}) => {
  const [error, setError] = useState(false);

  const fontColor = error ? 'red' : '#A9A9A9';

  const validateUserName = (userNameLength) => {
    if (userNameLength >= requiredLength) {
      return true;
    }
    return false;
  };

  const handleChange = (event) => {
    setState({
      value: event.target.value,
      valid: validateUserName(event.target.value.length),
    });
  };

  const handleOnBlur = (event) => {
    if (event.target.value.trim() !== '') {
      setError(!validateUserName(event.target.value.length));
    }
  };
  const theme = useTheme();
  const greenShade = theme.palette.mode === 'dark' ? '#99FF99' : 'green';
  const redShade = theme.palette.mode === 'dark' ? '#6f0000' : 'red';

  return (
    <>
      <TextField
        variant="outlined"
        required
        fullWidth
        placeholder="Username"
        id={id}
        label={label}
        aria-label={label}
        onChange={handleChange}
        onBlur={handleOnBlur}
        error={error}
        helperText={error ? errorText : ''}
        {...otherProps}
      />
      <Grid container spacing={0}>
        {isUsernameTaken ?
          (
            <ValidationCheckList color={redShade}>
              Username is currently taken.
            </ValidationCheckList>
          ) :
          (

            <ValidationCheckList color={state.valid ? greenShade : fontColor}>
              Username must be at least 6 characters.
            </ValidationCheckList>
          )
        }

      </Grid>
    </>
  );
};

UserField.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  requiredLength: PropTypes.number,
  isUsernameTaken: PropTypes.bool
};

UserField.defaultProps = {
  errorText: undefined,
  id: undefined,
  label: undefined,
  requiredLength: undefined,
  isUsernameTaken: false
};

export default UserField;
