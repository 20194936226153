import SortIcon from '@mui/icons-material/Sort';
import { Button as MatButton, List, ListItem } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { CPARS_AWARD_VALUE } from '../../constants/assetFields';
import { NO_VALUE_DEFINED_IN_OTMM } from '../../constants/constants';
import { setSelectedFilters } from '../../models/otmmModel/functions/updateState';
import CheckboxFilter from '../checkboxFilter/CheckboxFilter';
import ShowMoreOrLessFacet from '../common/ShowMoreOrLessFacet';
import Pop from '../pop/Pop';
import './FilterItems.css';

const createOnClick =
  (filter, expanded, expandedFilters, setExpandedFilters) => () => {
    if (expanded) {
      setExpandedFilters(
        expandedFilters.filter((value) => value !== filter.id)
      );
    } else {
      setExpandedFilters([...expandedFilters, filter.id]);
    }
  };

const COUNT = 'count';
const NAME = 'value.text';

const getIconName = (type, asc = false) => {
  if (type === NAME) {
    return asc ? 'sort alphabet up' : 'sort alphabet down';
  }
  return asc ? 'sort amount up' : 'sort amount down';
};

const FilterItems = ({ active, filter }) => {
  const [expandedFilters, setExpandedFilters] = useState([]);
  const [filterSort, setFilterSort] = useState([]);

  const rangeFilters = [CPARS_AWARD_VALUE];
  const onSort = useCallback(
    (type) => {
      const currentFilterSort = [...filterSort];
      const sort = _.chain(currentFilterSort)
        .remove(({ id }) => id === filter.id)
        .first()
        .defaultTo({ id: filter.id, type, asc: true })
        .value();
      const asc = !sort.asc;

      setFilterSort([
        ...currentFilterSort,
        { ...sort, type, asc, name: getIconName(type, asc) },
      ]);
    },
    [filter.id, filterSort, setFilterSort]
  );

  const onSortCount = useCallback(() => {
    onSort(COUNT);
  }, [onSort]);

  const onSortName = useCallback(() => {
    onSort(NAME);
  }, [onSort]);

  if (!rangeFilters.includes(filter.id)) {
    const expanded = expandedFilters.includes(filter.id);
    const max = 8;
    const showExpand = filter.values.length > max;
    const currentSort = _.find(filterSort, { id: filter.id });

    const unorderedValues = filter.values;
    const undefinedIndex = _.findIndex(unorderedValues, [
      'value.text',
      NO_VALUE_DEFINED_IN_OTMM,
    ]);
    if (undefinedIndex !== -1) {
      unorderedValues.push(unorderedValues.splice(undefinedIndex, 1)[0]);
    }

    let sorted = unorderedValues;
    if (currentSort) {
      sorted = _.sortBy(unorderedValues, [currentSort.type]);
      if (!currentSort.asc) {
        sorted.reverse();
      }
    }

    const visibleValues =
      showExpand && !expanded
        ? sorted.slice(0, sorted[max] ? max : sorted.length)
        : sorted;
    const items = visibleValues.map((value, index) => (
      <CheckboxFilter // eslint-disable-next-line react/no-array-index-key
        key={index}
        filterValue={value}
        filter={filter}
        // selectedFilters={selectedFilters}
        // setSelectedFilters={setSelectedFilters}
      />
    ));

    if (!active) {
      return null;
    }

    if (showExpand) {
      items.push(
        <ListItem key={visibleValues.length + 1}>
          <ShowMoreOrLessFacet
            isExpanded={expanded}
            onClick={createOnClick(
              filter,
              expanded,
              expandedFilters,
              setExpandedFilters
            )}
          />
        </ListItem>
      );
    }

    if (showExpand) {
      items.unshift(
        <ListItem key={Date.now()}>
          <Pop
            type="button"
            id={Date.now()}
            content={
              <>
                <MatButton onClick={onSortCount}>Count</MatButton>
                <MatButton onClick={onSortName}>AZ</MatButton>
              </>
            }
            endIcon={<SortIcon />}
            fullWidth
          >
            Sort
          </Pop>
        </ListItem>
      );
    }
    return <List>{items}</List>;
  }

  return null;
};

FilterItems.propTypes = {
  active: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  isSearching: state.otmmState.loading,
  filters: state.otmmState.filters.allFilters,
  selectedFilters: state.otmmState.filters.selected,
});

export default connect(mapStateToProps, { setSelectedFilters })(FilterItems);
