// Model Ids
export const GOVERNMENT_MODEL_ID = 'CAPD_GOVERNMENT_OPPORTUNITY_MODEL';
export const OPPORTUNITY_MODEL_ID = 'STG_MODEL_KP_OPPS';
export const CRWS_MODEL_ID = 'DIMSD.MODEL.RESOURCES';
export const CRWS_PREAPPROVAL = 'DIMSD.MODEL.CRWS.PREAPPROVAL';
export const GUIDED_SEARCH = 'CRWS.DOMAIN.GUIDED_SEARCH_OPTIONS';

// Hardcoded Labels
export const DATA_TYPE_LABEL = 'Data Type';

// Shared Asset Fields
export const ASSET_TYPE = 'ARTESIA.FIELD.MODEL';
export const ASSET_ID = 'ASSET_ID';
export const METADATA_MODEL_ID = 'METADATA_MODEL_ID';
export const ASSET_SUBSCRIBED_TO = 'ASSET_SUBSCRIBED_TO';
export const REFINED_TOPICS = 'STG_TAB_FIELD_REFINED_TOPIC';

export const CPARS_AWARD_VALUE = 'STG.FIELD.CPARS_AWD_VAL';

// Keystone Pursuits Asset Fields
export const KEYSTONE_PURSUIT_OPPORTUNITY_NAME = 'KP_OPPORTUNITY_NAME';
export const KEYSTONE_PURSUIT_CRM = 'KP_CRM';
export const KEYSTONE_PURSUIT_CAPTURE_MANAGER = 'KP_CAPTURE_MANAGER';
export const KEYSTONE_PURSUIT_CODE_NAME = 'KP_CODE_NAME';
export const KEYSTONE_PURSUIT_CG_PRIORITY = 'KP_CG_PRIORITY';
export const KEYSTONE_PURSUIT_CUSTOMER_GROUP = 'KP_CUSTOMER_GROUP';
export const KEYSTONE_PURSUIT_PRACTICE_AREA = 'KP_PRACTICE_AREA';
export const KEYSTONE_PURSUIT_PROPOSAL_DATE = 'KP_PROPOSAL_DATE';
export const KEYSTONE_PURSUIT_RFP_DATE = 'KP_RFP_DATE';
export const KEYSTONE_PURSUIT_SUB_PRIME = 'KP_SUB_PRIME';
export const KEYSTONE_PURSUIT_VP_PROGRAMS = 'KP_VP_PROGRAMS';
export const KEYSTONE_PURSUIT_APPROVAL_AUTHORITY = 'KP_APPROVAL_AUTHORITY';
export const KEYSTONE_PURSUIT_NUMBER_OF_SERVICES = 'KP_NUM_SERVICES';
export const KEYSTONE_PURSUIT_NUMBER_OF_REUSE_MATERIALS =
  'KP_NUM_REUSE_MATERIALS';
export const KEYSTONE_PURSUIT_DESCRIPTION_OF_WORK = 'KP_DESCRIPTION_OF_WORK';
export const KEYSTONE_PURSUIT_AWARD_DATE = 'KP_AWARD_DATE';
export const KEYSTONE_PURSUIT_STATUS = 'KP_STATUS';
export const KEYSTONE_PURSUIT_VALUE = 'KP_VALUE';
export const KEYSTONE_PURSUIT_REFINED_TOPICS = 'STG_TAB_FIELD_REFINED_TOPIC';

// constraints CRWS
export const CRWS_TITLE = 'MSIFT.FIELD.BOK_TITLE';
export const SOURCE_NAME_FULL = 'CRWS.TAB.SOURCE_NAME';
export const OPR_SHORT = 'CRWS.TAB.SOURCE_SHORT';
export const DATE_EFFECTIVE = 'CRWS.TAB.VER_DATE';
export const VERSON = 'CRWS.TAB.VER';
export const USER_RATING = 'CRWS.FIELD.USER_RATING';
export const CONTENT_MATURITY = 'CRWS.FIELD.CONTENT_MATURITY';
export const RESOURCE_TYPE = 'CRWS.TAB.FIELD.RESOURCE_TYPE';
export const DOMAIN = 'CRWS.TAB.FIELD.DOMAIN_TYPE';
export const DOMAIN_SHORT = 'CRWS.TAB.FIELD.DOMAIN_ABBR';
export const CONTROLLED_ACCESS = 'MSIFT.FIELD.BOK_RES_ACCESS';
export const SHORT_NAME = 'MSIFT.FIELD.BOK_SUBJECT';
export const CRWS_ABSTRACT = 'MSIFT.FIELD.BOK_ABSTRACT';
export const CRWS_TOPIC = 'MSIFT.FIELD.BOK_TOPIC_NAME';
export const CRWS_URL = 'CRWS.TAB.SOURCE_URL';
export const CRWS_TOTAL_USER_RATINGS = 'CRWS.FIELD.TOTAL_USER_RATINGS';
export const CRWS_AUTHOR = 'MSIFT.FIELD.BOK_AUTHOR_NAME';
export const CRWS_AUTHOR_DEPT = 'MSIFT.FIELD.BOK_AUTHOR_DEPT';
export const CRWS_AUTHOR_TITLE = 'MSIFT.FIELD.BOK_AUTHOR_TITLE';
export const CRWS_AUTHOR_NOTES = 'MSIFT.FIELD.BOK_AUTHOR_NOTES';

export const CRWS_SOURCE_URL = 'CRWS.FIELD.SOURCE_URL';
export const CRWS_COPYRIGHT = 'MSIFT.FIELD.BOK_COPYRIGHT';
export const CRWS_JUSTIFICATION = 'CRWS.FIELD.JUSTIFICATION';
export const CRWS_PREAPPROVAL_STATUS = 'DIMSD.FIELD.CRWS_PREAPPROVAL_STATUS';
export const CRWS_ORIGINAL_COPYRIGHT_HOLDER_NAME =
  'CRWS.FIELD.ORIGINAL_COPYRIGHT_HOLDER';
export const CRWS_MATURITY_COLOR = 'CRWS.FIELD.CONTENT_MATURITY_COLOR';
export const CONTENT_MATURITY_REASON = 'CRWS.FIELD.CONTENT_MATURITY_REASON';

export const CRWS_REVIEW_START = 'CRWS.FIELD.REVIEW.START';
export const CRWS_REVIEW_END = 'CRWS.FIELD.REVIEW.END';
export const CRWS_REVIEW_DATE = 'CRWS.TAB.REVIEW.DATE';
export const CRWS_REVIEW_USER_ID = 'CRWS.TAB.REVIEW.USER_ID';
export const CRWS_REVIEW_APPROVAL = 'CRWS.TAB.REVIEW.APPROVAL';
export const CRWS_REVIEW_COMMENTS = 'CRWS.TAB.REVIEW.COMMENTS';
export const SEMOD_AREA = 'SEMOD.TAB.FIELD.AREA';

/** References */
export const REFERENCES_TYPE = 'CRWS.TAB.REFERENCES.TYPE';
export const REFERENCES_TITLE = 'CRWS.TAB.REFERENCES.TITLE';
export const REFERENCES_AUTHOR = 'CRWS.TAB.REFERENCES.AUTHOR';
export const REFERENCES_PUBLISH_DATE = 'CRWS.TAB.REFERENCES.PUBLISH_DATE';
export const REFERENCES_ID_NUMBER = 'CRWS.TAB.REFERENCES.ID_NUMBER';
export const REFERENCES_REF_UOI_ID = 'CRWS.TAB.REFERENCES.REFERENCE_UOI_ID';

/** Field Types */
export const METADATA_TABULAR_FIELD = 'com.artesia.metadata.MetadataTableField';
export const METADATA_TABLE = 'com.artesia.metadata.MetadataTable';

export const METADATA_FIELD = 'com.artesia.metadata.MetadataField';
export const METADATA_CATEGORY = 'com.artesia.metadata.MetadataCategory';

/** Tabular Parent Field Ids */
export const TABULAR_REFERENCES = 'CRWS.TABLE.REFERENCES';
export const TABULAR_DOMAIN_TYPE = 'DIMSD.TABLE.DOMAIN_TYPE';
export const TABULAR_CRWS_SOURCES = 'DIMSD.TABLE.CRWS_SOURCES';
export const TABULAR_CRWS_TOPICS = 'MSIFT.TABLE.BOK_TOPIC';
export const TABULAR_DIMSD_VERSIONS = 'DIMSD.TABLE.CRWS_VERSION';
export const TABULAR_MSIFT_AUTHORS = 'MSIFT.TABLE.BOK_AUTHOR';
export const TABULAR_MSIFT_REVIEW_BOARD = 'CRWS.TABLE.REVIEW_PROCESS';
export const TABULAR_SEMOD_AREA = 'SEMOD.TABLE.AREA';
export const TABULAR_SEMOD_RESOURCE_TYPE = 'DISMD.TABLE.RESOURCE_TYPE';

export const METADATA_TO_RETURN = [
  DOMAIN,
  CRWS_TITLE,
  SOURCE_NAME_FULL,
  OPR_SHORT,
  DATE_EFFECTIVE,
  VERSON,
  USER_RATING,
  CONTENT_MATURITY,
  CONTROLLED_ACCESS,
  SHORT_NAME,
  CRWS_ABSTRACT,
  CRWS_TOPIC,
  CRWS_URL,
  CRWS_TOTAL_USER_RATINGS,
  CRWS_AUTHOR,
  CRWS_AUTHOR_DEPT,
  CRWS_AUTHOR_TITLE,
  CRWS_AUTHOR_NOTES,
  CRWS_SOURCE_URL,
  CRWS_COPYRIGHT,
  CRWS_JUSTIFICATION,
  CRWS_ORIGINAL_COPYRIGHT_HOLDER_NAME,
  CRWS_MATURITY_COLOR,
  CONTENT_MATURITY_REASON,
  REFERENCES_TYPE,
  REFERENCES_TITLE,
  REFERENCES_AUTHOR,
  REFERENCES_PUBLISH_DATE,
  REFERENCES_ID_NUMBER,
  REFERENCES_REF_UOI_ID,
  CRWS_PREAPPROVAL_STATUS,
  CRWS_REVIEW_DATE,
  CRWS_REVIEW_END,
  CRWS_REVIEW_START,
  CRWS_REVIEW_USER_ID,
  CRWS_REVIEW_APPROVAL,
  CRWS_REVIEW_COMMENTS,
  SEMOD_AREA,
  RESOURCE_TYPE,
];

export const HIDDEN_FIELDS = [
  // Fields
  CONTENT_MATURITY,
  CONTROLLED_ACCESS,
  CRWS_PREAPPROVAL_STATUS,
  CONTENT_MATURITY_REASON,
  CRWS_MATURITY_COLOR,
  CRWS_TOTAL_USER_RATINGS,
  USER_RATING,
  CRWS_JUSTIFICATION,
  CRWS_REVIEW_DATE,
  CRWS_REVIEW_END,
  CRWS_REVIEW_START,
  CRWS_REVIEW_USER_ID,
  CRWS_REVIEW_APPROVAL,
  CRWS_REVIEW_COMMENTS,
  RESOURCE_TYPE,

  // Tabular Parent IDs
  TABULAR_REFERENCES,
];
