/* eslint-disable */
import _ from 'lodash';
import otmm from '../../otmm/apis/otmm';
import {
  CPARS_MODEL_ID,
  CPARS_ARTIFACT_MODEL_ID,
  GWAC_MODEL_ID,
  GWAC_ARTIFACT_MODEL_ID,
  CRWS_PREAPPROVAL,
  CRWS_MODEL_ID,
} from '../constants/assetFields';
import {
  CPARS_ACCESS,
  CPARS_ARTIFACT_ACCESS,
  GWAC_ACCESS,
  POLICY_FOR_EVERYONE,
} from '../constants/securityPolicyNames';

const modelToSecurityMapping = {
  // [CPARS_MODEL_ID]: [CPARS_ACCESS],
  // [CPARS_ARTIFACT_MODEL_ID]: [CPARS_ARTIFACT_ACCESS],
  // [GWAC_MODEL_ID]: [GWAC_ACCESS],
  // [GWAC_ARTIFACT_MODEL_ID]: [GWAC_ACCESS],
  [CRWS_PREAPPROVAL]: [POLICY_FOR_EVERYONE],
};

const getSecurityPolicyIds = async (modelId) => {
  const response = await otmm.get(`securitypolicies`);

  // TODO: Yeah...fix this
  const ids = ['1', '2', '3'];
  // = _.reduce(
  //   response.data.security_policies_resource.security_policy_list,
  //   (acc, value) => {
  //     console.log('modelId', modelId);

  //     console.log('value', value);
  //     console.log('modelToSecurityMapping', modelToSecurityMapping);
  //     console.log(
  //       'modelToSecurityMapping[modelId]',
  //       modelToSecurityMapping[modelId]
  //     );
  //     if (modelToSecurityMapping[modelId].includes(value.name)) {
  //       acc.push(value.id);
  //     }
  //     return acc;
  //   },
  //   []
  // );

  return ids;
};

export default getSecurityPolicyIds;
