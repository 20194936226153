import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  // useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { SITE_NAME_ABBR, SUPPORT_EMAIL } from '../../constants/constants';
import LandingMenu from './components/LandingMenu/LandingMenu';

const tips = [
  {
    title: `Need help on a page? Look for a “?” button!`,
    content: `Most of the pages on ${SITE_NAME_ABBR} offer help on how to optimize your experience. If you need a little assistance, this can be a great place to start. `,
  },
  {
    title: `Register an account with ${SITE_NAME_ABBR} today!`,
    content: `Registered user accounts are free and offer additional benefits, from saved searches to email notifications! Check the FAQ for a full list of benefits.`,
  },
  {
    title: `Check out Relationships view!`,
    content: `See how resources in the repository are related, whether through topic or publishing organization! Click the relationships view in each asset`,
  },
  {
    title: `Need a quick link to a reference? ${SITE_NAME_ABBR} has you covered!`,
    content: `As of 1.0, you can check out what documents are referenced directly through the resource view! This will also show you what documents in ${SITE_NAME_ABBR} mention the resource you’re currently looking at.`,
  },
  {
    title: `${SITE_NAME_ABBR} has multiple ways to search!`,
    content: `The landing page search will always be available on ${SITE_NAME_ABBR}’s main page, but did you know you can perform keyword searches as well? The search bar at the top will search ${SITE_NAME_ABBR} for all instances of what you’re interested in!`,
  },
  {
    title: `Can’t find the resource you’re looking for?`,
    content: `${SITE_NAME_ABBR} is always looking to grow! If you're aware of a resource the community may be interested in, be sure to submit a resource nomination. Register an account, and fill out the nomination form today!`,
  },
  {
    title: `Have a question? Looking to provide feedback?`,
    content: `Email us at ${SUPPORT_EMAIL} or check out the "Contact Us" section in the footer.`,
  },
];

const news = [`You are using version 1.0 of ${SITE_NAME_ABBR}`];

const LandingPage = () => {
  const tipsTimeout = useRef(null);

  const [currentTip, setCurrentTip] = useState();
  const [currentTipTitle, setCurrentTipTitle] = useState();

  const handleEnterTips = (title) => {
    setCurrentTipTitle(title);
    // On enter start 5 second timer, then call handleExitTips to change tip to random
    const { length } = tips;

    tipsTimeout.current = setTimeout(() => {
      setCurrentTip(Math.floor(Math.random() * length));
    }, 10000);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && !currentTip) {
      const randomNumber = Math.floor(Math.random() * tips.length);
      setCurrentTipTitle(tips[randomNumber].title);
      setCurrentTip(randomNumber);
    }
    if (!mounted) {
      clearTimeout(tipsTimeout);
    }

    return () => {
      mounted = false;
    };
  }, [currentTip]);

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      alignItems="stretch"
      spacing={3}
      style={{ minHeight: '60vh' }}
    >
      {/* Start Search container */}
      <Grid item xs>
        <Paper style={{ height: '100%' }}>
          <LandingMenu />
        </Paper>
      </Grid>

      {/* Side container with "What's New" and "Tips" */}
      <Grid item xs={12} md={3}>
        <Grid container spacing={3} direction="column">
          <Grid item tour="nav-news">
            <Card>
              <CardHeader
                title={
                  <Typography>
                    <b>{SITE_NAME_ABBR} News</b>
                  </Typography>
                }
              />
              <CardContent
                style={{
                  maxHeight: '150px',
                }}
              >
                <List>
                  {news.map((newsItem) => (
                    <ListItem key={newsItem}>
                      <ListItemText>{newsItem} </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item tour="nav-tips">
            <Card>
              <CardHeader
                title={
                  <Typography>
                    <b>{currentTipTitle}</b>
                  </Typography>
                }
              />
              <CardContent>
                <List>
                  {tips.map((tip, i) => {
                    const { title, content } = tip;
                    return (
                      <Collapse
                        key={title}
                        in={currentTip === i}
                        timeout={500}
                        onEntered={() => handleEnterTips(title)}
                        unmountOnExit
                        mountOnEnter
                      >
                        <ListItem>
                          <ListItemText>{content}</ListItemText>
                        </ListItem>
                      </Collapse>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          </Grid>
          {/* </Slide> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
LandingPage.propTypes = {};

export default LandingPage;
