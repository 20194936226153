import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { alpha, IconButton, TextField, Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';

import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import clearSearchSuggestions from '../actions/clearSearchSuggestions';
import fetchSearchSuggestions from '../actions/fetchSearchSuggestions';
import formatSearchSuggestions from '../functions/formatSearchSuggestions';
import { SEARCH } from '../../../constants/paths';
import fetchTotalQuantity from '../../Home/functions/fetchTotalQuantity';
import './SearchBar.css';
import {
  searchByTerm,
  setSearchTerm,
} from '../../Home/models/otmmModel/functions/updateState';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: '.66em',
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.light, 1)
        : alpha(theme.palette.text.hint, 1),
    border: '3px solid #F7FDFE',
    '&:hover, &:focus': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.light, 0.75)
          : alpha(theme.palette.text.hint, 0.75),
      border: `3px solid ${theme.palette.primary.dark}`,
    },
    marginLeft: 0,
    width: '100%',
    minWidth: '250px',
    maxWidth: '600px',
    [theme.breakpoints.down('lg')]: {
      margin: '1em',
      marginLeft: '3em',
      width: '100%',
    },
  },
  input: {
    color: theme.palette.primary.dark,
    '&::placeholder': {
      color: theme.palette.primary.dark,
    },
  },
}));

const SearchBar = ({
  variant,
  size,
  fetchSearchSuggestions,
  setSearchTerm: pSetSearchTerm,
  searchTerm: pSearchTerm,
  searchSuggestions,
  searchByTerm,
  autoFocus,
}) => {
  const searchRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [totalResults, setTotalResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState(pSearchTerm);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (totalResults === null && mounted) {
        const results = await fetchTotalQuantity().catch(
          () => 'Error retrieving Resource count.'
        );
        if (mounted) setTotalResults(results.totalResults);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [totalResults]);

  const search = (term) => {
    const encodedTerm = encodeURI(term.trim());

    searchByTerm(term);
    if (location.pathname !== SEARCH + encodedTerm) {
      history.push({
        pathname: SEARCH,
        search: `?term=${encodedTerm}`,
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (!location.pathname === SEARCH && mounted) {
      setSearchTerm('');
      clearSearchSuggestions();
    }

    return () => {
      mounted = false;
    };
  }, [location, setSearchTerm]);

  const onSearchChange = useCallback(
    (e) => {
      if (e && e.target.value && e.target.value.trim().length > 0) {
        fetchSearchSuggestions(e.target.value);
      }

      setSearchTerm(e.target.value);
    },
    [fetchSearchSuggestions, setSearchTerm]
  );
  const formattedSearchSuggestions = useMemo(
    () => formatSearchSuggestions(searchSuggestions),
    [searchSuggestions]
  );

  const onSubmit = (event) => {
    event.preventDefault();
    pSetSearchTerm(searchTerm.trim());
    if (searchTerm && searchTerm.trim().length > 0) search(searchTerm);
    setSearchTerm(searchTerm.trim());
  };
  const handleSelect = (e, values) => {
    if (values !== null) search(values);
  };
  return (
    <div className={classes.search}>
      <form onSubmit={onSubmit}>
        <Autocomplete
          tour="search-bar"
          freeSolo
          size={size}
          value={searchTerm}
          id="SearchTheBok"
          onChange={handleSelect}
          options={formattedSearchSuggestions.map((option) => option.title)}
          renderInput={(params) => (
            <TextField
              onKeyDown={(e) => {
                // fix-later
                if (e.keyCode === 13 && e.target.value) {
                  setSearchTerm(e.target.value);
                  onSubmit(e);
                }
              }}
              margin="none"
              variant={variant}
              {...params}
              autoFocus={autoFocus}
              ref={searchRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <IconButton
                    aria-label="search"
                    size="small"
                    onClick={onSubmit}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
                type: 'search',
                disableUnderline: true,
                classes: { input: classes.input },
              }}
              value={searchTerm}
              onChange={onSearchChange}
              placeholder={
                totalResults ? `Search ${totalResults} resources.` : ''
              }
            />
          )}
          sx={{ paddingLeft: '2rem' }}
        />
      </form>
    </div>
  );
};

SearchBar.propTypes = {
  fullWidth: PropTypes.bool,
  disableOptions: PropTypes.bool,

  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.any,
  backgroundColor: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  fetchSearchSuggestions: PropTypes.func.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.array.isRequired,
  searchByTerm: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};
SearchBar.defaultProps = {
  backgroundColor: null,
  disableOptions: true,
  color: 'primary',
  fullWidth: false,
  variant: 'standard',
  size: 'medium',
  className: null,
  autoFocus: false,
};

const mapStateToProps = (state) => ({
  searchSuggestions: state.searchSuggestions,
  searchTerm: state.otmmState.otmmParams.term,
  isSearching: state.otmmState.loading,
});

export default connect(mapStateToProps, {
  fetchSearchSuggestions,
  setSearchTerm,
  clearSearchSuggestions,
  searchByTerm,
})(SearchBar);
