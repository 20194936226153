import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  IconButton,
} from '@mui/material';
import { HelpOutlineOutlined } from '@mui/icons-material';

import Pop from '../pop/Pop';
import SaveSearchTable from './SaveSearchTable/SaveSearchTable';
import Banner from '../Banner/Banner';

function ViewSaveSearches() {
  const [showHelp, setShowHelp] = React.useState(false);
  return (
    <Card>
      <CardHeader
        title="Save Search"
        action={
          <Pop content="Help">
            <IconButton onClick={() => setShowHelp(!showHelp)} size="large">
              <HelpOutlineOutlined size="medium" style={{ color: 'white' }} />
            </IconButton>
          </Pop>
        }
      />
      <CardContent>
        <Banner
          open={showHelp}
          icon={<HelpOutlineOutlined />}
          title="Save Search Help"
          handler={() => setShowHelp(!showHelp)}
        >
          <>
            <ul>
              <li>
                <Typography>
                  Select Private or Public to choose the type of Save Search.
                </Typography>
              </li>
              <li>
                <Typography>Choose a Saved Search</Typography>
              </li>
              <li>
                <Typography>Click Search to perform the search.</Typography>
              </li>
            </ul>
          </>
        </Banner>

        <SaveSearchTable style={{ hight: '100%' }} />
      </CardContent>
    </Card>
  );
}

ViewSaveSearches.propTypes = {};
export default ViewSaveSearches;
