import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Link as MatLink,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { LinkButton } from '../../../../components/StyledComponents';
import { DoDLogo, USDRELogo } from '../../../../constants/images';
import { CURRENT_VERSION } from '../../constants/constants';
import About from '../About/About';
import AccessibilityInformation from '../Accessibility/AccessibilityInformation';
import ContactForm from '../ContactPage/ContactForm';
import CopyrightPage from '../CopyrightPage/CopyrightPage';
import ExternalLinkDialog from '../CustomDialog/ExternalLinkDialog';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import Faqs from '../FAQs/FAQs';
import MenuIconButton from '../MenuButtons/MenuIconButton';
import ContactUsMenuItems from '../MenuButtons/MenuItems/ContactUsMenuItems';
import CreateExternalLinkMenuItems from '../MenuButtons/MenuItems/CreateExternalLinkMenuItems';
import SiteInfoMenuItems from '../MenuButtons/MenuItems/SiteInfoMenuItems';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ReleasePage from '../ReleasePage/ReleasePage';
import Sitemap from '../Sitemap/Sitemap';
import TermsOfUse from '../TermsOfUse/TermsOfUse';

const Footer = ({ ...otherProps }) => {
  const useStyles = makeStyles((theme) => ({
    footer: {
      top: 'auto',
      padding: '0 24px',
      bottom: 0,
      textAlign: 'center',
      zIndex: theme.zIndex.drawer - 1,
    },
    accordion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: 0,
    },
    summary: {
      textAlign: 'center',
      width: '100%',
    },
    sectionDesktop: {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    sectionMobile: {
      display: 'none',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        display: 'block',
      },
    },
  }));

  const [selected, setSelected] = useState({
    title: 'Terms of Use',
    component: null,
    open: false,
  });

  const handleClose = () => {
    setSelected({ ...selected, open: false });
  };

  const sponsorLinks = [
    {
      label: 'USD(R&E)',
      url: 'https://www.cto.mil',
      tooltip:
        'Office of the Under Secretary of Defense, Research and Engineering (USD(R&E))',
    },
    { label: 'USD(R&E)/STP&E', url: 'https://rt.cto.mil/stpe/' },
  ];

  const legalLinks = [
    { label: 'DoD No Fear Act', url: 'https://prhome.defense.gov/NoFear/' },
    {
      label: 'Freedom of Information',
      url: 'https://open.defense.gov/Transparency/FOIA.aspx',
    },
    {
      label: 'External Links Disclaimer',
      url: 'https://dodcio.defense.gov/Home2/ELD.aspx',
    },
    { label: 'Web Policy', url: 'https://dodcio.defense.gov/DoD-Web-Policy' },
    {
      label: 'Accessibility/Section 508',
      url: 'https://dodcio.defense.gov/DoDSection508/Std_Stmt.aspx',
    },
  ];

  const otherLinks = [
    { label: 'DefenseLink', url: 'https://www.defense.gov' },
    { label: 'USA.gov', url: 'http://usa.gov' },
  ];

  const copyrightAndVersion = (
    <>
      <Typography color="inherit" display="inline" variant="body2">
        {`Copyright © ${new Date().getFullYear()} `}
        <MatLink
          variant="body2"
          color="inherit"
          underline="hover"
          href="https://saic.com/"
        >
          SAIC
        </MatLink>{' '}
        <MatLink
          underline="hover"
          tabIndex={0}
          variant="body2"
          color="inherit"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelected({
              title: 'Release Notes',
              component: <ReleasePage withAccordion={false} />,
              open: true,
            });
          }}
        >
          v{CURRENT_VERSION}
        </MatLink>
      </Typography>
    </>
  );

  const createLinkColumns = (title, rows) => (
    <Grid item container direction="column" alignItems="flex-start">
      <Grid item>
        <Typography>
          <b>{title}</b>
        </Typography>
      </Grid>
      {rows &&
        rows.map((row) => (
          <Grid item key={`${row.title}${row.url}`}>
            <ExternalLinkDialog
              underline="hover"
              label={row.label}
              tooltip={row.tooltip ? row.tooltip : row.label}
              color="inherit"
              tabIndex={0}
              variant="body2"
            >
              {row.url}
            </ExternalLinkDialog>{' '}
          </Grid>
        ))}
    </Grid>
  );

  const classes = useStyles();
  return (
    <AppBar
      {...otherProps}
      className={classes.footer}
      sx={{ color: '#F7FDFE' }}
    >
      <div className={classes.sectionDesktop}>
        <Grid
          container
          style={{ padding: '24px' }}
          justifyContent="space-between"
        >
          <Grid item>
            <Grid item container>
              <DoDLogo />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item container spacing={3}>
              <Grid item>
                <Grid item container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Typography>
                      <b>Site Info</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      tabIndex={0}
                      variant="body2"
                      color="inherit"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Sitemap',
                          component: (
                            <Sitemap
                              onClose={() =>
                                setSelected({ ...selected, open: false })
                              }
                            />
                          ),
                          open: true,
                        });
                      }}
                    >
                      Sitemap
                    </MatLink>
                  </Grid>

                  <Grid item>
                    <MatLink
                      variant="body2"
                      tabIndex={0}
                      color="inherit"
                      underline="hover"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Privacy Policy',
                          component: <PrivacyPolicy />,
                          open: true,
                        });
                      }}
                    >
                      Privacy Policy
                    </MatLink>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      tabIndex={0}
                      color="inherit"
                      variant="body2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Accessibility Information',
                          component: <AccessibilityInformation />,
                          open: true,
                        });
                      }}
                    >
                      Accessibility Information
                    </MatLink>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      tabIndex={0}
                      color="inherit"
                      variant="body2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Copyright Information',
                          component: <CopyrightPage dialog />,
                          open: true,
                        });
                      }}
                    >
                      Copyright Information
                    </MatLink>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      tabIndex={0}
                      color="inherit"
                      variant="body2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Terms of Use',
                          component: <TermsOfUse />,
                          open: true,
                        });
                      }}
                    >
                      Terms of Use
                    </MatLink>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-start"
                  tour="nav-help-footer"
                >
                  <Grid item>
                    <Typography>
                      <b>Help</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      variant="body2"
                      tabIndex={0}
                      color="inherit"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Support',
                          component: (
                            <ContactForm fullWidth variant="outlined" />
                          ),
                          open: true,
                        });
                      }}
                    >
                      Get support help or ask a question
                    </MatLink>
                  </Grid>

                  <Grid item>
                    <MatLink
                      underline="hover"
                      variant="body2"
                      tabIndex={0}
                      color="inherit"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'Frequently Asked Questions',
                          component: <Faqs dialog />,
                          open: true,
                        });
                      }}
                    >
                      Frequently Asked Questions
                    </MatLink>
                  </Grid>
                  <Grid item>
                    <MatLink
                      underline="hover"
                      variant="body2"
                      tabIndex={0}
                      color="inherit"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected({
                          title: 'About',
                          component: <About dialog />,
                          open: true,
                        });
                      }}
                    >
                      About
                    </MatLink>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid item container direction="column">
                  {createLinkColumns('Sponsor Link', sponsorLinks)}
                </Grid>
              </Grid>

              <Grid item>
                <Grid item container direction="column">
                  {createLinkColumns('Legal', legalLinks)}
                </Grid>
              </Grid>

              <Grid item>
                <Grid item container direction="column">
                  {createLinkColumns('Other Links', otherLinks)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid item container justifyContent="center" alignItems="center">
              <USDRELogo />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ backgroundColor: '#ffffff', opacity: '0.5' }} />
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Grid
              item
              container
              justifyContent="center"
              padding="24px"
              alignItems="center"
            >
              {copyrightAndVersion}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.sectionMobile}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <DoDLogo style={{ height: 60 }} />
          </Grid>
          <Grid item xs={6} sm>
            <Grid container justifyContent="center">
              <MenuIconButton
                color="inherit"
                component={LinkButton}
                menuItems={SiteInfoMenuItems()}
                content="Site Info"
              >
                Site Info
              </MenuIconButton>
              <MenuIconButton
                color="inherit"
                component={LinkButton}
                menuItems={ContactUsMenuItems()}
                content="Help"
              >
                Contact Us
              </MenuIconButton>
              <MenuIconButton
                color="inherit"
                component={LinkButton}
                menuItems={CreateExternalLinkMenuItems(sponsorLinks)}
                content="Sponsor Links"
              >
                Sponsor Links
              </MenuIconButton>
              <MenuIconButton
                color="inherit"
                component={LinkButton}
                menuItems={CreateExternalLinkMenuItems(legalLinks)}
                content="Legal"
              >
                Legal
              </MenuIconButton>
              <MenuIconButton
                color="inherit"
                component={LinkButton}
                menuItems={CreateExternalLinkMenuItems(otherLinks)}
                content="Other Links"
              >
                Other Links
              </MenuIconButton>
            </Grid>
          </Grid>
          <Grid item>
            <USDRELogo style={{ height: 60 }} />
          </Grid>
        </Grid>

        <div>{copyrightAndVersion}</div>
      </div>

      <Dialog open={selected.open} onClose={handleClose}>
        <DialogTitleWithClose onClose={handleClose}>
          {selected.title}
        </DialogTitleWithClose>
        <DialogContent>{selected.component}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
