import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopIcon from '@mui/icons-material/Stop';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { TreeItem, TreeView } from '@mui/lab';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import createLegend, { updateLegend } from './createLegend';
import StyledAccordion from '../../StyledAccordion/StyledAccordion';

const GraphLegend = ({ graphSettings, data, setData }) => {
  const [originalData] = useState({ ...data });
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const [legendItems, setLegendItems] = useState(
    createLegend(data, graphSettings)
  );

  const getGraphIcon = (key) => {
    switch (key) {
      case 'Origin':
        return <StarIcon style={{ color: 'green' }} />;
      case 'Asset':
        return <StopIcon style={{ color: legendItems[key].color }} />;

      default:
        return (
          <FiberManualRecordIcon style={{ color: graphSettings.nodes[key] }} />
        );
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const removeNode = (id, arr) => {
    // Prevent removing the origin node.
    const origin = data.nodes.find((node) => node.symbolType === 'star');
    if (id === origin.id) return arr;

    return [...arr].filter((node) => node.id !== id);
  };

  const removeLink = (id, tempData) => {
    const { links, nodes } = tempData;

    // Store removed Links temporarily so we can iterate through assets and try and delete them.
    const removedLinks = [...links].filter((link) => link.source === id);
    let filteredNodes = [...nodes];

    removedLinks.forEach((link) => {
      const { target } = link;

      // Check if removed Links can be removed as Nodes... if they have no other sources other than this, then remove the nodes.
      const hasOtherTargets =
        [...links].filter((l) => l.target === target && l.source !== id)
          .length > 0;

      if (!hasOtherTargets) filteredNodes = removeNode(target, filteredNodes);
    });

    // Create filtered links where the source !== id. This will remove all links to the ID passed to this function.
    const filteredLinks = [...links].filter((link) => link.source !== id);

    // Finally - remove the topic that was unchecked.
    filteredNodes = [...filteredNodes.filter((node) => node.id !== id)];

    // Set data for graph.
    return { nodes: [...filteredNodes], links: [...filteredLinks] };
  };

  const restoreLink = (id, tempData) => {
    const { links, nodes } = tempData;
    const originalLinks = originalData.links;
    const originalNodes = originalData.nodes;

    const tempNodes = [...nodes];
    let tempLinks = [...links];

    const originatingNode = originalNodes.find((node) => node.id === id);
    if (originatingNode) tempNodes.push(originatingNode);

    // Restore the links for the original node...
    const filteredLinks = [...originalLinks].filter(
      (link) => link.source === originatingNode.id
    );

    // Restore all the targets from the new filteredList
    filteredLinks.forEach((link) => {
      const { target } = link;
      const targetNode = originalNodes.find((node) => node.id === target);

      if (targetNode) tempNodes.push(targetNode);
    });
    tempLinks = [...tempLinks, ...filteredLinks];

    return { nodes: [...tempNodes], links: [...tempLinks] };
  };

  const handleChange = (e, type) => {
    const { id, value, checked } = e.target;
    const { nodes } = data;
    const dataToUpdate = { id, type };
    const updatedLegend = updateLegend(legendItems, dataToUpdate);

    // Set the checkbox states...
    setLegendItems(updatedLegend);

    if (value) {
      // the value with from type... this is a child
      if (!checked) {
        // Set the data with the new removed link...
        setData(removeLink(value, data));
      } else {
        setData(restoreLink(value, data));
      }
    } else if (!checked) {
      // Remove all links to the parent source...
      // Get all Ids of the nodes of this type....
      const tempNodes = [...nodes].filter((node) => node.type === id);
      let tempData = { ...data };
      tempNodes.forEach((node) => {
        const { id } = node;
        tempData = removeLink(id, tempData);
      });
      setData(tempData);
    } else {
      const tempNodes = [...originalData.nodes].filter(
        (node) => node.type === id
      );
      let tempData = { ...data };
      tempNodes.forEach((node) => {
        const { id } = node;
        tempData = restoreLink(id, tempData);
      });
      setData(tempData);
    }
  };

  return (
    <StyledAccordion style={{ width: '400px', opacity: '0.9' }} title={<Typography>Legend</Typography>}>
      <div style={{ padding: '5px' }}>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {Object.keys(legendItems).map((type) => {
            if (type === 'Asset') return null;
            const parentTopic = legendItems[type];
            const { children } = parentTopic;
            const { color, checked, disabled } = parentTopic;
            return (
              <TreeItem
                nodeId={type}
                key={type}
                // onLabelClick={(e) => e.preventDefault()}
                // onClick={(e) => {
                //   e.preventDefault();
                // }}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        id={type}
                        style={{ color }}
                        checked={checked}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {getGraphIcon(type)} {` ${type}`}
                      </div>
                    }
                  />
                }
              >
                {Object.keys(children).map((child) => {
                  const subTopic = children[child];
                  const { id, checked, disabled, count } = subTopic;
                  return (
                    <TreeItem
                      nodeId={id}
                      key={id}
                      // onLabelClick={(e) => {
                      //   e.preventDefault();
                      // }}
                      label={
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disabled}
                              style={{ color }}
                              id={child}
                              checked={checked}
                              value={id}
                              onChange={(e) => {
                                handleChange(e, type);
                              }}
                            />
                          }
                          label={`${child} (${count})`}
                        />
                      }
                    />
                  );
                })}
              </TreeItem>
            );
          })}
        </TreeView>
      </div>
    </StyledAccordion>
  );
};

GraphLegend.propTypes = {
  graphSettings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  graphSettings: state.otmmSettings.graph,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GraphLegend);
