import React from 'react';
import { Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import getDateIndex from '../../../hooks/getDateIndex';

function Subtitle({ version, dateEffective }) {
  const dateIndex = getDateIndex(dateEffective);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="caption">
            <b>Version Date:</b> {`${dateEffective.values[0] ? dateEffective.values[dateIndex] : 'NA'
              } `}{' '}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="caption">
            <b>Version:</b> {`${version.values[0] ? version.values[dateIndex] : 'NA'
              }`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

Subtitle.propTypes = {
  version: PropTypes.object.isRequired,
  dateEffective: PropTypes.object.isRequired,
};

export default Subtitle;
