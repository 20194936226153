import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from '@mui/material';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import clearSearchSuggestions from '../actions/clearSearchSuggestions';
import formatSearchSuggestions from '../functions/formatSearchSuggestions';
import { SEARCH } from '../../../constants/paths';
import './SearchBar.css';
import fetchSearchSuggestions from '../actions/fetchSearchSuggestions';
import { addKeyword } from '../../Home/models/otmmModel/functions/updateState';

const KeywordSearch = ({
  addKeyword,
  searchSuggestions,
  clearSearchSuggestions,
}) => {
  const [keyword, setKeyword] = useState('');

  const location = useLocation();

  function updateKeywords(pKeyword) {
    if (pKeyword !== '') {
      addKeyword(pKeyword);
      setKeyword('');
    }
  }

  useEffect(() => {
    let mounted = true;
    if (!location.pathname === SEARCH && mounted) {
      setKeyword('');
      clearSearchSuggestions();
    }
    return () => {
      mounted = false;
    };
  }, [clearSearchSuggestions, location, setKeyword]);

  const formattedSearchSuggestions = useMemo(() => formatSearchSuggestions(searchSuggestions), [searchSuggestions]);

  const onSubmit = (event) => {
    event.preventDefault();
    updateKeywords(keyword);
  };

  const onSearchChange = useCallback(
    (e) => {
      if (e && e.target.value && e.target.value.length > 0) {
        fetchSearchSuggestions(e.target.value);
      }
      setKeyword(e.target.value);
    },
    [setKeyword]
  );

  const handleSelect = (_e, values) => {
    // if (values !== null) updateKeywords();
    if (values && values.trim().length > 0) {
      setKeyword(values);
      updateKeywords(values);
    }
  };
  return (
    <form onSubmit={onSubmit} style={{ padding: 0 }}>
      <Autocomplete
        id="keywordSearch"
        inputValue={keyword}
        disableClearable
        onChange={handleSelect}
        clearOnEscape
        options={formattedSearchSuggestions.map((option) => option.title)}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            value={keyword}
            onChange={onSearchChange}
            fullWidth
            type="text"
            variant="outlined"
            label="Keyword Filter"
            placeholder="Enter keyword"
          />
        )}
      />
    </form>
  );
};
KeywordSearch.propTypes = {
  keywords: PropTypes.array.isRequired,
  addKeyword: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.array.isRequired,
  clearSearchSuggestions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    searchSuggestions: state.searchSuggestions,
    isSearching: state.otmmState.loading,
    filters: state.otmmState.filters.allFilters,
    keywords: state.otmmState.otmmParams.keywords,
  });

export default connect(
  mapStateToProps,
  { addKeyword, clearSearchSuggestions }
)(KeywordSearch);
