import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StyledAccordion from '../StyledAccordion/StyledAccordion';
import TabPanel from '../TabPanel/TabPanel';
import {
  ReferenceTabs,
  ReferenceTab,
} from '../../../../components/StyledComponents';


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabsTable({ tables, ...otherProps }) {
  const tabsData = tables.map((table) => {
    const { name, parentId } = table;
    return { name, parentId };
  });

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledAccordion title={<Typography>{tables[value].name}</Typography>}>
      <div className={classes.root}>
        <ReferenceTabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label={`Vertical tabs ${tables[value].name}`}
        >
          {tabsData.map((tab, index) => (
            <ReferenceTab
              label={tab.name}
              {...a11yProps(index)}
              key={tab.name}
            />
          ))}
        </ReferenceTabs>
        {tables.map((table, index) => {
          const { name } = table;
          return (
            <TabPanel
              value={value}
              index={index}
              key={name}
              style={{ width: '100%', height: '100%', padding: '5px' }}
            >
              <div style={{ width: '100%', height: '400px' }}>
                <DataGrid
                  {...otherProps}
                  rows={table.rows}
                  columns={table.columns}
                />
              </div>
            </TabPanel>
          );
        })}
      </div>
    </StyledAccordion>
  );
}
VerticalTabsTable.propTypes = {
  tables: PropTypes.array.isRequired,
};
