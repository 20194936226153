import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Link as MatLink,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { RELEASE_PAGE } from '../../../../constants/paths';
import {
  CURRENT_VERSION,
  SITE_NAME,
  SITE_NAME_ABBR,
} from '../../constants/constants';
import Pop from '../pop/Pop';

function About({ dialog }) {
  const history = useHistory();

  const content = (
    <>
      <Typography paragraph>
        {SITE_NAME} ({SITE_NAME_ABBR}) is intended to provide a modular, open
        knowledge management content platform to search and store relevant
        domain knowledge of modernized Systems Engineering Practices for DoD
        stakeholders.
      </Typography>
      <Typography paragraph>
        The initial version of the {SITE_NAME_ABBR} content will include
        publicly accessible best practices, lesson learned, and implementation
        strategies for SE MOD focus areas (Digital Engineering, MOSA, SW-AGILE,
        Mission Engineering). The content will allow be regularly updated to
        provide the community of practice with the most recent concepts, terms
        and implementation strategies for emerging modernized SE practices.
      </Typography>
      <Typography paragraph>
        Current Version of {SITE_NAME_ABBR}:{' '}
        <Pop content="Click here for release notes">
          <MatLink
            tabIndex={0}
            component={Link}
            to={RELEASE_PAGE}
            onClick={() => history.push(RELEASE_PAGE)}
          >
            {CURRENT_VERSION}
          </MatLink>
        </Pop>
      </Typography>
    </>
  );

  return dialog ? (
    content
  ) : (
    <Card>
      <CardHeader title="About" />
      <CardContent>{content}</CardContent>
    </Card>
  );
}

About.propTypes = {
  dialog: PropTypes.bool,
};
About.defaultProps = {
  dialog: false,
};
export default About;
