import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
} from '@mui/material';
import React from 'react';
import checkSession from '../../../../../../auth/functions/checkSession';
import { registrationApi } from '../../../../../../otmm/apis/otmm';
import checkPasswordHistory from '../../../../../functions/checkPasswordHistory';

const PasswordChange = () => {
  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    showConfirmPassword: false,
    showPassword: false,
    showOldPassword: false,
  });

  const [oldPassword, setOldPasswordState] = React.useState({
    oldPasswordState: false,
    oldPasswordInvalid: true,
    oldPasswordHelperText: '',
  });

  const [newPassword, setNewPasswordState] = React.useState({
    newPasswordState: false,
    newPasswordInvalid: true,
    newPasswordHelperText: '',
  });

  const [confirmPassword, setConfirmPasswordState] = React.useState({
    confirmPasswordState: false,
    confirmPasswordInvalid: true,
    confirmPasswordHelperText: '',
  });

  const hasSpecialCharacter = (passwordToTest) => {
    if (
      passwordToTest.includes('!') ||
      passwordToTest.includes('@') ||
      passwordToTest.includes('?') ||
      passwordToTest.includes('#') ||
      passwordToTest.includes('$')
    ) {
      return true;
    }
    return false;
  };

  const hasDisallowedCharacter = (passwordToTest) => {
    if (
      passwordToTest.includes('%') ||
      passwordToTest.includes('/') ||
      passwordToTest.includes('\\') ||
      passwordToTest.includes('&')
    ) {
      return true;
    }
    return false;
  };

  const [snackBar, setSnackBar] = React.useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    if (prop === 'oldPassword') {
      if (!event.target.value.length) {
        setOldPasswordState({
          ...oldPassword,
          oldPasswordState: true,
          oldPasswordHelperText: 'Please enter your old password',
          oldPasswordInvalid: true,
        });
      } else {
        setOldPasswordState({
          ...oldPassword,
          oldPasswordState: false,
          oldPasswordHelperText: '',
          oldPasswordInvalid: false,
        });
      }
    } else if (prop === 'newPassword') {
      if (
        event.target.value.length < 7 ||
        !/[a-z]/.test(event.target.value) ||
        !/[A-Z]/.test(event.target.value) ||
        !hasSpecialCharacter(event.target.value) ||
        hasDisallowedCharacter(event.target.value)
      ) {
        const str = [];
        if (event.target.value.length < 7) {
          str.push('Must be at least 8 characters.');
        }
        if (!/[a-z]/.test(event.target.value)) {
          str.push('Must contain at least one lowercase letter.');
        }
        if (!/[A-Z]/.test(event.target.value)) {
          str.push('Must contain at least one uppercase letter.');
        }
        if (!hasSpecialCharacter(event.target.value)) {
          str.push('Must contain at least one special character.');
        }
        if (hasDisallowedCharacter(event.target.value)) {
          str.push('Must not contain illegal characters.');
        }
        setNewPasswordState({
          ...newPassword,
          newPasswordState: true,
          newPasswordHelperText: str,
          newPasswordInvalid: true,
        });
      } else {
        setNewPasswordState({
          ...newPassword,
          newPasswordState: false,
          newPasswordHelperText: '',
          newPasswordInvalid: false,
        });
      }
    } else if (prop === 'confirmPassword') {
      if (values.newPassword === event.target.value) {
        setConfirmPasswordState({
          ...confirmPassword,
          confirmPasswordState: false,
          confirmPasswordHelperText: '',
          confirmPasswordInvalid: false,
        });
      } else {
        setConfirmPasswordState({
          ...confirmPassword,
          confirmPasswordState: true,
          confirmPasswordHelperText: 'Passwords must match.',
          confirmPasswordInvalid: true,
        });
      }
    }
  };

  const handleClickShowPassword = (field, value) => {
    setValues((v) => ({
      ...v,
      ...v[field],
      [field]: value,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnClick = async () => {
    const session = checkSession();
    const user = `${session.session_resource.session.login_name}`;

    await registrationApi
      .post(
        `/${user}/password`,
        JSON.stringify({
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        })
      )
      .then(() => {
        setSnackBar({
          severity: 'success',
          open: true,
          message: 'Password has been changed.',
        });
      })
      .catch((err) => {
        checkPasswordHistory(err, setSnackBar);
      });
  };

  function validateForm() {
    return (
      oldPassword.oldPasswordInvalid ||
      newPassword.newPasswordInvalid ||
      confirmPassword.confirmPasswordInvalid
    );
  }
  return (
    // <div className={styles.PasswordChangePage}>
    //   <div className={styles.container}>
    <form onSubmit={handleOnClick}>
      <TextField
        required
        fullWidth
        id="oldPassword"
        label="Old Password"
        variant="outlined"
        aria-label="Old Password"
        aria-describedby={oldPassword.oldPasswordHelperText}
        helperText={oldPassword.oldPasswordHelperText}
        error={oldPassword.oldPasswordState}
        type={values.showOldPassword ? 'text' : 'password'}
        value={values.oldPassword}
        onChange={handleChange('oldPassword')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  handleClickShowPassword(
                    'showOldPassword',
                    !values.showOldPassword
                  )
                }
                onMouseDown={handleMouseDownPassword}
              >
                {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />

      <TextField
        required
        fullWidth
        id="newPassword"
        label="New Password"
        variant="outlined"
        aria-label="New Password"
        aria-describedby={newPassword.newPasswordHelperText}
        helperText={newPassword.newPasswordHelperText}
        error={newPassword.newPasswordState}
        type={values.showPassword ? 'text' : 'password'}
        value={values.newPassword}
        onChange={handleChange('newPassword')}
        style={{ marginTop: '10px', marginBottom: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  handleClickShowPassword('showPassword', !values.showPassword)
                }
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        required
        fullWidth
        id="confirmPassword"
        label="Confirm Password"
        variant="outlined"
        aria-label="Confirm Password"
        aria-describedby={confirmPassword.confirmPasswordHelperText}
        helperText={confirmPassword.confirmPasswordHelperText}
        error={confirmPassword.confirmPasswordState}
        type={values.showConfirmPassword ? 'text' : 'password'}
        value={values.confirmPassword}
        onChange={handleChange('confirmPassword')}
        style={{ marginTop: '10px', marginBottom: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  handleClickShowPassword(
                    'showConfirmPassword',
                    !values.showConfirmPassword
                  )
                }
                onMouseDown={handleMouseDownPassword}
              >
                {values.showConfirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Collapse in={snackBar.open} style={{ marginBottom: 8, marginTop: 8 }}>
        <Alert
          onClose={() =>
            setSnackBar({ open: false, severity: snackBar.severity })
          }
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Collapse>

      <div style={{ textAlign: 'right' }}>
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onClick={handleOnClick}
          disabled={validateForm()}
        >
          Change Password
        </Button>
      </div>
    </form>
  );
};

export default PasswordChange;
