import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/pdfjs-express';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import Banner from '../Banner/Banner';

const PdfViewer = ({ asset, searchTerm, viewType, theme }) => {
  const viewer = useRef(null);
  const [instance, setInstance] = useState(null);
  const { assetMasterContent } = asset;
  let url = '';
  const { mimeType } = assetMasterContent;
  const showBanner = mimeType !== 'application/pdf' && !(asset.resourceType.values ? asset.resourceType.values.ResourceType.values.includes('Websites') : false)
  let isPdf = true

  if (mimeType !== 'application/pdf') {
    if (asset.rendition_content.pdf_preview_content) {
      url = asset.rendition_content.pdf_preview_content.url
    }
    else {
      url = asset.rendition_content.preview_content.url
      isPdf = false;
    }
  }
  else {
    url = assetMasterContent.url
  }


  useEffect(() => {
    let mounted = true;
    if (isPdf && mounted)
      WebViewer(
        {
          licenseKey: 'XftL17UQqFulSMwnT1m4',
          path: '/lib',
          extension: 'pdf',
        },
        viewer.current
      ).then((instance) => {
        setInstance(instance);
      });

    return () => {
      mounted = false;
    };
  }, [isPdf]);

  useEffect(() => {
    let mounted = true;
    if (mounted && instance && url) {
      instance.loadDocument(url);
      const { docViewer, setZoomLevel, enableDownload } = instance;
      setZoomLevel(1.25);
      if (mimeType === 'application/pdf') {
        enableDownload();
      }
      docViewer.on('documentLoaded', () => {
        if (searchTerm && searchTerm !== '*') {
          instance.searchTextFull(searchTerm, {
            wholeWord: true,
          });
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [url, instance, searchTerm, mimeType]);

  useEffect(() => {
    let mounted = true;

    if (mounted && instance) {
      const { setTheme } = instance;
      setTheme(theme);
    }
    return () => {
      mounted = false;
    };
  }, [theme, instance]);

  return (
    <>
      <Banner severity='info' handler={null} open={showBanner}>
        <Typography>
          This document is a {mimeType}. A preview is being displayed, to download original document, click <a href={assetMasterContent.url}>here</a>.
        </Typography>
      </Banner>
      {isPdf ?
        <div
          ref={viewer}
          style={
            viewType === 'AssetDetailsView'
              ? { height: '100%' }
              : { height: '100vh' }
          }
        />
        : <embed type="image/jpg" src={url} width="100%" />
      }
    </>
  );
};

PdfViewer.propTypes = {
  searchTerm: PropTypes.string,
  viewType: PropTypes.string,
  asset: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

PdfViewer.defaultProps = { searchTerm: undefined, viewType: undefined };

const mapStateToProps = (state) => ({
  searchTerm: state.otmmState.otmmParams.term,
  theme: state.theme.mode,
});

export default connect(mapStateToProps)(PdfViewer);
