import { useTheme } from '@mui/material';

import GovIcon from '../icons/Other_Gov.svg';
import LawIcon from '../icons/Laws.svg';
import AcademyIcon from '../icons/Non_Gov.svg';

const LAWS = 'Laws / Authoritative Government';
const NON_GOV = 'Non-Government / Industry';
const OTHER_GOV = 'Other Government / Non-Authoritative';
export function getDomainIcon(domain) {
  switch (domain) {
    case LAWS:
      return {
        avatar: LawIcon,
      };
    case NON_GOV:
      return {
        avatar: AcademyIcon,
      };
    case OTHER_GOV:
      return {
        avatar: GovIcon,
      };

    default:
      return {
        avatar: '',
      };
  }
}

function GetDomainTheme(domain) {
  const theme = useTheme();
  switch (domain['Cross-CuttingEnabler'].values[0]) {
    case LAWS:
      return {
        avatar: LawIcon,
        color: theme.cards.type1,
      };
    case NON_GOV:
      return {
        avatar: AcademyIcon,
        color: theme.cards.type2,
      };
    case OTHER_GOV:
      return {
        avatar: GovIcon,
        color: theme.cards.type3,
      };

    default:
      return {
        avatar: undefined,
        color: theme.cards.type4,
      };
  }
}

export default GetDomainTheme;
