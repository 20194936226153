// Form for having user enter their email and username in the event of a forgotten password

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Collapse } from '@mui/material';

import { registrationApi } from '../../../../otmm/apis/otmm';

import {
  EmailField,
  UserField,
} from '../../CustomFields/CustomFields';

const EmailForm = ({ advanceToNextStep, setStepsEmail, setStepsUsername }) => {

	const [email, setEmail] = useState({ value: '', valid: false });
	const [username, setUsername] = useState({ value: '', valid: false });

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState({
		severity: 'success',
    message: '',
    open: false,
	});

	const error = (err) => {
		setMessage({
			severity: 'error',
			open: true,
			message: err.response.statusText === 'Not Found'
				? `Email and/or username not found. Please check your information and contact administator if the problem persists.`
				: `${err.message} - no response from the server. If problem persists contact administrator.`,
		});
	};

	const handleOnClick = async (e) => {
		e.preventDefault();
		setLoading(true);

		setStepsEmail(email.value); // Set email for parent component
		setStepsUsername(username.value); // Set username for parent component

		let emailAndUsernameFound = false
		const usernameToLower = username.value.toLowerCase();

		await registrationApi
			.post(
				'/reset-password',
				JSON.stringify({
					email: email.value,
					username: usernameToLower
				})
			)
			.then(() => {
				emailAndUsernameFound = true;
			})
			.catch((err) => {
				error(err);
			});

		setLoading(false);

		if(emailAndUsernameFound){
			advanceToNextStep(); // Must do this at the bottom to avoid a memory leak
		}
	};

	function validateForm() {
		return (
			!email.valid || !username.valid
		);
	}


	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<EmailField
					required
					id="email"
					label="email"
					aria-describedby="Email field"
					state={email}
					setState={setEmail}
			/>

			<UserField
					required
					id="username"
					label="Username"
					requiredLength={5}
					state={username}
					setState={setUsername}
			/>

			<Collapse in={message.open} mountOnEnter unmountOnExit>
				<Alert
					severity={message.severity}
					onClose={() => setMessage({ ...message, open: false })}
				>
					{message.message}
				</Alert>
      </Collapse>

			<Button
				fullWidth
				variant="contained"
				onClick={handleOnClick}
				disabled={validateForm()} 
				color="primary"
				style={{ marginTop: '10px', marginBottom: '10px' }}
			>
				{loading ? <>Loading...</> : <>Next</>}
			</Button>
		</form>
	);
};

EmailForm.propTypes = {
	advanceToNextStep: PropTypes.func.isRequired,
	setStepsEmail: PropTypes.func.isRequired,
	setStepsUsername: PropTypes.func.isRequired
};

EmailForm.defaultProps = {};

export default EmailForm;