import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DefaultLogo, WhiteLogo } from '../../../../constants/images';
import Loading from '../../../../Loading';
import { SITE_NAME_ABBR } from '../../constants/constants';
import fetchUpFile from '../../functions/fetchUpFile';

const MaintenancePage = ({ children }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const logo = isDark ? WhiteLogo : DefaultLogo;
  const [isUp, setIsUp] = useState(false);
  useEffect(() => {
    let mount = true;
    if (mount && !isUp) {
      const fetch = async () => {
        setIsUp(await fetchUpFile());
      };
      fetch();
    }
    return () => {
      mount = false;
    };
  }, [isUp]);

  if (!isUp)
    return (
      <Loading active={isUp} fullscreen>
        Checking for site maintenance...
      </Loading>
    );
  if (isUp.response && isUp.response.status === 200) return <>{children}</>;

  return (
    <div>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card>
          <CardHeader title={`${SITE_NAME_ABBR} is down for Maintenance`} />
          <CardMedia>
            <div style={{ textAlign: 'center' }}>
              <img
                src={logo}
                style={{ height: '150px', margin: '15px' }}
                alt="Logo"
              />
            </div>
          </CardMedia>
          <CardContent>
            <Typography paragraph>
              {SITE_NAME_ABBR} is down for Maintenance. Please check back later.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

MaintenancePage.propTypes = {
  children: PropTypes.object,
};

MaintenancePage.defaultProps = {
  children: {},
};

export default MaintenancePage;
