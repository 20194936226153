const getString = (arrayToString, separator) => {
  if (arrayToString[0]) {
    let stringVal = '';
    for (let i = 0; i < arrayToString.length; i += 1) {
      if (i === arrayToString.length - 1) {
        stringVal += arrayToString[i];
      } else {
        stringVal += `${arrayToString[i]}${separator} `;
      }
    }
    return stringVal;
  }
  return 'N/A';
};

export default getString;
