import { Typography, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CRWS_MODEL_ID } from '../../constants/assetFields';
import fetchFieldGroup from '../../functions/fetchFieldGroup';
import transformFields from '../../functions/transformFields';
import CreateFields from '../CreateFields/CreateFields';

const CreateFieldGroupForm = ({ setValues: callback, fieldGroup, options }) => {
  const defaultMetadata = {
    metadata_model_id: CRWS_MODEL_ID,
    name: 'Resources',
  };

  const [fields, setFields] = useState();
  const [values, setValues] = useState();

  useState(async () => {
    const result = await fetchFieldGroup(fieldGroup);
    const { defaultFields, defaultValues } = options
      ? await transformFields(result.fields, options)
      : await transformFields(result.fields);

    setFields({ ...defaultFields });
    setValues({ ...defaultMetadata, ...defaultValues });
  });

  const updateValues = (e, field) => {
    if (field) {
      const { id } = field;
      setValues({
        ...values,
        [id]: { ...values[id], ...field, value: e.target.value },
      });
      // If no field exists, merge the values from the table
    } else setValues({ ...values, ...e });
  };

  // Update the parent state to match the child state.
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      callback(values);
    }
    return () => {
      mounted = false;
    };
  }, [values, callback]);

  return values && fields ? (
    <CreateFields fields={fields} values={values} updateValues={updateValues} />
  ) : (
    <>
      <Typography>Loading fields...</Typography>
      <Skeleton variant="rectangular" height={40} /> <br />
      <Skeleton variant="rectangular" height={40} /> <br />
      <Skeleton variant="rectangular" height={40} /> <br />
      <Skeleton variant="rectangular" height={400} /> <br />
    </>
  );
};

CreateFieldGroupForm.propTypes = {
  setValues: PropTypes.func.isRequired,
  fieldGroup: PropTypes.string.isRequired,
  options: PropTypes.object,
};
CreateFieldGroupForm.defaultProps = {
  options: undefined,
};

export default CreateFieldGroupForm;
