import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  darken,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link as MatLink,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LinkButton } from '../../../../../../components/StyledComponents';
// import {
//   setTourOpen,
//   setTourProps,
//   setTourSteps,
// } from '../../../../../../actions/tourActions';
// import WelcomeTourSteps from '../../../tour/steps/WelcomeTourSteps';
import { SEARCH } from '../../../../../../constants/paths';
import Pop from '../../../pop/Pop';

const useStyles = makeStyles((theme) => ({
  menuContent: {
    color: theme.palette.primary.light,
    minHeight: '100px',
  },
  chipPad: {
    padding: '16px',
    justifyContent: 'flex-end',
  },
  enablerMenu: {
    width: '100%',
    padding: 0,
  },
  enablerItem: {
    '&:nth-child(n+2)': {
      marginTop: '24px',
    },
  },
}));

function LandingMenu() {
  const isLoading = false;

  const classes = useStyles();
  const history = useHistory();

  const [selectedCrossCuttingEnablers, setCrossCuttingEnablers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    area: {},
  });

  const { area } = dialog;

  function toggleSelected(term, state, setState) {
    let tempArr = [];
    if (state.includes(term)) {
      tempArr = state.filter((word) => word !== term);
      setState([...tempArr]);
    } else {
      setState((prev) => [...prev, term]);
    }
  }

  const createFilter = (filterId, values) => ({
    filter_id: filterId,
    value: values.map((value) => ({
      text: value,
      meta: {},
    })),
    type: 'com.artesia.search.facet.FacetSimpleFieldResponse',
    include: true,
    label: 'label',
  });

  const searchGuided = () => {
    // Generate the correct filters from the states above for area and cross cutting enablers.

    const selectedFilters = [
      createFilter('CRWS.TAB.FIELD.DOMAIN_TYPE', selectedCrossCuttingEnablers),
      createFilter('SEMOD.TAB.FIELD.AREA', selectedAreas),
    ];

    history.push(SEARCH);
    const encodedFilters = window.btoa(JSON.stringify(selectedFilters));

    history.push({
      pathname: SEARCH,
      search: `term=*&filters=${encodedFilters}`,
    });
  };

  const { chipPad, menuContent, enablerMenu, enablerItem } = classes;
  const [open, setOpen] = useState(true);

  const theme = useTheme();

  // AreaMenu info will populate from database I think
  const areaInfo = [
    {
      areaName: 'Digital Engineering',
      bgcolor: theme.cards.type1,
      shortDescription:
        'Digital Engineering (DE) will require new methods, processes, and tools, which will change the way the engineering community operates...',
      fullDescription:
        'Digital Engineering (DE) will require new methods, processes, and tools, which will change the way the engineering community operates; however, this shift extends beyond the engineering community with an impact on the research, requirements, acquisition, test, cost, sustainment, and intelligence communities. The digital engineering transformation offers similar positive changes for business operations including acquisition practices, legal requirements, and contracted activities.',
      value: 'Digital Engineering',
      sourceLink: '/asset/c92082c87e4e8bf30d4b8d51dd82bbf6a3aca8c7',
    },
    {
      areaName: 'Mission Engineering',
      bgcolor: theme.cards.type2,
      shortDescription:
        'An analytical and data-driven approach to decompose and analyze the constituent parts of a mission in order to identify measurable trade-offs and draw conclusions...',
      fullDescription:
        'Mission Engineering (ME) is an analytical and data-driven approach to decompose and analyze the constituent parts of a mission in order to identify measurable trade-offs and draw conclusions. Based on the question asked, and the level of understanding of a given scenario and related contexts, an ME analysis (part of a study) may hypothesize a new concept, system, technology, or tactics that may yield superior “value” in a future military operation. The ME practitioner then designs an analytical experiment to measure and compare the baseline approach to complete the mission to each alternative case (also referred to as a trial case).',
      value: 'Mission Engineering',
      sourceLink: '/asset/66485d6631824dc8d0f5f79d76f55c8bb8608ac6',
    },
    {
      areaName: 'MOSA',
      bgcolor: theme.cards.type3,
      shortDescription:
        'Provides an integrated business and technical strategy for competitive and affordable acquisition and sustainment of a new or legacy system...',
      fullDescription:
        'Modular Open Systems Approach (MOSA) provides an integrated business and technical strategy for competitive and affordable acquisition and sustainment of a new or legacy system (or a component within a new or legacy system) over the system life cycle. The modular approach uses an architecture that separates the system into major functions and elements, which work together across interfaces in conformance with widely supported, consensus-based standards.',
      value: 'MOSA',
      sourceLink: '/asset/d5fde446c51724635c26d15f30477395a4ee30c3',
    },
    {
      areaName: 'Software Engineering',
      bgcolor: theme.cards.type4,
      shortDescription:
        'Software is a part of almost everything with which we interact in our daily lives, either directly through embedded computation in the objects around us or indirectly through the use of information technology...',
      fullDescription:
        'The rise of electronics, computing, and networking has forever transformed the way we live: software is a part of almost everything with which we interact in our daily lives, either directly through embedded computation in the objects around us or indirectly through the use of information technology through all stages of design, development, deployment, and operations. Software is an enduring capability that must be supported and continuously improved throughout its life cycle. To maintain advantage, DoD needs to procure, deploy, and update software that works for its users at the speed of mission need, executing more quickly than our adversaries. DoD must streamline its acquisition process and transform its culture to enable effective delivery and oversight of multiple types of software-enabled systems, at scale, and at the speed of relevance.',
      value: 'Software Engineering',
      sourceLink: '/asset/2fa21facb1304cbab1023f7934188cd7cb0cdbbf',
    },
  ];

  const keyEnablers = [
    { title: 'Architecture', value: 'Architecture', color: theme.cards.type1 },
    {
      title: 'Engineering Workflow',
      value: 'Engineering Workflow',
      color: theme.cards.type2,
    },
    {
      title: 'Model-Based Systems Engineering',
      value: 'Model-Based Systems Engineering',
      color: theme.cards.type3,
    },
    {
      title: 'SOS/Enterprise Collaboration',
      value: 'SoS/Enterprise Collaboration',
      color: theme.cards.type4,
    },
    {
      title: 'Value Engineering',
      value: 'Value Engineering',

      color: theme.cards.type1,
    },
    {
      title: 'Workforce Culture',
      value: 'Workforce Culture',
      color: theme.cards.type2,
    },
  ];

  return isLoading ? (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Slide direction="right" in={open} unmountOnExit mountOnEnter>
          <Stack
            tour="cross-cutting-enabler-menu"
            direction={{ xs: 'column' }}
            spacing={3}
            style={{
              padding: open ? 24 : 12,
              width: '100%',
            }}
            justifyContent="flex-start"
          >
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.primary.textAlt,
              }}
            >
              Cross-Cutting Key Enablers
            </Typography>
            <List className={enablerMenu}>
              {keyEnablers.map((keyEnabler) => (
                <Pop
                  key={`keyanabler-${keyEnabler.value}`}
                  type="tooltip"
                  content="Click to select at least one Key Enabler"
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                >
                  <ListItemButton
                    className={`${menuContent} ${enablerItem}`}
                    onClick={() => {
                      toggleSelected(
                        keyEnabler.value,
                        selectedCrossCuttingEnablers,
                        setCrossCuttingEnablers
                      );
                    }}
                    sx={{
                      bgcolor: keyEnabler.color,
                      padding: '24px',
                      borderRadius: '24px',
                      '&:hover, &:focus': {
                        bgcolor: keyEnabler.color,
                        // filter: 'brightness(1.15)',
                        outline: `3px solid ${theme.palette.primary.dark}`,
                      },
                      '&.MuiListItemButton-root.Mui-selected': {
                        bgcolor: darken(keyEnabler.color, 0.3),
                        outline: `3px solid ${theme.palette.primary.dark}`,
                      },
                    }}
                    key={keyEnabler.value}
                    selected={selectedCrossCuttingEnablers.includes(
                      keyEnabler.value
                    )}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h4">{keyEnabler.title}</Typography>
                      }
                    />
                    <ListItemIcon sx={{ color: 'primary.light' }}>
                      {selectedCrossCuttingEnablers.includes(
                        keyEnabler.value
                      ) ? (
                        <CheckIcon fontSize="large" />
                      ) : null}
                    </ListItemIcon>
                  </ListItemButton>
                </Pop>
              ))}
            </List>
            <Button
              sx={{
                color: theme.palette.primary.light,
                bgcolor: theme.palette.primary.main,
                padding: '8px 16px',
                alignSelf: 'flex-end',
                '&:hover, &:focus': {
                  bgcolor: theme.palette.primary.main,
                  filter: 'brightness(1.15)',
                  outline: `3px solid ${theme.palette.primary.dark}`,
                },
              }}
              size="large"
              onClick={() => setOpen(!open)}
              endIcon={<ArrowRightIcon />}
              tour="cross-cutting-enablers-btn-next"
              disabled={selectedCrossCuttingEnablers.length === 0}
            >
              NEXT
            </Button>
          </Stack>
        </Slide>

        <Slide direction="left" in={!open} unmountOnExit mountOnEnter>
          <Stack
            tour="area-menu-list"
            direction={{ xs: 'column' }}
            spacing={3}
            style={{
              padding: 24,
              minHeight: !open ? 400 : 100,
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.primary.textAlt,
              }}
            >
              Focus Areas
            </Typography>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              // spacing={3}
            >
              <Grid container spacing={3}>
                {/* interate through area info */}
                {areaInfo.map((area) => (
                  <Grid item xs={12} md={6} key={area.areaName}>
                    <List sx={{ padding: '0' }}>
                      <Pop type="tooltip" content="Click to select area">
                        <ListItem
                          sx={{
                            padding: '0',
                            borderRadius: '4px',
                            '&.Mui-selected.MuiListItem-root': {
                              bgcolor: area.bgcolor,
                              outline: `3px solid ${theme.palette.primary.dark}`,
                            },
                          }}
                          selected={selectedAreas.includes(area.value)}
                        >
                          <Card
                            sx={{
                              width: '100%',
                              bgcolor: area.bgcolor,
                              '&:hover, &:focus': {
                                bgcolor: area.bgcolor,
                                filter: 'brightness(1.15)',
                                outline: `3px solid ${theme.palette.primary.dark}`,
                              },
                            }}
                          >
                            <CardActionArea
                              onClick={() => {
                                toggleSelected(
                                  area.value,
                                  selectedAreas,
                                  setSelectedAreas
                                );
                              }}
                            >
                              <CardHeader
                                title={
                                  <Typography variant="h3">
                                    <header>{area.areaName}</header>
                                  </Typography>
                                }
                                sx={{ backgroundColor: 'inherit' }}
                              />

                              <CardContent className={menuContent}>
                                {area.shortDescription}
                              </CardContent>
                            </CardActionArea>
                            <CardActions
                              className={chipPad}
                              tour={
                                area.areaName === 'MOSA'
                                  ? 'keyword-buttons'
                                  : null
                              }
                            >
                              {/* Add Dialog popups for information about each area */}

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  color: theme.palette.primary.light,
                                }}
                              >
                                <CheckIcon
                                  fontSize="large"
                                  style={{
                                    visibility: selectedAreas.includes(
                                      area.value
                                    )
                                      ? 'visible'
                                      : 'hidden',
                                  }}
                                />
                                <LinkButton
                                  style={{
                                    fontWeight: 'bold',
                                    color: 'inherit',
                                  }}
                                  variant="h6"
                                  onClick={() =>
                                    setDialog({
                                      open: true,
                                      area,
                                    })
                                  }
                                  underline="hover"
                                >
                                  Learn More
                                </LinkButton>
                              </div>
                            </CardActions>
                          </Card>
                        </ListItem>
                      </Pop>
                    </List>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* fix-later - might need an inverted button variant globally so it's reusable - inlining styles for quickness */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={{
                  color: theme.palette.primary.light,
                  bgcolor: theme.palette.primary.main,
                  padding: '8px 16px',
                  alignSelf: 'flex-start',
                  '&:hover, &:focus': {
                    bgcolor: theme.palette.primary.main,
                    filter: 'brightness(1.15)',
                    outline: `3px solid ${theme.palette.primary.dark}`,
                  },
                }}
                size="large"
                onClick={() => setOpen(!open)}
                startIcon={<ArrowLeftIcon />}
              >
                BACK
              </Button>
              <Button
                size="large"
                variant="outlined"
                endIcon={<SearchIcon />}
                onClick={() => searchGuided()}
                disabled={selectedAreas.length === 0}
              >
                Search
              </Button>
            </div>
          </Stack>
        </Slide>
      </Stack>

      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ ...dialog, open: false })}
        fullWidth
      >
        <DialogTitle sx={{ color: 'primary.light', bgcolor: area.bgcolor }}>
          {area.areaName}
        </DialogTitle>

        <DialogContent
          sx={{
            bgcolor: area.bgcolor,
            color:
              area.bgcolor === theme.cards.type2
                ? theme.palette.primary.light
                : 'inherit',
          }}
        >
          {area.fullDescription}
        </DialogContent>
        <DialogContent
          sx={{
            bgcolor: area.bgcolor,
            color:
              area.bgcolor === theme.cards.type2
                ? theme.palette.primary.light
                : 'inherit',
          }}
        >
          <MatLink
            href={area.sourceLink}
            style={{ color: theme.palette.primary.light }}
          >
            Source link
          </MatLink>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LandingMenu;
