import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';

function SkeletonTable() {
  const skeletonList = [0, 1, 2, 3];

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <colgroup>
            {/** Thumbnail */}
            <col style={{ width: '10%' }} />
            {/** Title */}
            <col style={{ width: '50%' }} />
            {/** Version Date */}
            <col style={{ width: '10%' }} />
            {/** Version */}
            <col style={{ width: '10%' }} />
            {/** Buttons */}
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={75} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={150} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={50} />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {skeletonList.map((id) => (
              <TableRow key={id}>
                <TableCell>
                  <Skeleton variant="rectangular" width={30} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={300} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={10} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width={30} />
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Skeleton variant="rectangular" width={100} />
                    <Skeleton variant="text" width={200} height={30} />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SkeletonTable;
