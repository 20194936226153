import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Link as MatLink,
  Dialog,
  DialogContent
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import StyledAccordion from '../StyledAccordion/StyledAccordion';
import styles from './FAQs.module.css';
import { NOTIFICATIONS } from '../../../../constants/paths';
import { SITE_NAME_ABBR } from '../../constants/constants';
import AcceptanceCriteria from '../AcceptanceCriteria/AcceptanceCriteria';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import NominateResource from '../NominateResource/NominateResource';

function Faqs({ dialog }) {
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const content = (
    <>

      <StyledAccordion title={<Typography>How do I Change my Subscription/Notification Settings?</Typography>}>
        <div className={styles.container}>
          <Typography paragraph variant="body2" align="left">
            The <b>“Manage Notifications”</b> at the left-side Home screen menu
            allows registered users to update their preferences for receiving
            notifications and the frequency of those notifications for
            subscriptions and saved searches from the {SITE_NAME_ABBR}.
          </Typography>
          <Typography paragraph variant="body2" align="left">
            To update your settings, select the following link (you must be
            logged in): <br />
            <MatLink
              tabIndex={0}
              component={Link}
              to={NOTIFICATIONS}
              onClick={() => history.push(NOTIFICATIONS)}
            >
              {`${window.location.origin}${NOTIFICATIONS}`}
            </MatLink>
          </Typography>
          <Typography paragraph variant="body2" align="left">
            Alternatively, you can click on your username in the top right-hand
            corner of the web page and select <b>“Manage Notifications.”</b>
          </Typography>
        </div>
      </StyledAccordion>
      <StyledAccordion title={<Typography>How do I edit my Profile (email address, password, etc.)?</Typography>}>
        <div className={styles.container}>
          <Typography paragraph variant="body2" align="left">
            Registered {SITE_NAME_ABBR} users can change their profile
            information by selecting “Edit Profile” from the left-side Home
            screen menu. You will be directed to a page containing your account
            information and settings. By selecting the <b>“Profile”</b> link
            under your profile picture, and then selecting the <b>“Edit” </b>
            option, you can modify your account information. You can also get to
            this screen by clicking on your username in the top right-hand
            corner of the web page and selecting{' '}
            <b>“Signed in as [your_username].”</b> Please note, you must be
            logged in before these options will appear. Once at this screen, you
            have the option to either update your user information (name, email
            address) and/or password.
          </Typography>
        </div>
      </StyledAccordion>
      <StyledAccordion title={<Typography>Why Register for the {SITE_NAME_ABBR}?</Typography>}>
        <div className={styles.container}>
          <Typography paragraph variant="body2" align="left">
            Registering for the {SITE_NAME_ABBR} allows users to take full
            advantage of all {SITE_NAME_ABBR} services and capabilities. The
            free registration provides users access to all {SITE_NAME_ABBR}{' '}
            capabilities, including the updated resource and saved search
            notifications and resource nomination. Registered users can also
            customize their profile, subscribing to and setting up notifications
            to alert them when new content (e.g., updated versions of favorited
            resources or new items in a saved search) becomes available on the
            site.
            {/* [note: need link/URL for registration] */}
          </Typography>
        </div>
      </StyledAccordion>
      <StyledAccordion
        title={<Typography>How do I suggest documents and references to the {SITE_NAME_ABBR} repository?</Typography>}
      >
        <div className={styles.container}>
          <Typography paragraph variant="body2" align="left">
            If you have suggestions for documents or references in hard copy or
            electronic format that you would like to nominate for inclusion in
            the {SITE_NAME_ABBR} resource repository, use the{' '}
            <MatLink
              tabIndex={0}
              component='button'
              // to={NOMINATE}
              onClick={() => setOpenDialog(true)}
            >
              Nominate Feature
            </MatLink>
            . The nominated resource will be reviewed in accordance with the{' '}
            {SITE_NAME_ABBR} Acceptance Criteria, shown on the{' '}
            <MatLink
              tabIndex={0}
              component='button'
              // to={NOMINATE}
              onClick={() => setOpenDialog(true)}
            >
              Nominate A Resource
            </MatLink>{' '}
            form.
          </Typography>
        </div>
      </StyledAccordion>
      <StyledAccordion
        title={<Typography>Does {SITE_NAME_ABBR} have classified documents in its repository?</Typography>}
      >
        <div className={styles.container}>
          <Typography paragraph variant="body2" align="left">
            No, the {SITE_NAME_ABBR} does not contain classified documents or
            classified resources in its repository. Only unclassified, fully
            publicly released and uncontrolled resources are contained in its
            repository. Those unclassified, publicly released but controlled
            resources (e.g., due to subscription requirements or PII) list only
            titles, applicable abstracts, and the controlling offices’ URL (to
            request access directly).
          </Typography>
        </div>
      </StyledAccordion>
      <Dialog open={openDialog}>
        <DialogTitleWithClose
          help={<AcceptanceCriteria />}
          onClose={() => setOpenDialog(false)}
        >
          Nominate a Resource
        </DialogTitleWithClose>
        <DialogContent>
          <NominateResource isDialog />
        </DialogContent>
      </Dialog>
    </>
  );
  return dialog ? (
    content
  ) : (
    <Card>
      <CardHeader title="FAQs" />
      <CardContent>{content}</CardContent>
    </Card>
  );

}

Faqs.propTypes = {
  dialog: PropTypes.bool,
};
Faqs.defaultProps = {
  dialog: false,
};

export default Faqs;
