import React, { useState, useCallback, useEffect } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const ClipboardCopyIcon = ({ textToCopy, uniqueId, ...otherProps }) => {
  const [copied, setCopied] = useState(false);
  const [copiedTimeout, setCopiedTimeout] = useState();

  const copyCardURL = useCallback(() => {
    document.getElementById(`text-to-copy-${uniqueId}`).select();
    document.execCommand('copy');
    setCopied(true);
    clearTimeout(copiedTimeout);
    setCopiedTimeout(setTimeout(() => setCopied(false), 5000));
  }, [uniqueId, copiedTimeout, setCopied, setCopiedTimeout]);

  useEffect(() => () => {
      clearTimeout(copiedTimeout);
    });

  return (
    <>
      <input
        id={`text-to-copy-${uniqueId}`}
        readOnly
        value={textToCopy}
        style={{ position: 'absolute', left: '-9999px' }}
      />

      <ListItem button onClick={copyCardURL}>
        <ListItemIcon {...otherProps}>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText primary="Copy Link" />
        <ListItemIcon>
          {copied && <CheckIcon style={{ color: 'green' }} />}
        </ListItemIcon>
      </ListItem>
    </>
  );
};

ClipboardCopyIcon.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  uniqueId: PropTypes.string,
};

ClipboardCopyIcon.defaultProps = {
  uniqueId: uuid(),
};

export default ClipboardCopyIcon;
