import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Snackbar,
  Grow,
  FormControlLabel,
  Checkbox,
  Typography,
  Slide,
  useTheme,
  Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import useAuth from '../../../auth/hooks/useAuth';
import { savedUsername } from '../../../auth/functions/savedUsername';
import { LinkButton } from '../../../../components/StyledComponents';
import { DefaultLogo, WhiteLogo } from '../../../../constants/images';
import RegistrationPage from '../RegistrationPage/RegistrationPage';
import Login from '../../hooks/Login';
import { SITE_NAME_ABBR } from '../../constants/constants';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.light,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    margin: theme.spacing(1),
    borderBottom: `3pt solid ${theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.primary.main
      }`,
  },
  logo: {
    width: '300px',
  },
}));

function LoginForm({ onComplete, username: pUsername, password: pPassword }) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [forgotPassword, didForgetPassword] = useState(false);

  const [password, setPassword] = useState(pPassword);
  const [username, setUsername] = useState(pUsername);

  const [rememberMe, setRememberMe] = useState(Boolean(savedUsername.get()));

  const { handleAuthentication, handleAuthenticationError } = useAuth();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const classes = useStyles();

  const [error403, setError403] = useState({ text: '', open: false });

  const [snackBar, setSnackBar] = React.useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    if (rememberMe) {
      savedUsername.set(username);
    } else {
      savedUsername.delete();
    }

    await Login({ username, password }).then((res) => {
      if (res.error) {
        const { error } = res;
        let message = 'You entered an incorrect username or password.';

        if (error.response.status === 403) {
          message =
            'User account is locked. Please contact system administrator or try resetting your password';
          setError403({ text: message, open: true });

          // If the user is locked out (403 error), we want to send the directly to the forgot password screen
          didForgetPassword(true);
        } else if (
          typeof error.response.data === 'object' &&
          'exception_body' in error.response.data
        ) {
          message = error.response.data.exception_body.message;
        }

        handleAuthenticationError('api', error);
        setSnackBar({
          open: true,
          severity: 'error',
          message,
        });
      } else {
        if (onComplete) onComplete();

        handleAuthentication(res.data);
      }
    });
  };

  const handleChange = (e) => {
    setRememberMe(e.target.checked);
  };

  // Return header of modal based on whether the user is signing in, registering, or forgot password
  const determineJsxHeader = () => {
    let JSX;

    if (isRegistering) {
      JSX = (
        <Typography component="h1" variant="h5">
          Registration
        </Typography>
      );
    } else if (forgotPassword) {
      JSX = (
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
      );
    } else {
      JSX = (
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
      );
    }

    return JSX;
  };

  // Return body of modal based on whether the user is signing in, registering, or forgot password
  const determineJsxBody = () => {
    let JSX;

    if (isRegistering) {
      JSX = (
        <Slide direction="left" in={isRegistering} mountOnEnter unmountOnExit>
          <div>
            <RegistrationPage
              onComplete={onComplete}
              setIsRegistering={setIsRegistering}
            />
          </div>
        </Slide>
      );
    } else if (forgotPassword) {
      JSX = (
        <Slide direction="left" in={forgotPassword} mountOnEnter unmountOnExit>
          <div>
            <ForgotPassword
              didForgetPassword={didForgetPassword}
              error403={error403}
            />
          </div>
        </Slide>
      );
    } else {
      JSX = (
        <>
          <form onSubmit={onSubmit} className={classes.form}>
            <TextField
              id="username"
              name="username"
              margin="normal"
              placeholder="Username"
              value={username}
              autoFocus
              type="username"
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              variant="outlined"
              autoComplete="username"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            <input
              type="text"
              autoComplete="username"
              style={{ display: 'none' }}
            />

            <TextField
              id="password"
              placeholder="Password"
              variant="outlined"
              type="password"
              value={password}
              margin="normal"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              fullWidth
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  checked={rememberMe}
                  onChange={handleChange}
                  color={isDark ? 'secondary' : 'primary'}
                />
              }
              label="Remember me"
            />
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  className={classes.submit}
                  disabled={username.length < 1 || password.length < 1}
                  sx={{ color: 'primary.light' }}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      );
    }
    return JSX;
  };

  // Return statement for LoginForm function
  return (
    <>
      <div className={classes.paper}>
        <div className={classes.logoContainer}>
          <img
            src={isDark ? WhiteLogo : DefaultLogo}
            className={classes.logo}
            alt={`${SITE_NAME_ABBR} Logo`}
          />
        </div>

        {determineJsxHeader()}

        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {determineJsxBody()}

          <Grid item style={{ display: 'flex' }}>
            <LinkButton
              disableElevation
              color={isDark ? 'secondary' : 'primary'}
              disableRipple
              disableFocusRipple
              onClick={() => {
                setIsRegistering(!isRegistering);
                didForgetPassword(false);
              }}
            >
              {isRegistering
                ? 'Have an existing user? Sign In'
                : "Don't have an account? Sign Up"}
            </LinkButton>
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            <LinkButton
              disableElevation
              color={isDark ? 'secondary' : 'primary'}
              disableRipple
              disableFocusRipple
              onClick={() => {
                didForgetPassword(!forgotPassword);
                setIsRegistering(false);
              }}
            >
              {forgotPassword
                ? 'Have an existing user? Sign In'
                : 'Forgot Password?'}
            </LinkButton>
          </Grid>
        </Grid>

        <Snackbar
          TransitionComponent={Grow}
          open={snackBar.open}
          autoHideDuration={3000}
          onClose={() =>
            setSnackBar({ open: false, severity: snackBar.severity })
          }
        >
          <Alert severity={snackBar.severity}>{snackBar.message}</Alert>
        </Snackbar>
      </div>
    </>
  );
}

LoginForm.propTypes = {
  onComplete: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string,
};
LoginForm.defaultProps = {
  onComplete: undefined,
  username: savedUsername.get() || '',
  password: '',
};

export default LoginForm;
