import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

function StyledAccordion({
  title,
  children,
  summaryProps,
  detailProps,
  style,
  ...otherProps
}) {
  const Accordion = withStyles((theme) => ({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  }))(MuiAccordion);

  const AccordionSummary = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 10,
      '&$expanded': {
        minHeight: 10,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  }))(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  return (
    <div>
      <Accordion
        elevation={0}
        defaultExpanded
        style={style}
        {...otherProps}
      // style={{ margin: 5, minWidth: '500px' }}
      >
        <AccordionSummary
          {...summaryProps}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        >
          {title}
          {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
          {/* <Grid container alignItems='center' justifyContent='space-between' direction='row'>
            <Grid item>
              <Typography variant="body1" gutterBottom >
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Pop content="admin would be able to edit metadata in the future">
                <span>
                  <Button disabled startIcon={<EditIcon />}>
                    Edit
                  </Button>
                </span>
              </Pop>
            </Grid>
          </Grid> */}
          {/* </div> */}
        </AccordionSummary>
        <AccordionDetails {...detailProps}>
          <> {children}</>
        </AccordionDetails>
      </Accordion>
    </div >
  );
}
StyledAccordion.defaultProps = {
  detailProps: { style: { display: 'block', padding: 0 } },
  summaryProps: {},
  style: {},
};
StyledAccordion.propTypes = {
  title: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  detailProps: PropTypes.object,
  summaryProps: PropTypes.object,
  style: PropTypes.any,
};

export default StyledAccordion;
