import {
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { WhiteLogo } from '../../../../constants/images';
import MenuButtonGroup from '../MenuButtonGroup/MenuButtonGroup';
import SearchBar from '../../../search/components/SearchBar';
import { SITE_NAME, SITE_NAME_ABBR } from '../../constants/constants';
import Pop from '../pop/Pop';

const useStyles = makeStyles((theme) => ({
  app: {
    backgroundColor:
      theme.type === 'dark'
        ? theme.palette.secondary[500]
        : theme.palette.primary[500],
    color: theme.palette.primary.light,
  },
  toolbar: {
    justifyContent: 'space-between',
  },

  logo: {
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({
    title: '',
    open: false,
  });
  return (
    <>
      <AppBar position="static" className={classes.app}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <div className={classes.logo}>
            <Pop type="tooltip" content="Find out about SE Mod BoK">
              <Button
                onClick={() =>
                  setDialog({ title: 'What is SE Mod BoK?', open: true })
                }
                onKeyPress={(event) => {
                  if (event.key === 'Enter')
                    setDialog({ title: 'What is SE Mod BoK?', open: true });
                }}
                role="link"
                tabIndex={0}
              >
                <img
                  src={WhiteLogo}
                  alt="White logo"
                  style={{ height: '120px' }}
                />
              </Button>
            </Pop>
          </div>
          <SearchBar />

          <div className={classes.sectionDesktop}>
            <MenuButtonGroup />
          </div>
          <div
            className={classes.sectionMobile}
            style={{ justifyContent: 'flex-end', width: '100%' }}
          >
            <MenuButtonGroup />
          </div>
        </Toolbar>
      </AppBar>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ ...dialog, open: false })}
        fullWidth
      >
        <DialogTitle sx={{ color: 'primary.light' }}>
          {dialog.title}
        </DialogTitle>
        <DialogContent sx={{ mt: '24px' }}>
          <Typography paragraph>
            {SITE_NAME} ({SITE_NAME_ABBR}) is intended to provide a modular,
            open knowledge management content platform to search and store
            relevant domain knowledge of modernized Systems Engineering
            Practices for DoD stakeholders.
          </Typography>
          <Typography paragraph>
            The initial version of the {SITE_NAME_ABBR} content will include
            publicly accessible best practices, lesson learned, and
            implementation strategies for SE MOD focus areas (Digital
            Engineering, MOSA, SW-AGILE, Mission Engineering). The content will
            allow be regularly updated to provide the community of practice with
            the most recent concepts, terms and implementation strategies for
            emerging modernized SE practices.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
