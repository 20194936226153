import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TextField, Autocomplete } from '@mui/material';
import { connect } from 'react-redux';

import './Filters.css';
import createFilter from '../../functions/createFilter';
import {
  setSelectedFilters,
  setActivePage,
} from '../../models/otmmModel/functions/updateState';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';

const FilterSelect = ({
  filters,
  selectedFilters,
  setSelectedFilters,
  setPendingUpdate,
}) => {
  const [filterValue, setFilterValue] = React.useState('');

  const getCurrentValues = (filterId) =>
    _.chain(selectedFilters)
      .find({ filter_id: filterId })
      .defaultTo(createFilter(filterId))
      .value().value;

  const isSelected = (filterId, value) => {
    const currentValues = getCurrentValues(filterId, selectedFilters);
    if (currentValues.length > 0) {
      const labels = currentValues.map((v) => v.text);
      return labels.includes(value);
    }
    return false;
  };

  function formattedFacetList() {
    const arr = [];
    filters.forEach((filter) => {
      filter.values.forEach((item) => {
        const value = {
          value: item.value,
          id: filter.id,
          type: filter.type,
          count: item.count,
          label: filter.label,
        };

        if (!isSelected(filter.id, item.value.text)) arr.push(value);
      });
    });
    return arr;
  }

  function updateFacets(facet) {
    if (facet !== null) {
      const currentFilters = [...selectedFilters];
      const currentFilter = _.chain(currentFilters)
        .remove(({ filter_id: id }) => id === facet.id)
        .first()
        .defaultTo(createFilter(facet.id, facet.type))
        .value();
      currentFilter.value.push(facet.value);
      currentFilters.push(currentFilter);
      setSelectedFilters(currentFilters);
      setActivePage(1);
      setPendingUpdate(true);
      setFilterValue('');
    }
  }
  return (
    <Autocomplete
      style={{ marginTop: '10px' }}
      inputValue={filterValue}
      aria-label="Filter"
      id="combo-box-demo"
      disableClearable
      options={formattedFacetList()}
      autoHighlight
      getOptionLabel={(option) => `${option.value.text} (${option.count})`}
      groupBy={(option) => option.label}
      onChange={(event, newValue) => {
        updateFacets(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Search Filters"
          fullWidth
          placeholder="Search Filters"
          variant="outlined"
        />
      )}
    />
  );
};

FilterSelect.defaultProps = {};

FilterSelect.propTypes = {
  filters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    otmmState: state.otmmState,
    isSearching: state.otmmState.loading,
    filters: state.otmmState.filters.allFilters,
    selectedFilters: state.otmmState.filters.selected,
    keywords: state.otmmState.otmmParams.keywords,
  });

export default connect(
  mapStateToProps,
  { setSelectedFilters, setPendingUpdate, setActivePage }
)(FilterSelect);
