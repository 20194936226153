import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Tour from 'reactour';
import {
  setTourOpen,
  setTourProps,
  setTourSteps,
} from '../../../../actions/tourActions';
import { HOME } from '../../../../constants/paths';
import { tourDefaultState } from '../../../../reducers/tourReducer';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import WelcomeTourSteps from './steps/WelcomeTourSteps';
import tourStorage from './tourStorage/tourStorage';

const TourGuide = ({
  steps,
  open,
  props,
  setTourSteps,
  setTourOpen,
  setTourProps,
  tourRef,
  name,
}) => {
  const { preventClose } = props;

  const [openWarning, setOpenWarning] = useState(false);
  // const disableBody = (target) => disableBodyScroll(target);
  // const enableBody = (target) => enableBodyScroll(target);
  const routeHome = useRouteMatch({
    path: HOME,
    exact: true,
  });

  const welcomeSteps = WelcomeTourSteps();
  const handleClose = () => {
    setTourOpen(false);
    if (preventClose && name === 'Global Tour') {
      const totalSteps = tourRef.current.props.steps.length;
      const currentStep = tourRef.current.state.current + 1;
      if (currentStep < totalSteps) {
        setOpenWarning(true);
      } else {
        tourStorage.set(false);
      }
    }
    setTourProps({ ...tourDefaultState.props });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && !steps && routeHome) {
      setTourSteps(welcomeSteps);
      setTourProps({
        preventClose: false,
        disableInteraction: false,
        closeWithMask: false,
        showButtons: false,
        startAt: 0,
        accessibilityOptions: { closeButtonAriaLabel: 'Close' },
      });
      setTourOpen(false);
      // setTourOpen(tourStorage.get());
    }
    return () => {
      mounted = false;
    };
  }, [steps, setTourSteps, welcomeSteps, routeHome, setTourProps, setTourOpen]);
  return (
    <>
      <Tour
        {...props}
        onRequestClose={handleClose}
        steps={steps || []}
        rounded={5}
        isOpen={open}
        accessibilityOptions={{ closeButtonAriaLabel: 'Close' }}
      />
      <Dialog open={openWarning}>
        <DialogTitleWithClose
          onClose={() => {
            tourStorage.set(false);
            setOpenWarning(false);
          }}
        >
          Tour closed
        </DialogTitleWithClose>
        <DialogContent>
          <Typography>{`You've closed the tour early would you like to see it again next
              time you log in?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              tourStorage.remove();
              setOpenWarning(false);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              tourStorage.set(false);
              setOpenWarning(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  steps: state.tour.steps,
  open: state.tour.open,
  props: state.tour.props,
  name: state.tour.props.name,
  tourRef: state.tour.props.ref,
});

TourGuide.propTypes = {
  steps: PropTypes.array,
  props: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setTourSteps: PropTypes.func.isRequired,
  setTourOpen: PropTypes.func.isRequired,
  setTourProps: PropTypes.func.isRequired,
  preventClose: PropTypes.bool,
  tourRef: PropTypes.object,
  name: PropTypes.string,
};

TourGuide.defaultProps = {
  steps: undefined,
  preventClose: undefined,
  props: {},
  tourRef: undefined,
  name: undefined,
};

export default connect(mapStateToProps, {
  setTourSteps,
  setTourOpen,
  setTourProps,
})(TourGuide);
