import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import { setDialog } from '../../../../actions/dialogActions';
import { defaultDialogState } from '../../../../reducers/dialogReducer';

// This dialog is triggered using redux. Check dialogReducer

const CrwsDialog = ({
  open,
  title,
  actions,
  component,
  dialogProps,
  setDialog,
  callback,
  hasCloseButton,
}) => {
  const handleClose = () => {
    setDialog(defaultDialogState);
    if (callback) callback();
  };

  return open && title && component ? (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      {...dialogProps}
      onClose={handleClose}
    >
      <DialogTitleWithClose onClose={handleClose}>{title}</DialogTitleWithClose>
      <DialogContent>{component}</DialogContent>
      <DialogActions>
        {actions &&
          actions.map((action) => {
            const key = action.props.children;
            return <div key={key}>{action}</div>;
          })}
        {hasCloseButton && <Button onClick={handleClose}>Close</Button>}
      </DialogActions>
    </Dialog>
  ) : null;
};

CrwsDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  component: PropTypes.any,
  actions: PropTypes.array,
  title: PropTypes.string,
  dialogProps: PropTypes.object,
  callback: PropTypes.func,
  setDialog: PropTypes.func.isRequired,
  hasCloseButton: PropTypes.bool,
};

CrwsDialog.defaultProps = {
  actions: [],
  title: undefined,
  dialogProps: undefined,
  component: undefined,
  callback: undefined,
  hasCloseButton: true,
};

const mapStateToProps = (state) => ({
  dialog: state.dialog,
  open: state.dialog.open,
  component: state.dialog.component,
  actions: state.dialog.actions,
  title: state.dialog.title,
  hasCloseButton: state.dialog.hasCloseButton,
  dialogProps: state.dialog.dialogProps,
});

const mapDispatchToProps = {
  setDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrwsDialog);
