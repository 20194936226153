import {
  Badge,
  IconButton,
  Popover,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Notifications, NotificationsActive } from '@mui/icons-material';
import React, { useState } from 'react';
import { LinkButton } from '../../../../components/StyledComponents';
import notificationStorage from '../../../auth/functions/lastNotificationClient';
import { CURRENT_VERSION, SITE_NAME_ABBR } from '../../constants/constants';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import Pop from '../pop/Pop';
import ReleasePage from '../ReleasePage/ReleasePage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  typography: {},
}));

const NotificationBell = ({ ...otherProps }) => {
  const classes = useStyles();
  const storage = notificationStorage.get();
  const version = (storage && storage.version) || undefined;

  const [isActive, setIsActive] = useState(CURRENT_VERSION !== version);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const handleClick = (event) => {
    setIsActive(false);
    notificationStorage.set({ date: Date.now(), version: CURRENT_VERSION });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const notifications = (
    <div className={classes.root}>
      <Typography className={classes.typography}>
        {SITE_NAME_ABBR} was recently updated!
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <LinkButton color="primary" onClick={handleOpenDialog}>
          View recent changes
        </LinkButton>
      </div>
    </div>
  );

  return (
    <>
      <Pop content="Alerts">
        <div>
          <IconButton {...otherProps} onClick={handleClick} size="large">
            {isActive ? (
              <Badge
                badgeContent={1}
                color="error"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <NotificationsActive />
              </Badge>
            ) : (
              <Notifications />
            )}
          </IconButton>
        </div>
      </Pop>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notifications}
      </Popover>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitleWithClose onClose={() => setOpenDialog(false)}>
          Release Notes
        </DialogTitleWithClose>
        <DialogContent>
          <ReleasePage withAccordion={false} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationBell;
