import { Typography, Alert } from '@mui/material';
import React from 'react';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import { otmm, SES_MAILER_BASE_URL } from '../../../otmm/apis/otmm';
import {
  CRWS_ABSTRACT,
  CRWS_COPYRIGHT,
  CRWS_JUSTIFICATION,
  CRWS_SOURCE_URL,
  CRWS_TITLE,
} from '../../constants/assetFields';
import { SITE_NAME_ABBR } from '../../constants/constants';
import uploadAsset from '../../functions/uploadAsset';
import styles from './NominateResource.module.css';
import NominateResourceForm from './NominateResourceForm';

const NominateResource = () => {
  const { getUserInfo } = useUserInfo();
  const userInfo = getUserInfo();

  const sendEmail = async (values) => {
    const subject = 'New Resource Nomination Received';
    const toAddresses = process.env.REACT_APP_CURATOR_LIST.split(',');
    toAddresses.push(userInfo.email_address);
    const fromAddress = process.env.REACT_APP_FROM_EMAIL;
    const templateValues = {
      username: userInfo.login_name,
      resource: {
        title: values[CRWS_TITLE].value,
        justification: values[CRWS_JUSTIFICATION].value,
        copyright: values[CRWS_COPYRIGHT] ? values[CRWS_COPYRIGHT].value : '',
        summary: values[CRWS_ABSTRACT] ? values[CRWS_ABSTRACT].value : '',
        url: values[CRWS_SOURCE_URL] ? values[CRWS_SOURCE_URL].value : '',
      },
    };
    const emailData = {
      fromAddress,
      subject,
      toAddresses,
      type: 'ses_mailer',
      templateValue: 'Nominate-Artifact',
      templateLocation: 'Nominate-Artifact',
      templateValuesJson: JSON.stringify(templateValues),
    };
    const res = await otmm.post(
      `${SES_MAILER_BASE_URL}/sendEmail`,
      JSON.stringify(emailData)
    );
    return res;
  };

  const handleOnSubmit = (values, files) => {
    const res = uploadAsset({ values, files }).then(() => {
      sendEmail(values);
    });

    return res;
  };

  return (
    <div className={styles.NominateArtifact} data-testid="NominateArtifact">
      <Alert color="info">
        <Typography paragraph>
          All nominated resources will be reviewed in accordance with the{' '}
          {SITE_NAME_ABBR} Acceptance Criteria, which can be viewed at the help
          button in the upper right-hand corner.
        </Typography>
        <Typography paragraph>
          Only resources that are <b>UNCLASSIFIED</b> and approved for{' '}
          <b>UNRESTRICTED PUBLIC RELEASE</b> can be contained the{' '}
          {SITE_NAME_ABBR}{' '}
          repository
        </Typography>
        <Typography paragraph>
          A resource that is <b>UNCLASSIFIED</b> but approved for{' '}
          <b>CONTROLLED ACCESS</b> will only have the unclassified title,
          abstract (as applicable), and URL (Hyperlink) of the controlling
          office (for requesting access) listed in {SITE_NAME_ABBR} repository
        </Typography>
      </Alert>
      <br />
      <NominateResourceForm handleOnSubmit={handleOnSubmit} />
    </div>
  );
};

export default NominateResource;
