import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';
// import { Assignment } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useCardColor from '../../hooks/useCardColor';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import ApprovalIcon from '../ApprovalIcon/ApprovalIcon';
// import AssetAvatar from '../AssetAvatar/AssetAvatar';
import AssetStatus from '../assetStatus/AssetStatus';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';
import controlledAccessThumbnail from '../CRWS/icons/controlledAccess.png';
import noThumbnail from '../CRWS/icons/default.jpg';
import CrwsTitle from '../CrwsTitle/CrwsTitle';
import PdfDialog from '../PdfDialog/PdfDialog';
import ControlledAccessIcon from '../QuickSelectIcons/ControlledAccessIcon/ControlledAccessIcon';
import DownloadButton from '../QuickSelectIcons/DownloadButton/DownloadButton';
import ReviewBoardPage from '../ReviewBoard/ReviewBoardPage';

const NominatedCards = ({ asset }) => {
  const {
    title,
    domains,
    controlledAccess,
    copyright,
    assetMasterContent,
    sources,
    dateImported,
    dateLastUpdated,
  } = asset;
  const dispatch = useDispatch();

  const { OPRShort: oprShort } = sources.values;
  // const { Domain: domain } = domains.values;

  const cardDetails = domains && domains.values ? GetDomainTheme(domains.values) : {};
  const headers = {
    delimiter: <br />,
    fields: [{ name: title.name, value: title.value }],
  };

  const classes = useCardColor(cardDetails.color);
  const { thumbnail } = assetMasterContent || {};

  const tabIndex = 0;

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    dispatch(setPendingUpdate(true));
    setOpen(false);
  };

  const card = (
    <CardActionArea onClick={handleOpenDialog} className={classes.content}>
      <CardHeader
        className={classes.cardHeader}
        // avatar={
        //   <AssetAvatar
        //     domain={domain}
        //     classes={classes}
        //     cardDetails={cardDetails}
        //     elevation={3}
        //   >
        //     <Assignment />
        //   </AssetAvatar>
        // }
        action={<ApprovalIcon reviewBoard={asset.reviewBoard} />}
        title={
          <div style={{ width: '200px' }}>
            <CrwsTitle asset={asset} headers={headers} oprShort={oprShort} />
          </div>
        }
      />
      <div style={{ position: 'relative' }}>
        <CardMedia
          variant="outlined"
          title={title.value}
          className={classes.cardMedia}
          onClick={handleOpenDialog}
          tabIndex={0}
          role="button"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={
              thumbnail ||
              (controlledAccess.value === 'Yes'
                ? controlledAccessThumbnail
                : noThumbnail)
            }
            alt="Thumbnail"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              flexShrink: '0',
            }}
          />
        </CardMedia>{' '}
        <div
          style={{
            position: 'absolute',
            left: 10,
            top: 0,
          }}
        >
          <AssetStatus
            dateImported={dateImported}
            dateLastUpdated={dateLastUpdated}
            cardDetails={cardDetails}
          />
        </div>
      </div>
      <CardContent className={classes.content}>
        <Typography variant="caption">
          Copyright: {copyright.value || 'NA'}
        </Typography>
      </CardContent>
    </CardActionArea>
  );

  return (
    <>
      <div
        role="button"
        tabIndex={tabIndex}
        className={classes.card}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleOpenDialog();
          }
        }}
      >
        <Card raised className={classes.card}>
          {card}
          {/* Place review process here...
            <CardActions className={classes.content}>
            <AssetRating asset={asset} classes={classes} />
          </CardActions> */}
          <CardActions disableSpacing className={classes.content}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ControlledAccessIcon
                controlledAccess={controlledAccess}
                classes={classes}
              />

              <DownloadButton asset={asset} classes={classes} />
              <PdfDialog
                asset={asset}
                classes={{ root: classes.contrastColor }}
              />
            </div>
          </CardActions>
        </Card>
      </div>
      <ReviewBoardPage
        asset={asset}
        open={open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      />

      {/* <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitleWithClose onClose={handleCloseDialog}>
          {title.value || 'Unnamed Resource'}
        </DialogTitleWithClose>
        <DialogContent> */}
      {/* <DetailsButtonGroup asset={asset} />
          {!assetMasterContent.url && (
            <Typography variant="body1">
              This resource is <b>external</b> to {SITE_NAME_ABBR}. To view this
              document select the link in <b>Source URL</b> below
            </Typography>
          )}
          <Divider />
          <Grid container direction="column" style={{ padding: '15px' }}>
            <Grid item>
              <Typography variant="h6">Justification</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {asset.justification.value}
              </Typography>
            </Grid>
          </Grid>
          <AssetDetailsPane disableAccordion asset={asset} /> */}
      {/* <ReviewBoardPage asset={asset} />
        </DialogContent>
      </Dialog> */}
    </>
  );
};

NominatedCards.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default NominatedCards;
