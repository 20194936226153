import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, IconButton } from '@mui/material';
import { Brightness4, BrightnessHigh, Home, Person } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { setTheme } from '../../../../actions/themeActions';
import { HOME, SIGNOUT } from '../../../../constants/paths';
import useUserInfo from '../../../auth/hooks/useUserInfo';
import MenuIconButton from '../MenuButtons/MenuIconButton';
import GuestUserMenuItems from '../MenuButtons/MenuItems/GuestUserMenuItems';
import UserMenuItems from '../MenuButtons/MenuItems/UserMenuItems';
import NominationIconButton from '../NominationIconButton/NominationIconButton';
import NotificationBell from '../NotificationBell/NotificationBell';
import Pop from '../pop/Pop';
// import {
//   setTourOpen,
//   setTourProps,
//   setTourSteps,
// } from '../../../../actions/tourActions';

const MenuStripDivider = () => (
  <div style={{ height: '42px' }}>
    <Divider
      orientation="vertical"
      style={{
        marginLeft: '5px',
        marginRight: '5px',
        backgroundColor: '#e8eaf6',
      }}
    />
  </div>
);

const MenuButtonGroup = ({ themeMode, setTheme }) => {
  const history = useHistory();
  const { isUserGuest, getUserLogin } = useUserInfo();
  const routeSignOut = useRouteMatch({ path: SIGNOUT, exact: true }) !== null;
  const isGuest = isUserGuest();
  const getLogin = getUserLogin();
  const guestMenu = GuestUserMenuItems();
  const UserMenu = UserMenuItems();
  const updateTheme = () => {
    const mode = themeMode === 'light' ? 'dark' : 'light';
    setTheme(mode);
    localStorage.setItem('theme', mode);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div>
        <Pop content="Go Home">
          <div>
            <IconButton
              color="inherit"
              aria-label="Go Home"
              aria-controls="id"
              aria-haspopup="true"
              onClick={() => history.push(HOME)}
              size="large"
            >
              <Home />
            </IconButton>
          </div>
        </Pop>
      </div>

      <div>
        <NominationIconButton
          color="inherit"
          dialogProps={{ fullWidth: true, maxWidth: 'md' }}
        />
      </div>

      <div>
        <NotificationBell color="inherit" />
      </div>
      <div>
        <Pop
          content={
            themeMode === 'light' ? 'Toggle Dark Theme' : 'Toggle Light Theme'
          }
        >
          <div>
            <IconButton
              color="inherit"
              aria-label="Go Home"
              aria-controls="id"
              aria-haspopup="true"
              onClick={updateTheme}
              size="large"
            >
              {themeMode === 'light' ? <Brightness4 /> : <BrightnessHigh />}
            </IconButton>
          </div>
        </Pop>
      </div>
      <MenuStripDivider />

      {isGuest || routeSignOut ? (
        <div>
          <MenuIconButton
            content="Sign In/Register"
            menuItems={guestMenu}
            color="inherit"
          >
            <Person />
          </MenuIconButton>
        </div>
      ) : (
        <MenuIconButton
          component={Button}
          content={`Signed in as ${getLogin}  `}
          menuItems={UserMenu}
          color="inherit"
          startIcon={<Person />}
        >
          {getLogin}
        </MenuIconButton>
      )}
    </div>
  );
};

MenuButtonGroup.propTypes = {
  themeMode: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
});

const mapDispatchToProps = {
  setTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButtonGroup);
