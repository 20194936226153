import { createTheme, useTheme } from '@mui/material/styles';

const Theme = (type) => {
  const primary = '#19b1d3';
  const secondary = '#d7f5ff';
  const isLight = type === 'light';
  const paperColor = isLight ? '#F7FDFE' : '#5e6f73';
  const backgroundColor = isLight ? '#d7f5ff' : '#313A3C';
  const primaryText = isLight ? '#062931' : '#f7fdfe';
  const secondaryText = isLight ? '#09424F' : '#d7f5ff';
  const disabledText = isLight ? '#0E6A7E' : '#8ee3f1';
  const hintText = isLight ? '#19B1D3' : '#63d8ec';
  const textAlt = isLight ? '#19b1d3' : '#F7FDFE';
  const theme = useTheme();
  const customTheme = {
    palette: {
      primary: {
        main: '#19b1d3',
        light: '#F7FDFE',
        dark: '#09424f',
        textAlt,
        // contrastText: '#062931',
      },
      secondary: {
        main: '#e97b00',
        light: '#d7f5ff',
        // dark: '#753e00',
        // contrastText: '#062931',
      },
      background: {
        default: backgroundColor,
        paper: paperColor,
      },
      divider: '#313a3c',
      text: {
        primary: primaryText,
        secondary: secondaryText,
        disabled: disabledText,
        hint: hintText,
      },
      error: {
        main: '#e90700',
        light: '#ff7975',
        dark: '#570300',
        contrastText: '#fff2f2',
      },
    },

    components: {
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor: primary,
            color: '#F7FDFE',
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            marginLeft: '-11px',
            marginRight: '16px',
            width: '100%',
          },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            margin: 'auto',
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: primary,
            padding: '8px 16px',
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: theme.palette.background.paper,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[3],
            fontSize: '1rem',
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
            color: '#F7FDFE',
            border: '3px solid #F7FDFE',
            borderRadius: '.5rem',
          },
          deletable: {
            '&:hover, &:focus': {
              border: `1pt solid ${secondary}`,
              boxShadow: theme.shadows[1],
            },
          },
        },
      },
      MuiCollapse: {
        styleOverrides: {
          wrapperInner: {
            minWidth: '100%',
          },
        },
      },
    },

    props: {
      MuiButton: {
        disableElevation: false,
      },
      MuiTextField: {
        variant: 'standard',
        margin: 'normal',
      },
      MuiSelect: {
        variant: 'outlined',
      },

      MuiCard: {
        raised: true,
        variant: 'outlined',
      },
    },
    cards: {
      type1: '#19D398',
      type2: '#1954D3',
      type3: '#8AA3A8',
      type4: '#19B1D3',
      default: primary,
    },

    typography: {
      subtitle2: {
        fontVariant: 'small-caps',
        fontSize: '14px',
      },
      h3: {
        fontFamily: ['Righteous', 'black'].join(','),
        fontSize: '2.5rem',
      },
      h4: {
        fontFamily: ['Righteous', 'black'].join(','),
        fontSize: '2rem',
      },
    },

    chip: {
      searchTerm: {
        backgroundColor: secondary,
        color: 'black',
        margin: '3px',
        marginTop: '5px',
      },
      filter: {
        color: 'inherit',
        margin: '3px',
        marginTop: '5px',
      },
      keyword: {
        color: 'inherit',
        margin: '3px',
        marginTop: '5px',
      },
    },
  };

  return createTheme(customTheme);
};
export default Theme;
