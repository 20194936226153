import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import NominateResource from '../NominateResource/NominateResource';
import AcceptanceCriteria from '../AcceptanceCriteria/AcceptanceCriteria';
import Pop from '../pop/Pop';
import Restricted from '../../../../components/Restricted/Restricted';
import { USERS } from '../../constants/constants';

const NominationIconButton = ({ dialogProps, ...otherProps }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Restricted userGroups={[USERS]}>
        <Pop content="Nominate a Resource">
          <div>
            <IconButton
              {...otherProps}
              onClick={() => setOpen(true)}
              size="large"
            >
              <AddCircle />
            </IconButton>
          </div>
        </Pop>

        <Dialog open={open} {...dialogProps}>
          <DialogTitleWithClose
            help={<AcceptanceCriteria />}
            onClose={() => setOpen(false)}
          >
            Nominate a Resource
          </DialogTitleWithClose>
          <DialogContent>
            <NominateResource isDialog />
          </DialogContent>
        </Dialog>
      </Restricted>
    </>
  );
};

NominationIconButton.propTypes = {
  dialogProps: PropTypes.object,
};

NominationIconButton.defaultProps = {
  dialogProps: {},
};
export default NominationIconButton;
