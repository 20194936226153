// if you add a route that needs to be sitemap add it in scr/sitemap/routes.js
export const HOME = '/';
export const PROFILEMANAGEMENT = '/user/profile';
export const ASSET = '/asset/:assetId';
export const SEARCH = '/search';
export const SEARCHVAR = '/search/:searchText';

export const SAVED_SEARCH = '/search/saved/:id';
export const SAVED_SEARCHES = '/search/saved';
export const COPYRIGHT = '/copyright';

export const USER_PAGE = '/user';
export const PROFILE = '/user/profile';
export const NOTIFICATIONS = '/user/notifications';
export const SUBSCRIPTIONS = '/user/subscriptions';
export const FAVORITES = '/user/favorites';
export const FEEDBACK = '/feedback';

export const NOMINATE = '/nominate';
export const CONTACT = '/contact';
export const SAVED_SEARCHES_TABLE = '/user/savedsearches';
export const ABOUT = '/about';
export const FAQS = '/faqs';
export const RELEASE_PAGE = '/recentupdates';
export const GLOSSARY_PAGE = '/search/glossary';

export const REVIEW = '/review';
export const REVIEW_NOMINATED = '/review/nominated';
export const REVIEW_BOARD = '/review/board';

export const PASSWORD_REST = '/user/password/reset';

export const UNAUTHORIZED = '/unauthorized';
export const SIGNOUT = '/signout';
export const ACTIVATE_ACCOUNT = '/user/activate/:encodedUsername';

export const LOGIN = '/login';