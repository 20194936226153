import { Settings } from '@mui/icons-material';
import {
  Button as MatButton,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import { setDefaultView } from '../../models/otmmModel/actions/otmmSettingsAction';
import {
  setOrderBy,
  setPageLimit,
  setSortId,
} from '../../models/otmmModel/functions/updateState';

const OtmmSettingsIconButton = ({
  pageLimit,
  sortId,
  sortOrder,
  setOrderBy,
  setSortId,
  setPageLimit,
  setPendingUpdate,
}) => {
  const theme = useTheme();
  const choices = ['25', '50', '100', '150'];
  const sortTypes = ['asc_', 'desc_'];
  const [values, setValues] = useState({
    pageLimit: pageLimit.toString(),
    sortId,
    sortOrder,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const applyChanges = () => {
    setPageLimit(parseInt(values.pageLimit, 10));
    setSortId(values.sortId);
    setOrderBy(values.sortOrder);
    setPendingUpdate(true);
    setAnchorEl(null);
  };
  const sortOptions = [
    { key: 0, text: 'Relevance', value: 'DEFAULT.RELEVANCE' },
    {
      key: 1,
      text: 'Date Last Modified',
      value: 'ARTESIA.FIELD.DATE PROPERTIES LAST MODIFIED',
    },
    { key: 2, text: 'Date Imported', value: 'ARTESIA.FIELD.DATE IMPORTED' },
  ];
  const id = open ? 'action-settings-popover' : undefined;

  const ActionMenu = () => (
    <div style={{ padding: 15 }}>
      <Typography gutterBottom>
        <b>Sort by</b>
      </Typography>
      <Select
        fullWidth
        labelId="select order by"
        aria-label="select order by"
        name="select"
        variant="outlined"
        value={values.sortId}
        color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
        style={{ marginBottom: '15px' }}
        onChange={(e) => setValues({ ...values, sortId: e.target.value })}
      >
        {sortOptions.map((name, index) => (
          <MenuItem id={index} key={name.key} value={name.value}>
            {name.text}
          </MenuItem>
        ))}
      </Select>
      {values.sortId !== 'DEFAULT.RELEVANCE' && (
        <>
          <Typography gutterBottom>
            <b>Order By</b>
          </Typography>
          <RadioGroup
            aria-label="Order by"
            value={values.sortOrder}
            onChange={(e) =>
              setValues({ ...values, sortOrder: e.target.value })
            }
          >
            {sortTypes.map((order, index) => (
              <FormControlLabel
                value={order}
                id={index}
                key={order}
                aria-label={order}
                control={
                  <Radio
                    color={
                      theme.palette.mode === 'dark' ? 'secondary' : 'primary'
                    }
                  />
                }
                label={order === 'asc_' ? 'Ascending' : 'Descending'}
              />
            ))}
          </RadioGroup>
        </>
      )}
      <Typography gutterBottom>
        <b>Results per page</b>
      </Typography>
      <ToggleButtonGroup
        value={values.pageLimit}
        exclusive
        size="large"
        aria-label="Assets per page"
      >
        {choices.map((choice) => (
          <ToggleButton
            key={choice}
            color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
            variant="outlined"
            onClick={(e) =>
              setValues({ ...values, pageLimit: e.target.textContent })
            }
            value={choice}
            aria-label={choice}
          >
            {choice}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <br /> <br />
      <div>
        <MatButton
          color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
          variant="contained"
          aria-label="Apply Changes"
          fullWidth
          onClick={applyChanges}
        >
          Apply Changes
        </MatButton>
      </div>
    </div>
  );

  return (
    <div>
      <IconButton
        color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Settings />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <ActionMenu />
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  totalResults: state.otmmState.pagination.totalResults,
  keywords: state.otmmState.otmmParams.keywords,
  selectedFilters: state.otmmState.filters.selected,
  searchTerm: state.otmmState.otmmParams.term,
  activePage: state.otmmState.pagination.activePage,
  currentPage: state.otmmState.pagination.currentPage,
  sortId: state.otmmState.sort.id,
  sortOrder: state.otmmState.sort.order,
  pageLimit: state.otmmState.pagination.pageLimit,
  searchName: state.otmmState.otmmParams.name,
});

OtmmSettingsIconButton.defaultProps = {};
OtmmSettingsIconButton.propTypes = {
  pageLimit: PropTypes.number.isRequired,
  sortId: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setSortId: PropTypes.func.isRequired,
  setPageLimit: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setPageLimit,
  setPendingUpdate,
  setOrderBy,
  setSortId,
  setDefaultView,
})(OtmmSettingsIconButton);
