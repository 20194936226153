import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import LoginForm from './LoginForm';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
}));

export default function LoginSideBySide() {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={2} md={3} />

      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <LoginForm />
        <Grid item xs={false} sm={2} md={3} />
      </Grid>
    </Grid>
  );
}
