import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchWithRedux } from '../../functions/fetchAssets';
import Assets from '../assets/Assets';
import {
  setSearchTerm,
  setKeywords,
  setSelectedFilters,
} from '../../models/otmmModel/functions/updateState';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';

function Search({
  pendingUpdate,
  fetchWithRedux,
  setSearchTerm,
  setKeywords,
  setSelectedFilters,
  keywords,
  filters,
  setPendingUpdate,
}) {
  const history = useHistory();
  const urlTerm =
    new URLSearchParams(history.location.search).get('term') || '*';

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    const strKeywords = params.get('keywords');
    const strFilters = params.get('filters');

    if (strKeywords) {
      const keywords = strKeywords.split(',');
      setKeywords(keywords);
    }

    if (strFilters) {
      const filters = JSON.parse(window.atob(strFilters));
      setSelectedFilters(filters);
    }

    setSearchTerm(urlTerm);

    setPendingUpdate(true);
  }, [
    fetchWithRedux,
    history,
    setKeywords,
    setSearchTerm,
    setSelectedFilters,
    setPendingUpdate,
    urlTerm,
  ]);

  useEffect(() => {
    let mounted = true;
    if (pendingUpdate && mounted) {
      fetchWithRedux();
    }

    return () => {
      mounted = false;
    };
  }, [pendingUpdate, fetchWithRedux]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // Set search params for filters
      const params = new URLSearchParams(history.location.search);
      const strTerm = params.get('term');
      const encodedFilters = window.btoa(JSON.stringify(filters));

      // Validate so that we don't lose data
      const searchStr = `${strTerm ? `term=${strTerm}` : ''}${
        keywords.length > 0 ? `&keywords=${keywords.join(',')}` : ''
      }${filters.length > 0 ? `&filters=${encodedFilters}` : ''}`;
      history.replace({ search: searchStr });
    }

    return () => {
      mounted = false;
    };
  }, [filters, keywords, filters.length, keywords.length, history]);

  return (
    <Paper style={{ width: '100%' }}>
      <Assets />
    </Paper>
  );
}

Search.propTypes = {
  pendingUpdate: PropTypes.bool.isRequired,
  fetchWithRedux: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setKeywords: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  keywords: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  pendingUpdate: state.otmmState.pendingUpdate,
  keywords: state.otmmState.otmmParams.keywords,
  filters: state.otmmState.filters.selected,
});

export default connect(mapStateToProps, {
  fetchWithRedux,
  setSearchTerm,
  setKeywords,
  setSelectedFilters,
  setPendingUpdate,
})(Search);
