import React from 'react';
import PropTypes from 'prop-types';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useRouteMatch } from 'react-router-dom';
import { Chip, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import {
  removeSelectedFilter,
  removeKeyword,
} from '../../models/otmmModel/functions/updateState';
import { FAVORITES, REVIEW_NOMINATED } from '../../../../constants/paths';

function FiltersBar({
  filters,
  keywords,
  searchTerm,
  selectedFilters,
  removeKeyword,
  removeSelectedFilter,
}) {
  const routeFavorites = useRouteMatch({
    path: FAVORITES,
    exact: true,
  });

  const routeReviewNominated = useRouteMatch({
    path: REVIEW_NOMINATED,
    exact: true,
  });

  const getFieldName = (filterId) => {
    let value = null;
    filters.forEach((filter) => {
      if (filter.id === filterId) value = filter.label;
    });
    return value;
  };
  const theme = useTheme();
  return (
    <div style={{ marginBottom: '5px' }} tour="filters-bar">
      {searchTerm && !routeReviewNominated && !routeFavorites && (
        <Chip
          label={`Search term: ${searchTerm}`}
          // size="small"
          style={theme.chip.searchTerm}
        />
      )}
      {keywords.length !== 0 &&
        keywords.map((item, index) => (
          <Chip // eslint-disable-next-line react/no-array-index-key
            key={index}
            icon={<VpnKeyIcon style={{ color: 'inherit' }} />}
            label={item}
            style={theme.chip.keyword}
            onDelete={() => removeKeyword(item)}
          />
        ))}
      {selectedFilters.length !== 0 &&
        selectedFilters.reduce((acc, selectedFilter) => {
          acc.push(
            selectedFilter.value.map((v) => (
              <Chip
                key={`${selectedFilter.id}, ${v.text}`}
                style={theme.chip.filter}
                label={
                  getFieldName(selectedFilter.filter_id)
                    ? `${getFieldName(selectedFilter.filter_id)}: ${v.text}`
                    : v.text
                }
                onDelete={() => removeSelectedFilter(v, selectedFilter)}
              />
            ))
          );
          return acc;
        }, [])}
    </div>
  );
}

FiltersBar.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  keywords: PropTypes.array.isRequired,
  removeKeyword: PropTypes.func.isRequired,
  removeSelectedFilter: PropTypes.func.isRequired,
};

FiltersBar.defaultProps = {
  searchTerm: null,
};
const mapStateToProps = (state) => ({
  otmmState: state.otmmState,
  totalResults: state.otmmState.pagination.totalResults,
  keywords: state.otmmState.otmmParams.keywords,
  filters: state.otmmState.filters.allFilters,
  selectedFilters: state.otmmState.filters.selected,
  searchTerm: state.otmmState.otmmParams.term,
});

export default connect(mapStateToProps, {
  removeKeyword,
  removeSelectedFilter,
})(FiltersBar);
