import React from 'react';
import { Typography } from '@mui/material';
import { SITE_NAME_ABBR } from '../../constants/constants';

const AcceptanceCriteria = () => (
  <div>
    <Typography variant="body1">
      <b>The resource must be available at no cost and accessible online.</b>
    </Typography>
    <ul>
      <li>
        <i>
          There is no cost for users to access the {SITE_NAME_ABBR}; it is for
          educational purposes only.
        </i>
      </li>

      <li>
        <i>
          The {SITE_NAME_ABBR} cannot circumvent or infringe upon those who own the
          commercial rights to the resource. Subsequently, contingent upon
          copyright permission and acceptance into the repository, no further
          distribution or production is authorized without the user individually
          requesting copyright permission from the resource’s author or
          publisher.
        </i>
      </li>
    </ul>
    <Typography variant="body1">
      <b>
        Only materials that are both unclassified and approved for public
        release (unlimited distribution) are acceptable.
      </b>
    </Typography>
    <ul>
      <li>
        <i>The {SITE_NAME_ABBR} is unclassified and with unlimited access.</i>
      </li>
      <li>
        <i>
          Materials that are unclassified but with limited distribution may be
          nominated, but will not be contained in the repository. A reference
          only (title/limited attributes) will be included, with a source link
          for the user to request access to the material/resource.
        </i>
      </li>
      <li>
        <i>
          At this time, no classified material will be included in/referenced by
          the {SITE_NAME_ABBR}.
        </i>
      </li>
      <li>
        <i>
          No foreign and/or sovereign-owned material that is not available for
          public release, may be nominated
        </i>
      </li>
    </ul>
    <Typography variant="body1">
      <b>
        Both published and unpublished materials are acceptable. While those
        materials that are in draft, review, or other unapproved form as part of
        an approval process are not acceptable.
      </b>
    </Typography>
    <ul>
      <li>
        <i>
          Material that is premature, in draft, and would not be appropriate to
          include until approved for release (e.g., standards, handbooks,
          guidance that come from a formal body (e.g., the International
          Organization for Standardization (ISO), the International Council on
          Systems Engineering (INCOSE), or the Institute of Electrical and
          Electronics Engineers (IEEE)), government (e.g., DoD or the National
          Institute of Standards and Technology (NIST)), or industry (e.g., the
          Radio Technical Commission for Aeronautics (RCTA)). Also unacceptable
          is material for textbooks or similar class materials or handouts. Also
          unacceptable would be material for textbooks or similar class
          materials or handouts.
        </i>
      </li>
      <li>
        <i>
          Material intended for formal acceptance by such a governing body
          should not be accepted in the {SITE_NAME_ABBR} while it is in draft, review,
          or other unapproved form.
        </i>
      </li>
    </ul>
  </div>
);

AcceptanceCriteria.propTypes = {};

export default AcceptanceCriteria;
