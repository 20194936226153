import {
  Button,
  Paper,
  Tab,
  Tabs as MatTabs,
  Typography,
  Alert,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Help, SettingsOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Banner from '../Banner/Banner';
import { SITE_NAME_ABBR } from '../../constants/constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square elevation={10} style={{ padding: '15px' }}>
          {children}
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const Tabs = ({ masterContent, supportingContent, children, color, resourceType }) => {
  const AntTabs = withStyles({
    indicator: {
      backgroundColor: color,
      fontSize: '2pt',
    },

  })(MatTabs);
  const [OpenAnnotate, setOpenAnnotate] = React.useState(false);
  const toggleAnnotateHelp = () => {
    setOpenAnnotate(!OpenAnnotate);
  };
  const hasFiles = useMemo(() => masterContent || supportingContent.length > 0 || (resourceType.values ? resourceType.values.ResourceType.values.includes('Websites') : false), [masterContent, supportingContent.length, resourceType.values]);

  const [value, setValue] = React.useState(hasFiles ? 'Viewer' : 'Details');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const viewer = useRef(null);

  // useEffect(() => {
  //   let mounted = true;
  //   if (masterContent && hasFiles && mounted) {
  //     WebViewer(
  //       {
  //         licenseKey: 'XftL17UQqFulSMwnT1m4',
  //         path: '/lib',
  //         initialDoc: masterContent.url,
  //         extension: 'pdf',
  //       },
  //       viewer.current
  //     ).then((instance) => {
  //       // const { annotManager } = instance;
  //       const { docViewer } = instance;
  //       instance.enableElements(['menuOverlay', 'downloadButton']);
  //       // annotManager.setReadOnly(true);

  //       // you must have a document loaded when calling this api
  //       docViewer.on('documentLoaded', function search() {
  //         if (searchTerm && searchTerm !== '*') {
  //           instance.searchTextFull(searchTerm, {
  //             wholeWord: true,
  //           });
  //         }
  //       });
  //     });
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  //   // eslint-disable-next-line
  // }, [masterContent, hasFiles]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <AntTabs
          value={value}
          onChange={handleChange}
          style={{ flexGrow: '1' }}
          aria-label="wrapped label tabs example"
          variant="standard"
          scrollButtons="auto"
          textColor="info"
        >
          {hasFiles && (
            <Tab value="Viewer" label="Viewer" {...a11yProps('Viewer')} />
          )}
          <Tab value="Details" label="Details" {...a11yProps('Details')} />
          <Tab
            value="Relationships"
            label="Relationships"
            {...a11yProps('Relationships')}
          />
          <Tab
            value="References"
            label="References"
            {...a11yProps('Details')}
          />
        </AntTabs>

        <div style={{ flexGrow: 1, textAlign: 'right' }}>
          {hasFiles && value === 'Viewer' && (
            <>
              <Button
                color="inherit"
                endIcon={<Help />}
                onClick={toggleAnnotateHelp}
              >
                Help with Annotations
              </Button>
            </>
          )}
        </div>
      </div>
      {hasFiles && value === 'Viewer' && (
        <>
          <Banner
            title="How to Annotate"
            open={OpenAnnotate}
            handler={toggleAnnotateHelp}
          >
            <>
              <Typography paragraph>
                Change the <b>viewer</b> from view mode to <b>annotate</b> mode
                by choosing the <b>Annotate</b> button in the viewer toolbar.
                Once in annotate mode, make as many annotations as needed using
                the built-in tools. Choose the respective button in the
                annotation toolbar or by pressing one of the following keys on
                the keyboard:{' '}
              </Typography>
              <ul>
                <li>
                  <Typography paragraph>
                    <b>H</b> for Highlighter.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>U</b> for Underline.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>K</b> for Strikeout.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>G</b> for Squiggly.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>N</b> for a new Note.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>T</b> for new Text Box.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>R</b> for Rectangle Tool.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>F</b> for Freehand Drawing.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    <b>E</b> for Eraser.
                  </Typography>
                </li>
              </ul>
              <Typography paragraph>
                To download the annotations made to the document:
              </Typography>
              <ol>
                <li>
                  <Typography paragraph>
                    Choose on <SettingsOutlined style={{ height: '15px' }} />{' '}
                    and choose <b>print</b>.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    Select the desired settings, such as checking the box that
                    says <b>Include annotations</b> and <b>Include comments</b>.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    Choose <b>print</b>.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    On the print screen, select <b>save as PDF</b> from the
                    Destination field, which will save and convert/download the
                    content as a PDF file. Or you can select a printer to print
                    a hardcopy.
                  </Typography>
                </li>
              </ol>
            </>
          </Banner>
          {value === 'Viewer' && (
            <Alert color="info">
              <Typography paragraph>
                Annotations are not saved in {SITE_NAME_ABBR}. Please ensure all work is
                downloaded or printed before navigating away from the page. Once
                you navigate away, all annotations made are removed permanently.
                For help with annotations, click on <b>How to Annotate</b>.{' '}
                <br />
                Trying to download an unaltered version? Choose <b>
                  download
                </b>{' '}
                from the <SettingsOutlined style={{ height: '15px' }} /> menu.{' '}
              </Typography>
            </Alert>
          )}
          {children[0]}
        </>
      )}
      <TabPanel value={value} index="Details">
        {children[1]}
      </TabPanel>

      <TabPanel value={value} index="Relationships">
        {children[2]}
      </TabPanel>
      <TabPanel value={value} index="References">
        {children[3]}
      </TabPanel>
    </>
  );
};

Tabs.propTypes = {
  masterContent: PropTypes.object,
  supportingContent: PropTypes.array,
  color: PropTypes.string,
  children: PropTypes.any,
  resourceType: PropTypes.object,
};

Tabs.defaultProps = {
  color: '',
  children: 'undefined',
  masterContent: undefined,
  supportingContent: [],
  resourceType: undefined
};

export default Tabs;
