import {
  Button as MatButton,
  IconButton,
  Paper,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Zoom,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

function Pop({
  content,
  type,
  children,
  id,
  anchorOrigin,
  hover,
  transformOrigin,
  label,
  isOpen,
  ...otherProps
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = isOpen || Boolean(anchorEl);

  const Element = useMemo(() => {
    switch (type) {
      case 'button':
        return (
          <MatButton aria-describedby={id} {...otherProps} onClick={handleOpen}>
            {children}
          </MatButton>
        );

      case 'iconButton':
        return (
          <Pop content={label}>
            <IconButton
              aria-describedby={id}
              {...otherProps}
              onFocus={hover ? handleOpen : null}
              onMouseOver={hover ? handleOpen : null}
              onClick={!hover ? handleOpen : null}
              size="large"
            >
              {children}
            </IconButton>
          </Pop>
        );

      case 'tooltip':
        return (
          <Tooltip
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
            enterDelay={300}
            title={content}
          >
            {children}
          </Tooltip>
        );
      case 'toggleButton':
        return (
          <ToggleButtonGroup size="small">
            <ToggleButton
              onClick={handleOpen}
              style={{ width: '32px', height: '32px' }}
              value="settings"
              aria-label="Settings button"
              {...otherProps}
            >
              {children}
            </ToggleButton>
          </ToggleButtonGroup>
        );
      default:
        return null;
    }
  }, [children, id, otherProps, type, content, hover, label]);

  return (
    <>
      {Element}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Paper style={{ padding: '15px' }}>{content}</Paper>
      </Popover>
    </>
  );
}

Pop.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  type: 'tooltip',
  id: Date.now(),
  content: null,
  hover: false,
  label: '',
  isOpen: undefined,
};
Pop.propTypes = {
  hover: PropTypes.bool,
  content: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.any,
  label: PropTypes.any,
  isOpen: PropTypes.bool,
  anchorOrigin: PropTypes.any,
  transformOrigin: PropTypes.any,
};

export default Pop;
