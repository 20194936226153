import PropTypes from 'prop-types';
import React from 'react';

const ImageViewer = ({ asset, ...otherProps }) => {
  const { rendition_content: renditionContent } = asset;
  const { url } = renditionContent.pdf_preview_content;

  return <embed type="image/jpg" src={url} width="100%" {...otherProps} />;
};

ImageViewer.propTypes = { asset: PropTypes.object.isRequired };

export default ImageViewer;
