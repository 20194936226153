import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Typography } from '@mui/material';
import StyledAccordion from '../StyledAccordion/StyledAccordion';

function ReleasePage({ withAccordion }) {
  const releases = [
    {
      title: 'Version 1.2.1',
      'Minor Fixes and Bugs': [
        'Updated color theming',
        'Updated User Profile Page with new look.',
        'PDF Viewer will now automatically change with the site theme.',
      ],
    },
    {
      title: 'Version 1.2',
      Features: [
        'Reference links added to all resources where applicable',
        'Usernames are no longer case sensitive.',
        'Landing page updated.',
        'Search page updated to have more concise verbiage and pagination at the bottom of search results as well as top.',
        'Review board added for applicable users.',
        'Two Step process for registration implemented',
      ],
      'Bug Fixes': [
        'Search not returning to the first page after submitting a new search.',
      ],
    },
    {
      title: 'Version 1.1.1',
      Features: `Added functionality for Review Board members to be able to view unapproved resources (nominated resources).`,
    },
    {
      title: 'Version 1.1',
      Features: [
        'List view redesigned to fit more resources on the screen.',
        'Sign out page added.',
        'Resource graphs have been updated and reformatted for better viewing.',
        'Added download button to both card and list view to download resources without opening them.',
        'A table of references has been added to the details view of resources.',
        'Updates to interface to make it more uniform.',
      ],
      'Bug Fixes': [
        'Fixed a bug with crashing after a long idle period.',
        ' Fixed a bug causing a search to happen when typing with leading spaces.',
        'Fixed a bug that caused the user to be logged out if asset not found.',
      ],
    },
  ];

  function getRelease(release) {
    return Object.keys(release).map((key) => {
      if (key === 'title') return null;
      const values = release[key];
      return (
        <div key={key}>
          <Typography color="primary">{key}</Typography>
          <ul>
            {typeof values !== 'object' ? (
              <li key={key}>{values}</li>
            ) : (
              values.map((value) => <li key={value}>{value}</li>)
            )}
          </ul>
        </div>
      );
    });
  }

  return (
    <div>
      <CardContent>
        {releases.map((release) => {
          const { title } = release;
          return withAccordion ? (
            <StyledAccordion key={title} title={<Typography>{title}</Typography>}>
              <div style={{ padding: '15px' }}>{getRelease(release)}</div>
            </StyledAccordion>
          ) : (
            <div key={title}>
              <Typography variant="h6">{title}</Typography>
              {getRelease(release)}
            </div>
          );
        })}
      </CardContent>
    </div>
  );
}

ReleasePage.defaultProps = {
  withAccordion: true,
};
ReleasePage.propTypes = {
  withAccordion: PropTypes.bool,
};

export default ReleasePage;
