import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, Link as MatLink, Typography } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import {
  FAVORITES,
  HOME,
  NOTIFICATIONS,
  PROFILE,
  SAVED_SEARCHES_TABLE,
} from '../../../../constants/paths';
import DialogTitleWithClose from '../DialogTitleWithClose/DialogTitleWithClose';
import About from '../About/About';
import NominateResource from '../NominateResource/NominateResource';
import AcceptanceCriteria from '../AcceptanceCriteria/AcceptanceCriteria';
import ContactForm from '../ContactPage/ContactForm';
import Faqs from '../FAQs/FAQs';
import CopyrightPage from '../CopyrightPage/CopyrightPage';
import ReleasePage from '../ReleasePage/ReleasePage';

function Sitemap({ onClose }) {
  const history = useHistory();

  const handleClose = (path) => {
    history.push(path);
    if (onClose) onClose();
  };


  const [selected, setSelected] = useState({
    title: 'Terms of Use',
    component: null,
    open: false,
    help: null
  });

  const handleDialogClose = () => {
    setSelected({ ...selected, open: false });
  };

  return (
    <>
      <Typography variant="h6">Main Pages</Typography>

      <ul>
        <li>
          <MatLink component={Link} variant='body2' to={HOME} onClick={() => handleClose(HOME)}>
            Home
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">User Settings and Resources</Typography>
      <ul>
        <li>
          <MatLink
            component={Link}
            variant='body2'
            to={PROFILE}
            onClick={() => handleClose(PROFILE)}
          >
            Profile
          </MatLink>
        </li>
        <li>
          <MatLink
            variant='body2'
            component={Link}
            to={NOTIFICATIONS}
            onClick={() => handleClose(NOTIFICATIONS)}
          >
            Notifications
          </MatLink>
        </li>
        <li>
          <MatLink
            variant='body2'
            component={Link}
            to={FAVORITES}
            onClick={() => handleClose(FAVORITES)}
          >
            Favorites
          </MatLink>
        </li>
        <li>
          <MatLink
            variant='body2'
            component={Link}
            to={SAVED_SEARCHES_TABLE}
            onClick={() => handleClose(SAVED_SEARCHES_TABLE)}
          >
            Saved Searches
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">Contact Us</Typography>
      <ul>
        <li>
          <MatLink
            component='button'
            onClick={() => setSelected({
              title: 'About',
              component: <NominateResource isDialog />,
              open: true,
              help: <AcceptanceCriteria />
            })}
          >
            Nominate a Resource
          </MatLink>
        </li>
        <li>
          <MatLink
            component='button'
            onClick={() => {
              setSelected({
                title: 'Contact Us',
                component: <ContactForm fullWidth variant="outlined" />,
                open: true,
              });
            }}
          >
            Contact Us
          </MatLink>
        </li>
      </ul>
      <Typography variant="h6">Site Information</Typography>
      <ul>
        <li>
          <MatLink
            component='button'
            onClick={() => {
              setSelected({
                title: 'About',
                component: <About dialog />,
                open: true,
              });
            }}
          >
            About
          </MatLink>
        </li>
        <li>
          <MatLink component='button' onClick={() => {
            setSelected({
              title: 'FAQ',
              component: <Faqs dialog />,
              open: true,
            });
          }}>
            FAQs
          </MatLink>
        </li>
        <li>
          <MatLink
            component='button'
            onClick={() => {
              setSelected({
                title: 'Copyright',
                component: <CopyrightPage dialog />,
                open: true,
              });
            }}
          >
            Copyright
          </MatLink>
        </li>
        <li>
          <MatLink
            component='button'
            variant="body2"
            onClick={() => {
              setSelected({
                title: 'Release Page',
                component: <ReleasePage />,
                open: true,
              });
            }}
          >
            Release Notes
          </MatLink>
        </li>
      </ul>
      <Dialog open={selected.open} onClose={handleDialogClose}>
        <DialogTitleWithClose help={selected.help} onClose={handleDialogClose}>
          {selected.title}
        </DialogTitleWithClose>
        <DialogContent>{selected.component}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );

}
Sitemap.defaultProps = {
  onClose: undefined,
};
Sitemap.propTypes = {
  onClose: PropTypes.func,
};

export default Sitemap;
