import React, { Suspense, useState, useRef } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import Loading from './Loading';
import './index.css';
import App from './App';
import useUserInfo from './features/auth/hooks/useUserInfo';
import { SIGNOUT } from './constants/paths';
import LoginPage from './features/Home/components/LoginPage/LoginPage'

const UserAuthorizationCheck = () => {
  const { isUserLoggedIn, isAuthenticating } = useUserInfo();
  const isLoggedIn = isUserLoggedIn();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const history = useHistory();
  const timeout = 30 * 60 * 1000;
  const shortTimeout = 5 * 60 * 1000;

  // useState(async () => {
  //   // Login as Guest if there is no session.
  //   if (!isLoggedIn) await loginAsGuest();
  // });

  const signout = () => {
    setModalIsOpen(false);
    history.push(SIGNOUT);
  };

  // const hasSession = async () =>
  //   otmm
  //     .get('/sessions')
  //     .then(() => true)
  //     .catch(() => false);

  function handleYesClick() {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  }

  function handleNoClick() {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    signout();
  }

  const userIdle = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(signout, shortTimeout);
  };
  return (
    <>
      {isLoggedIn && !isAuthenticating ? (
        <Suspense fallback={<Loading active={isLoggedIn} fullscreen />}>
          <App />
        </Suspense>
      ) : (
        <LoginPage />
      )}
      {(
        <IdleTimer ref={idleTimerRef} timeout={timeout} onIdle={userIdle} />
      )}
      <Dialog open={modalIsOpen} fullWidth aria-label="dialog-title">
        <DialogTitle>Are You Still There?</DialogTitle>
        <DialogContent>
          You have been inactive for 30 minutes, would you like to stay logged
          in?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleYesClick()}>Yes</Button>
          <Button onClick={() => handleNoClick()}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  assets: state.otmmState.assets,
  otmmParams: state.otmmState.otmmParams,
  otmmState: state.otmmState,
  isLoading: state.otmmState.loading,
  defaultView: state.otmmSettings.defaultView,
  otmmError: state.otmmState.error,
});

UserAuthorizationCheck.defaultProps = {};
UserAuthorizationCheck.propTypes = {};

export default connect(mapStateToProps, {})(UserAuthorizationCheck);
