import { useRouteMatch } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../components/AuthProvider';
import logoutAction from './actions/logout';
import error from './actions/error';
import handleAuthResponse from './funcions/handleAuthResponse';
import { SIGNOUT } from '../../../constants/paths';

const useAuth = () => {
  const { dispatch, navigate } = useContext(AuthContext);
  const routeSignOut = useRouteMatch({ path: SIGNOUT, exact: true }) !== null;

  const login = () => {
    navigate('/login');
  };

  const logout = async () => {
    dispatch(logoutAction());
    if (!routeSignOut) window.location.reload();
  };

  const handleAuthentication = (authResponse) => {
    handleAuthResponse({ dispatch, authResponse });
    if (!routeSignOut) window.location.reload();
  };

  const handleAuthenticationError = (errorType, errorIn) => {
    dispatch(error(errorType, errorIn));
  };

  return {
    login,
    logout,
    handleAuthentication,
    handleAuthenticationError,
  };
};

export default useAuth;
