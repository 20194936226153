import { useTheme } from '@mui/material';
import React from 'react';
import DoD from '../img/DoD-Icon-Light-Simple.png';
import DefaultLogo from '../img/logos/default-color-logo.png';
import WhiteLogo from '../img/logos/horizontal-white-1color-logo.png';
import USDRE from '../img/usdre-official-seal-150x150.png';
import { SITE_NAME_ABBR } from '../features/Home/constants/constants';

const USDRELogo = ({ ...otherProps }) => (
  <a href="https://www.cto.mil/" target="blank">
    <img
      src={USDRE}
      alt="Department of Defense Logo"
      style={{ height: '100px' }}
      {...otherProps}
    />
  </a>
);

const DoDLogo = ({ ...otherProps }) => (
  <a href="https://www.defense.gov" target="blank">
    <img
      src={DoD}
      alt="Department of Defense Logo"
      style={{ height: '100px' }}
      {...otherProps}
    />
  </a>
);

const DefaultImage = ({ ...otherProps }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  return (
    <img
      src={isDark ? WhiteLogo : DefaultLogo}
      alt={`${SITE_NAME_ABBR} Logo`}
      style={{
        height: '125px',
      }}
      {...otherProps}
    />
  );
};

export { DefaultLogo, WhiteLogo, DefaultImage, USDRELogo, DoDLogo };
