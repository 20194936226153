import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import {
  Dialog,
  IconButton,
  TextField,
  Typography,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  useTheme,
  InputLabel,
  Alert,
} from '@mui/material';
// import { CloseIcon } from '@material-ui/data-grid';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SaveIcon from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import { otmm } from '../../../otmm/apis/otmm';
import getSearchId from '../../functions/getSearchId';
import createSavedSearch from '../../functions/createSavedSearch';
import getNotificationFrequencies from '../../functions/getNotificationFrequencies';
import './SaveSearch.css';
import { CRWS_DEFAULT } from '../../../../constants/searches';
import {
  FAVORITES,
  REVIEW_NOMINATED,
  SUBSCRIPTIONS,
} from '../../../../constants/paths';
import NotificationFrequencySelector from '../notificationFrequencySelector/NotificationFrequencySelector';
import Restricted from '../../../../components/Restricted/Restricted';
import { USERS } from '../../constants/constants';

const SaveSearch = ({ searchTerm, selectedFilters, keywords }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(undefined);
  const [name, setName] = useState('');

  const [description, setDescription] = useState('');
  const [options, setOptions] = useState(undefined);
  const [errorName, setErrorName] = useState(false);
  const [errorMessageName, setErrorMessageName] = useState('');
  const theme = useTheme();
  const routeSubscriptions = useRouteMatch({
    path: SUBSCRIPTIONS,
    exact: true,
  });

  const routeFavorites = useRouteMatch({ path: FAVORITES, exact: true });
  const routeReviewNominated = useRouteMatch({
    path: REVIEW_NOMINATED,
    exact: true,
  });
  const [frequency, setFrequency] = useState('NEVER');
  const fetchNotificationFrequencies = async () => {
    const frequencies = await getNotificationFrequencies();
    setOptions(frequencies);
  };

  const onOpen = useCallback(async () => {
    setModalOpen(true);
    await fetchNotificationFrequencies();
  }, []);

  const onClose = useCallback(() => {
    setName('');
    setErrorMessageName('');
    setErrorName(false);
    setDescription('');
    setModalOpen(false);
  }, []);

  const setNotifications = async (savedSearchId, freq) => {
    await otmm
      .patch(`custom/searchfrequencies/${savedSearchId}?frequency=${freq}`)
      .catch((err) => {
        setError({
          color: 'error',
          message: err.response
            ? err.response.data.exception_body.message
            : err.message,
        });
      });
  };

  const onSubmit = async () => {
    const searchConfiguration = await otmm.get('/searchconfigurations');
    const searchId = getSearchId(searchConfiguration, CRWS_DEFAULT);
    await createSavedSearch(
      name,
      description,
      searchTerm,
      searchId,
      selectedFilters,
      keywords
    )
      .then(async (res) => {
        const saveSearchId = res.data.saved_search_resource.saved_search.id;
        await setNotifications(saveSearchId, frequency);

        setName('');
        setDescription('');
        onClose();
      })
      .catch((err) => {
        setError({
          color: 'error',
          message: err.response
            ? err.response.data.exception_body.message
            : err.message,
        });
      });
  };

  const onNameChange = (e) => {
    if (e) {
      setName(e);
      setErrorName(false);
      setErrorMessageName('');
    } else {
      setErrorName(true);
      setErrorMessageName('Name is required');
    }
  };
  return (
    <>
      <>
        {!routeSubscriptions && !routeFavorites && !routeReviewNominated && (
          <Restricted userGroups={[USERS]}>
            <span className="save-search-text" tour="save-search-step">
              <Button
                onClick={onOpen}
                color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
                startIcon={<SaveIcon />}
              >
                Save Search
              </Button>
            </span>
          </Restricted>
        )}
        <Dialog open={modalOpen} onClose={onClose} maxWidth="md">
          <form onSubmit={errorName ? onSubmit : null}>
            <DialogTitle>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography>Save Search</Typography>
                </Grid>
                <Grid item xs={1} container justifyContent="flex-end">
                  <IconButton onClick={onClose} size="large">
                    <Close size="medium" style={{ color: '#FFFFFF' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="normal"
                variant="outlined"
                label="Name"
                aria-label="Name"
                onBlur={(e) => onNameChange(e.target.value)}
                error={errorName}
                helperText={errorMessageName}
                fullWidth
              />
              <TextField
                label="Description"
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                aria-label="Description"
                onBlur={(e) => setDescription(e.target.value)}
                fullWidth
              />

              <InputLabel htmlFor="frequency-selector">Notify me</InputLabel>

              <NotificationFrequencySelector
                options={options}
                variant="outlined"
                id="frequency-selector"
                frequency={frequency}
                setFrequency={setFrequency}
                size="large"
              />

              {searchTerm && (
                <div className="search-terms-div" style={{ marginTop: '10px' }}>
                  <Typography variant="body2">
                    <strong>Search Term: </strong>
                    {searchTerm}
                  </Typography>
                </div>
              )}
              {keywords.length !== 0 &&
                keywords.map((item, index) => (
                    <Chip // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      icon={<VpnKeyIcon />}
                      label={item}
                      style={theme.chip.keyword}
                    />
                  ))}
              {selectedFilters.reduce((acc, selectedFilter) => {
                acc.push(
                  selectedFilter.value.map((v, index) => {
                    const key = `${v.text}${index}`;
                    return (
                      <Chip
                        key={key}
                        label={v.text}
                        style={theme.chip.filter}
                      />
                    );
                  })
                );

                return acc;
              }, [])}
              {error && (
                <Alert color={error.color} onClose={() => setError(undefined)}>
                  {error.message}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={onSubmit}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    </>
  );
};

SaveSearch.propTypes = {
  searchTerm: PropTypes.string,
  selectedFilters: PropTypes.array.isRequired,
  keywords: PropTypes.array.isRequired,
};

SaveSearch.defaultProps = {
  searchTerm: undefined,
};

export default SaveSearch;
