import { Paper } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SecureRoute from '../../../components/SecureRoute/SecureRoute';
import {
  ACTIVATE_ACCOUNT,
  ASSET,
  FAVORITES,
  FEEDBACK,
  HOME,
  NOTIFICATIONS,
  PROFILE,
  REVIEW,
  SAVED_SEARCH,
  SAVED_SEARCHES_TABLE,
  SEARCH,
  SEARCHVAR,
  SIGNOUT,
  LOGIN,
  UNAUTHORIZED,
} from '../../../constants/paths';
import Page404 from '../../../pages/Page404';
import Unauthorized from '../../../Unauthorized';
import { TESTERS, USERS } from '../constants/constants';
import Review from '../Review/Review';
import ActivateUser from './ActivateUser/ActivateUser';
import AssetView from './assets/AssetView';
import CrwsDialog from './CrwsDialog/CrwsDialog';
import Footer from './Footer/Footer';
import DrawerContainer from './DrawerContainer/DrawerContainer';
import Favorites from './Favorites/Favorites';
import Feedback from './Feedback/Feedback';
import Filters from './filters/Filters';
import Header from './Header/Header';
import RunGuidedSearch from './LandingPage/components/GuidedSearch/RunGuidedSearch';
import LandingPage from './LandingPage/LandingPage';
import SavedSearch from './SavedSearch/SavedSearch';
import Search from './Search/Search';
import Signout from './Signout/Signout';
import NotificationSettings from './User/ProfileManagement/Pages/NotificationSettings/NotificationSettings';
import UserSettings from './User/ProfileManagement/Pages/UserSettings/UserSettings';
import ViewSaveSearches from './ViewSaveSearches/ViewSaveSearches';
import TourGuide from './tour/TourGuide';
import CookieDisclaimer from './CookieDisclaimer/CookieDisclaimer';
import LoginPage from './LoginPage/LoginPage';

const Home = () => (
  <div>
    <Header />
    <div
      style={{
        minHeight: '75vh',
        padding: 24,
      }}
    >
      <Switch>
        <Route exact path={ASSET}>
          <AssetView />
        </Route>

        <Route path={SAVED_SEARCH} exact>
          <DrawerContainer menuItems={<Filters />}>
            <SavedSearch />
          </DrawerContainer>
        </Route>

        <Route path={SEARCH} exact>
          <DrawerContainer menuItems={<Filters />}>
            <Search />
          </DrawerContainer>
        </Route>
        <Route path={REVIEW}>
          <Review />
        </Route>

        <Route path={SEARCHVAR}>
          <DrawerContainer menuItems={<Filters />}>
            <RunGuidedSearch />
          </DrawerContainer>
        </Route>

        <Route path={HOME} exact>
          <LandingPage />
        </Route>
        <SecureRoute userGroups={[USERS]} path={FAVORITES} exact>
          <Paper>
            <Favorites />
          </Paper>
        </SecureRoute>
        <SecureRoute userGroups={[USERS]} path={SAVED_SEARCHES_TABLE} exact>
          <Paper>
            <ViewSaveSearches />
          </Paper>
        </SecureRoute>

        {/* User Settings */}
        <SecureRoute userGroups={[USERS]} path={PROFILE} exact>
          <Paper>
            <UserSettings />
          </Paper>
        </SecureRoute>
        <SecureRoute userGroups={[USERS]} path={NOTIFICATIONS} exact>
          <Paper>
            <NotificationSettings />
          </Paper>
        </SecureRoute>

        <Route path={SIGNOUT} exact>
          <Paper>
            <Signout />
          </Paper>
        </Route>

        <Route path={ACTIVATE_ACCOUNT} exact>
          <ActivateUser />
        </Route>

        <Route path={UNAUTHORIZED} exact>
          <Unauthorized />
        </Route>
        <SecureRoute userGroups={[TESTERS]} path={FEEDBACK} exact>
          <Paper>
            <Feedback />
          </Paper>
        </SecureRoute>

        <Route path={LOGIN}>
          <Paper>
            <LoginPage />
          </Paper>
        </Route>

        <Route path={HOME}>
          <Paper>
            <Page404 />
          </Paper>
        </Route>
      </Switch>
      <CrwsDialog />
    </div>
    <TourGuide />
    <CookieDisclaimer />
    <Footer position="relative" />
  </div>
);

export default Home;
