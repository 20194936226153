import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { DefaultImage } from '../../../../constants/images';
import { SITE_NAME_ABBR } from '../../constants/constants';

const CopyrightPage = ({ dialog }) => {
  const content = (
    <>
      <Typography variant="h6">
        <b>Copyright</b>
      </Typography>
      <Typography paragraph variant="body1" align="left">
        You may download, view, copy, and/or print documents and graphics
        incorporated in the {SITE_NAME_ABBR} resource repository solely for
        personal, informational, non-commercial purposes. All {SITE_NAME_ABBR}{' '}
        resources are annotated with a copyright type description of either
        Public Domain or Third Party/Used with Permission and, as such, must be
        handled in accordance with their listed copyright type, as follows:
      </Typography>
      <Typography variant="h6">
        <b>Public Domain</b>
      </Typography>
      <Typography paragraph variant="body1" align="left">
        These resources, clearly identified as <b>Public Domain</b>, are not
        protected by intellectual property laws such as copyright, trademark, or
        patent laws. The majority of these resources are U.S. Government works
        that have been approved as publicly available or released without
        restriction and are placed in the public domain (Title 17, Section 105,
        United States Code). Federal laws and agency policies govern the public
        release of U.S. Government information.
      </Typography>
      <Typography variant="h6">
        <b>Third Party/Used with Permission</b>
      </Typography>
      <Typography paragraph variant="body1" align="left">
        These resources, clearly identified as holding a <b>Third Party</b>{' '}
        copyright, are &quot;Used with Permission&quot; within the{' '}
        {SITE_NAME_ABBR} on a limited-use basis granted by the copyright holder,
        and the original copyright holder must be cited appropriately. Requests
        for further use must be expressly granted by the original copyright
        holder in writing.
      </Typography>
    </>
  );
  return dialog ? (
    content
  ) : (
    <>
      <Card>
        <CardHeader title="Copyright Information" />
        <CardMedia>
          <div style={{ textAlign: 'center' }}>
            <DefaultImage />
          </div>
        </CardMedia>
        <CardContent>{content}</CardContent>
      </Card>
    </>
  );
};

CopyrightPage.propTypes = {
  dialog: PropTypes.bool,
};
CopyrightPage.defaultProps = {
  dialog: false,
};

export default CopyrightPage;
