import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Alert,
  Skeleton,
} from '@mui/material';
import { connect } from 'react-redux';
import AssetCards from './AssetCards';
import './Assets.css';
import ActionBar from '../actionBar/ActionBar';
import FiltersBar from '../FiltersBar/FiltersBar';
import AssetDataTable from './AssetDataTable';
import { CARD_VIEW } from '../../../auth/functions/defaultView';
import { ScrollToTopButton } from '../scrollToTop/ScrollToTop';
import { setOtmmError } from '../../models/otmmModel/actions/otmmActions';
import { NOMINATIONS_UNDER_REVIEW } from '../../constants/constants';
import Paginator from '../actionBar/paginator/Paginator';
import ResultsCount from '../actionBar/resultsCount/ResultsCount';

const Assets = ({
  isSearching,
  assets,
  searchName,
  defaultView,
  otmmError,
  setOtmmError,
}) => {
  const searchNames = searchName.split(' ');
  const hasError = Boolean(otmmError);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const color = isDark ? 'secondary' : 'primary';
  return (
    <>
      {isSearching && <LinearProgress />}

      <Paper style={{ padding: '24px', width: '100%' }}>
        {hasError && (
          <Alert
            severity="error"
            style={{ margin: '24px' }}
            onClose={setOtmmError(undefined)}
          >
            An error occurred while loading this page.
          </Alert>
        )}
        {searchName === NOMINATIONS_UNDER_REVIEW && !isSearching ? (
          <>
            <Typography variant="h5">
              <b>{searchName}</b>
            </Typography>
          </>
        ) : (
          searchNames.length &&
          !isSearching && (
            <>
              {searchNames.map((text, index) => {
                const key = `${text}${index}`;
                if (searchNames.length === 1)
                  return (
                    <Typography key={key} variant="h5" color={color}>
                      <b>{text}</b>
                    </Typography>
                  );
                return (
                  <Typography
                    key={key}
                    style={
                      text.charAt(0) === '*'
                        ? { fontVariant: 'small-caps' }
                        : { fontSize: '16px' }
                    }
                    variant={text.charAt(0) === '*' ? 'h5' : 'subtitle2'}
                    color={text.charAt(0) === '*' ? color : 'inherit'}
                    display="inline"
                  >
                    {text.charAt(0) === '*' ? (
                      <b>{text.substring(1)} </b>
                    ) : (
                      <>{text} </>
                    )}
                  </Typography>
                );
              })}
            </>
          )
        )}
        {isSearching && <Skeleton variant="text" width={300} height={50} />}
        <Divider light />
        <a href="#endOfFilterBar" className="skip-filter-bar">
          Jump to Content
        </a>
        <ActionBar />
        <FiltersBar />
        <div id="endOfFilterBar" />
        {useMediaQuery(useTheme().breakpoints.down('lg')) ||
        defaultView === CARD_VIEW ? (
          <Grid container spacing={3} direction="row">
            <AssetCards hasData={assets.length !== 0} />
          </Grid>
        ) : (
          <AssetDataTable />
        )}
        <br />
        <Divider /> <br />
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item>
            <ResultsCount />
          </Grid>
          <Grid item>
            <Paginator />
          </Grid>
        </Grid>
      </Paper>

      <ScrollToTopButton />
    </>
  );
};

Assets.defaultProps = {
  otmmError: undefined,
};

Assets.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  assets: PropTypes.array.isRequired,
  searchName: PropTypes.string.isRequired,
  defaultView: PropTypes.string.isRequired,
  otmmError: PropTypes.any,
  setOtmmError: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  assets: state.otmmState.assets,
  searchName: state.otmmState.otmmParams.name,
  isSearching: state.otmmState.loading,
  otmmState: state.otmmState,
  defaultView: state.otmmSettings.defaultView,
  otmmError: state.otmmState.error,
});

export default connect(mapStateToProps, { setOtmmError })(Assets);
