import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
  Alert,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { registrationApi } from '../../../../../../otmm/apis/otmm';

const UserForm = ({ user }) => {
  const [edit, setEdit] = useState({
    first: false,
    last: false,
    email: false,
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [userInfo, setUserInfo] = useState(user);
  const [changed, setChanged] = useState(false);
  const updateEdit = (name) => {
    setEdit({ ...edit, [name]: !edit[name] });
  };

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    setChanged(true);
  };

  const updateProfileInfo = () => {
    const updatedAuthResponse = JSON.parse(
      localStorage.getItem('authResponse')
    );
    updatedAuthResponse.session_resource.session.user_full_name = `${userInfo.last_name}, ${userInfo.first_name}`;
    updatedAuthResponse.session_resource.session.email = userInfo.email_address;
    localStorage.setItem('authResponse', JSON.stringify(updatedAuthResponse));
  };

  const handleOnClick = async () => {
    setLoading(true);
    await registrationApi
      .post(
        `/users/${userInfo.login_name}`,
        JSON.stringify({
          email: userInfo.email_address,
          first: userInfo.first_name,
          last: userInfo.last_name,
        })
      )
      .then(() => {
        setSnackBar({
          severity: 'success',
          open: true,
          message: 'Information has been updated.',
        });
      })
      .catch(() => {
        setSnackBar({
          severity: 'error',
          open: true,
          message:
            'Failed to update information, please try again later. If problem persists contact administrator.',
        });
      });

    updateProfileInfo();
    setLoading(false);
  };

  return !userInfo ? (
    <CircularProgress />
  ) : (
    <>
      <TextField
        margin="normal"
        label="Username"
        name="login_name"
        fullWidth
        disabled
        value={userInfo.login_name}
      />
      <TextField
        label="First Name"
        margin="normal"
        name="first_name"
        fullWidth
        value={userInfo.first_name}
        disabled={!edit.first}
        variant={edit.first ? 'filled' : 'outlined'}
        color={edit.first ? 'primary' : null}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => updateEdit('first')}>
              <EditOutlined />
            </IconButton>
          ),
        }}
      />
      <TextField
        margin="normal"
        label="Last Name"
        name="last_name"
        value={userInfo.last_name}
        fullWidth
        onChange={handleChange}
        disabled={!edit.last}
        variant={edit.last ? 'filled' : 'outlined'}
        color={edit.last ? 'primary' : null}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => updateEdit('last')}>
              <EditOutlined />
            </IconButton>
          ),
        }}
      />
      <TextField
        label="Email"
        margin="normal"
        name="email_address"
        disabled={!edit.email}
        value={userInfo.email_address}
        onChange={handleChange}
        variant={edit.email ? 'filled' : 'outlined'}
        color={edit.email ? 'primary' : null}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => updateEdit('email')}>
              <EditOutlined />
            </IconButton>
          ),
        }}
      />

      <Collapse in={snackBar.open} style={{ marginBottom: 8, marginTop: 8 }}>
        <Alert
          onClose={() =>
            setSnackBar({ open: false, severity: snackBar.severity })
          }
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Collapse>

      <div style={{ textAlign: 'right' }}>
        <Button
          disabled={!changed || loading}
          color="primary"
          variant="contained"
          onClick={handleOnClick}
        >
          {loading ? 'Updating...' : 'Update'}
        </Button>
      </div>
    </>
  );
};

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserForm;
