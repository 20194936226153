import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = ({
  children,
  active,
  textAlign,
  ProgressIndicator,
  color,
  fullscreen,
}) => {
  const classes = useStyles();

  function RenderContent() {
    return (
      <div style={{ textAlign }}>
        <ProgressIndicator color={color} />
        <div>{children}</div>
      </div>
    );
  }

  function RenderFullscreen() {
    return (
      <Backdrop className={classes.backdrop} open={active}>
        <Paper style={{ padding: '15px' }}>
          <RenderContent />
        </Paper>
      </Backdrop>
    );
  }

  return active && fullscreen ? (
    <RenderFullscreen />
  ) : (
    active && <RenderContent />
  );
};

Loading.propTypes = {
  children: PropTypes.any,
  active: PropTypes.any,
  textAlign: PropTypes.string,
  ProgressIndicator: PropTypes.any,
  color: PropTypes.string,
  fullscreen: PropTypes.bool,
};
Loading.defaultProps = {
  children: null,
  fullscreen: false,
  textAlign: 'center',
  active: true,
  ProgressIndicator: CircularProgress,
  color: 'primary',
};

export default Loading;
