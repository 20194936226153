import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { SITE_NAME_ABBR } from '../../constants/constants';

function UserVsGuestTable() {
  const createRow = (feature, guest, user) => ({ feature, guest, user });

  const rows = [
    createRow(
      `Unlimited access to resources in ${SITE_NAME_ABBR}`,
      <CheckIcon style={{ color: '#03ac13' }} />,
      <CheckIcon style={{ color: '#03ac13' }} />
    ),
    createRow(
      'Save your searches',
      <ClearIcon color="error" />,
      <CheckIcon style={{ color: '#03ac13' }} />
    ),
    createRow(
      'Favorite resources',
      <ClearIcon color="error" />,
      <CheckIcon style={{ color: '#03ac13' }} />
    ),
    createRow(
      'Subscribe for email updates on resources and saved searches when changes occur.',
      <ClearIcon color="error" />,
      <CheckIcon style={{ color: '#03ac13' }} />
    ),
    createRow(
      'Nominate Resources to be included in the BoK',
      <ClearIcon color="error" />,
      <CheckIcon style={{ color: '#03ac13' }} />
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Feature</TableCell>
            <TableCell>Guest Users</TableCell>
            <TableCell>Registered Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.feature}>
              <TableCell component="th" scope="row">
                {row.feature}
              </TableCell>
              <TableCell align="center">{row.guest}</TableCell>
              <TableCell align="center">{row.user}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserVsGuestTable;
