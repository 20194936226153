import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Restricted from '../../../../components/Restricted/Restricted';
import Pop from '../pop/Pop';

const MenuIconButton = ({
  id,
  component: Element,
  content,
  menuProps,
  menuItems,
  children,
  ...otherProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Pop content={content}>
        <div>
          <Element
            aria-controls={anchorEl ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            {...otherProps}
            onClick={handleClick}
          >
            {children}
          </Element>
        </div>
      </Pop>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={id || content}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getcontentanchorel={null}
        onClose={handleCloseMenu}
      >
        {menuItems.map((menuItem, index) => {
          const {
            onClick,
            icon,
            label,
            props,
            restricted,
            accessRoles,
            userGroups,
            listItem,
            divider,
          } = menuItem;
          const key = label ? `${label}${index}` : index;
          if (divider) return <Divider key={key} />;
          const item = listItem ? (
            <ListItem {...props} key={key}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}{' '}
              <ListItemText>{label}</ListItemText>
            </ListItem>
          ) : (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                if (onClick) onClick();
              }}
              {...props}
              key={key}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );

          return (restricted && accessRoles) || (restricted && userGroups) ? (
            <Restricted
              key={key}
              accessRoles={accessRoles}
              userGroups={userGroups}
            >
              {item}
            </Restricted>
          ) : (
            item
          );
        })}
      </Menu>
    </>
  );
};

MenuIconButton.propTypes = {
  content: PropTypes.string.isRequired,
  component: PropTypes.any,
  id: PropTypes.string,
  menuProps: PropTypes.object,
  menuItems: PropTypes.array.isRequired,
  children: PropTypes.any.isRequired,
};

MenuIconButton.defaultProps = {
  component: IconButton,
  menuProps: undefined,
  id: undefined,
};

export default MenuIconButton;
