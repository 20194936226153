import lastNotificationStorageClient from './lastNotificationStorageClient';

const STORAGE_KEY = 'lastNotification';
const notificationStorage = {
  get: () => {
    const lastNotification = lastNotificationStorageClient.getItem(STORAGE_KEY);
    return JSON.parse(lastNotification);
  },
  set: (lastNotification) => {
    lastNotificationStorageClient.setItem(
      STORAGE_KEY,
      JSON.stringify(lastNotification)
    );
  },
  remove: () => {
    lastNotificationStorageClient.removeItem(STORAGE_KEY);
  },
};

export default notificationStorage;
