import React from 'react';
import PropTypes from 'prop-types';

import PdfViewer from '../PdfViewer/PdfViewer';
import ImageViewer from '../ImageViewer/ImageViewer';

const AssetViewer = ({ asset, ...otherProps }) => {
  const { assetMasterContent, rendition_content: renditionContent } = asset;
  const { mimeType } = assetMasterContent;

  const hasFilesAndPreview =
    Object.keys(assetMasterContent).length > 0 &&
    Object.keys(renditionContent).length > 0;

  const PDF = 'application/pdf';

  if (hasFilesAndPreview) {
    switch (mimeType) {
      case PDF:
        return <PdfViewer asset={asset} {...otherProps} />;

      default:
        return <ImageViewer asset={asset} {...otherProps} />;
    }
  }

  return null;
};

AssetViewer.propTypes = { asset: PropTypes.object.isRequired };

export default AssetViewer;
