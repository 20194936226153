import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Divider, Grid, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import getTableDataFromTabularField from '../../hooks/getTableDataFromTabularField';
import { HIDDEN_FIELDS } from '../../constants/assetFields';
import StyledAccordion from '../StyledAccordion/StyledAccordion';
import MetadataTable from '../MetadataTable/MetadataTable';
import ExternalLinkDialog from '../CustomDialog/ExternalLinkDialog';
import Pop from '../pop/Pop';

function getFieldsAndTables(field, hiddenFields) {
  const { name, value, values, id, parentId } = field;
  // If this is a field, return a field type.
  if (value) return { type: 'field', name, value, id };

  // If this field is tabular, get the rows and columns and return table data.
  const tableData = getTableDataFromTabularField({
    fieldValues: values,
    flex: 1,
    exclude: hiddenFields,
  });
  const { columns } = tableData;
  // Return as a field if only one column header...
  if (columns && columns.length === 1) {
    const { headerName, field: colField } = columns[0];

    const rowVal = field.values[headerName.split(' ').join('')].values;

    return { type: 'field', name: headerName, value: rowVal, id: colField };
  }

  return { type: 'table', name, parentId, ...tableData };
}

const sortField = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  const valueA = a.value;
  const valueB = b.value;

  if (typeof valueA === 'object' && typeof valueB !== 'object') {
    return -1;
  }

  if (typeof valueB === 'object' && typeof valueA !== 'object') {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
// function isLink(value) {
//   return (
//     (typeof value === 'string' && value.includes('http://')) ||
//     value.includes('https://') ||
//     value.includes('www.')
//   );
// }

function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

function validateLinkFormat(link) {
  const hasHttp = link.includes('http://') || link.includes('https://');
  if (!hasHttp) return validateLinkFormat(`https://${link}`);
  return link;
}

function AssetDetailsPane({ asset, disableAccordion, include }) {
  const hiddenFields = include
    ? HIDDEN_FIELDS.filter((field) => !include.includes(field))
    : HIDDEN_FIELDS;
  const data = Object.keys(asset).map((key) => getFieldsAndTables(asset[key], hiddenFields));
  const tables = data.filter(
    (obj) =>
      obj.type === 'table' &&
      obj.hasData &&
      !hiddenFields.includes(obj.parentId)
  );
  const fields = data
    .filter(
      (obj) =>
        obj.type === 'field' &&
        obj.value &&
        obj.id &&
        !hiddenFields.includes(obj.id)
    )
    .sort((a, b) => sortField(a, b));

  const displayField = (value) => {
    if (typeof value === 'object') {
      const URLs = value.filter((v) => typeof v === 'string' && validURL(v));

      const text = value.filter((v) => typeof v === 'string' && !validURL(v));

      return (
        <>
          {URLs &&
            URLs.map((url) => (
              <ExternalLinkDialog key={url} tabIndex={0} label={url}>
                {url} url
              </ExternalLinkDialog>
            ))}
          {text && <Typography variant="body2">{text.join(', ')} </Typography>}
        </>
      );
    }
    if (validURL(value))
      return (
        <ExternalLinkDialog tabIndex={0} label={value}>
          {validateLinkFormat(value)}
        </ExternalLinkDialog>
      );

    return <Typography variant="body2">{value}</Typography>;
  };

  const tableCellStyle = { maxWidth: '115px' };

  const detailsGrid = (
    <>
      <Grid container spacing={3} direction="column" style={{ padding: '15px' }}>
        {fields.map((field) => {
          const { id, name, value } = field;
          return (
            <Grid item key={id}>
              <Typography variant="h6">{name}</Typography>
              {displayField(value)}
            </Grid>
          );
        })}
        {tables.length > 0 && <Divider />}
        {tables.map((table) => {
          const { name, parentId } = table;
          return (
            <Grid item key={parentId}>
              <Typography variant="h6">{name}</Typography>
              <MetadataTable table={table} tableCellStyle={tableCellStyle} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );

  return disableAccordion ? (
    <Card>
      <CardHeader title="Details" />
      <CardContent>{detailsGrid}</CardContent>
    </Card>
  ) : (
    <StyledAccordion title={
      <Grid container alignItems='center' justifyContent='space-between' direction='row'>
        <Grid item>
          <Typography variant="body1" gutterBottom >
            <b>Details</b>
          </Typography>
        </Grid>
        <Grid item>
          <Pop content="admin would be able to edit metadata in the future">
            <span>
              <Button disabled startIcon={<EditIcon />}>
                Edit
              </Button>
            </span>
          </Pop>
        </Grid>
      </Grid>}>{detailsGrid}</StyledAccordion>
  );
}

AssetDetailsPane.propTypes = {
  asset: PropTypes.object.isRequired,
  disableAccordion: PropTypes.bool,
  include: PropTypes.array,
};

AssetDetailsPane.defaultProps = {
  disableAccordion: false,
  include: undefined,
};
export default AssetDetailsPane;
