import { EmojiPeople, Person } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from '../../../../../actions/dialogActions';
import LoginForm from '../../LoginPage/LoginForm';
import UserVsGuestTable from '../../UserVsGuestTable/UserVsGuestTable';

export default () => {
  const dispatch = useDispatch();
  return [
    {
      icon: <Person />,
      label: <b>Sign In / Register</b>,
      onClick: () =>
        dispatch(
          setDialog({
            component: <LoginForm />,
            title: 'Sign In / Register',
            open: true,
            dialogProps: { maxWidth: 'sm', fullWidth: true },
          })
        ),
    },
    {
      divider: true,
    },
    {
      icon: <EmojiPeople />,
      label: 'Why Sign Up?',
      onClick: () =>
        dispatch(
          setDialog({
            component: <UserVsGuestTable />,
            title: 'Registration Benefits',
            open: true,
          })
        ),
    },
  ];
};
