import _ from 'lodash';
import { otmm } from '../../otmm/apis/otmm';
import {
  GOVERNMENT_MODEL_ID,
  OPPORTUNITY_MODEL_ID,
  DATA_TYPE_LABEL,
  METADATA_CATEGORY,
  METADATA_FIELD,
  METADATA_TABULAR_FIELD,
} from '../constants/assetFields';
import parseDate from './parseDate';
import createCrwsTabularModel from '../models/CRWSModel';

const models = {};

const loadModelIds = (assets) => Promise.all(
  _.chain(assets)
    .map('metadata_model_id')
    .uniq()
    .value()
    .map(async (modelId) => {
      if (!models[modelId]) {
        const response = await otmm.get(`/metadatamodels/${modelId}`);
        models[modelId] =
          response.data.metadata_model_resource.metadata_model.name;
      }
    })
);

// Returns value from  metadata and converts time to readable string.
const getValue = (v) => {
  const domainValue = v.domain_value;
  if (domainValue) {
    return v.value.display_value;
  }

  if (v.value) {
    const { type } = v.value;
    if (type === 'dateTime') return parseDate(v.value.value);
    return v.value.value;
  }
  return '';
};
// Create an array of values from metadata
const getValues = (v) => {
  const values = v.map((value) => getValue(value));
  return values;
};
// Handles metadataField conversions
const handleField = (metadata) => {
  const { name, id, type, value } = metadata;
  return {
    id,
    name,
    type,
    value: getValue(value),
  };
};

// Handles metadataTableField conversions
const handleTabularField = (metadata) => {
  const { type, name, values, id } = metadata;
  const parentTableId = metadata.parent_table_id;
  const parentTableName = metadata.parent_table_name;
  return {
    name: parentTableName,
    parentId: parentTableId,
    values: {
      [name.replace(/ /g, '')]: {
        id,
        name,
        type,
        values: getValues(values),
      },
    },
  };
};

// Future, may be needed to handle categories if they come back. For now, do nothing.
const handleCategory = (metadata) => {
  if (metadata) return {};
  return {};
};

const handleMetadata = (metadata) => {
  const { type } = metadata;
  switch (type) {
    case METADATA_CATEGORY:
      return handleCategory(metadata);
    case METADATA_FIELD:
      return handleField(metadata);
    case METADATA_TABULAR_FIELD:
      return handleTabularField(metadata);

    default:
      return null;
  }
};

const updateMetadata = (data, assetMetadata) => {
  const { parentId, id, values: dataValues } = data;

  if (parentId) {
    if (assetMetadata[parentId]) {
      const { values } = assetMetadata[parentId];

      return {
        ...assetMetadata,
        [parentId]: {
          ...assetMetadata[parentId],
          values: { ...values, ...dataValues },
        },
      };
    }
    return { ...assetMetadata, [parentId]: { ...data } };
  }
  return { ...assetMetadata, [id]: { ...data } };
};

const getOpportunityModel = (asset) => {
  let assetMetadata = {};
  if (asset.metadata) {
    asset.metadata.metadata_element_list.forEach((element) => {
      assetMetadata = updateMetadata(handleMetadata(element), assetMetadata);
    });
  }

  return createCrwsTabularModel(assetMetadata);
};

const transformAssets = async (assets) => {
  if (assets) {
    await loadModelIds(assets);

    return assets.map((asset) => {
      const modelId = asset.metadata_model_id;
      const assetOpportunityModel = getOpportunityModel(asset, modelId);
      const assetMasterContent = {};
      if (asset.master_content_info) {
        assetMasterContent.mimeType = asset.master_content_info.mime_type;
        assetMasterContent.name = asset.master_content_info.name;
        assetMasterContent.url = asset.master_content_info.url;
        assetMasterContent.size = asset.master_content_info.content_size;
        if (asset.rendition_content && asset.rendition_content.thumbnail_content) {
          assetMasterContent.thumbnail =
            asset.rendition_content.thumbnail_content.url;
          assetMasterContent.thumbnailMime =
            asset.rendition_content.thumbnail_content.mime_type;
        } else {
          assetMasterContent.thumbnail = null;
          assetMasterContent.thumbnailMime = null;
        }
      }

      const assetSupportingContent = _.get(
        asset,
        'asset_content_info.supporting_content',
        []
      ).map(({ mime_type: mimeType, name, url, content_size: size }) => ({
        mimeType,
        name,
        url,
        size,
      }));

      const renditionContent = asset.rendition_content ? asset.rendition_content : {}

      const transformedAsset = {
        ...assetOpportunityModel,
        modelId,
        assetId: asset.asset_id,
        subscribed: asset.subscribed_to,
        deleted: asset.deleted,
        type: { name: DATA_TYPE_LABEL, value: models[asset.metadata_model_id] },
        assetMasterContent,
        assetSupportingContent,
        dateImported: asset.date_imported,
        dateLastUpdated: asset.date_last_updated,
        rendition_content: renditionContent,
      };
      // TODO: Remove when CAPD-101 and CAPD-117 are implemented
      if (
        transformedAsset.modelId === OPPORTUNITY_MODEL_ID ||
        transformedAsset.modelId === GOVERNMENT_MODEL_ID
      ) {
        transformedAsset.numberOfServices = {
          name: 'SAIC Services',
          value: Math.floor(Math.random() * 5),
        };
        transformedAsset.numberOfReuseMaterials = {
          name: 'Reuse Materials',
          value: Math.floor(Math.random() * 5),
        };
      }
      return transformedAsset;
    });
  }
  return [];
};

export default transformAssets;
