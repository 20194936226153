import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// import AssetAvatar from '../AssetAvatar/AssetAvatar';
import CrwsTitle from '../CrwsTitle/CrwsTitle';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import AssetStatus from '../assetStatus/AssetStatus';
import Subtitle from '../CRWS/components/Subtitle';
import AssetRating from '../AssetRating/AssetRating';
import RenderAbstract from '../CRWS/components/renderAbstract';
import GetDomainTheme from '../CRWS/functions/GetDomainTheme';
import noThumbnail from '../CRWS/icons/default.jpg';
import controlledAccessThumbnail from '../CRWS/icons/controlledAccess.png';
import useCardColor from '../../hooks/useCardColor';
import useSubscription from '../../hooks/useSubscription';
import ControlledAccessIcon from '../QuickSelectIcons/ControlledAccessIcon/ControlledAccessIcon';
import LightboxButton from '../QuickSelectIcons/LightboxButton/LightboxButton';
import NotificationIconButton from '../QuickSelectIcons/NotificationIconButton/NotificationIconButton';
import DownloadButton from '../QuickSelectIcons/DownloadButton/DownloadButton';
import PdfDialog from '../PdfDialog/PdfDialog';
import ShareList from '../QuickSelectIcons/ShareList/ShareList';

const SimpleCard = ({ asset }) => {
  const {
    abstract,
    assetId,
    title,
    domains,
    controlledAccess,
    contentMaturityReason,
    copyright,
    assetMasterContent,
    contentMaturityColor,
    versions,
    sources,
    dateImported,
    dateLastUpdated,
    subscribed,
  } = asset;

  const { OPRShort: oprShort } = sources.values;

  const {
    DateEffective: dateEffective,
    DescriptionofVersion: version,
  } = versions.values;
  // const { Domain: domain } = domains.values;

  const { thumbnail } = assetMasterContent || {};
  const [expanded, setExpanded] = React.useState(false);

  const cardDetails = domains && domains.values ? GetDomainTheme(domains.values) : {};

  const tabIndex = 0;

  const { toggleSubscription, isSubscribed } = useSubscription(subscribed);

  const classes = useCardColor(cardDetails.color);
  // const showCard = (viewingSubscribed && isSubscribed) || !viewingSubscribed;

  const handleOpenDialog = () => {
    // setOpenDetails(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const headers = {
    delimiter: <br />,
    fields: [{ name: title.name, value: title.value }],
  };

  return (
    <>
      <div
        role="button"
        tabIndex={tabIndex}
        className={classes.card}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleOpenDialog();
          }
        }}
      >
        <Card raised className={classes.card}>
          <PdfDialog asset={asset}>
            <CardActionArea
              onClick={handleOpenDialog}
              className={classes.content}
            >
              <CardHeader
                className={classes.cardHeader}
                // avatar={
                // <AssetAvatar
                //   domain={domain}
                //   classes={classes}
                //   cardDetails={cardDetails}
                //   elevation={3}
                // />
                // }
                title={
                  <div style={{ width: '200px' }}>
                    <CrwsTitle
                      asset={asset}
                      headers={headers}
                      oprShort={oprShort}
                    />
                  </div>
                }
                action={
                  <VerifiedIcon
                    size={50}
                    reason={contentMaturityReason.value}
                    color={contentMaturityColor.value}
                  />
                }
              />
              <div style={{ position: 'relative' }}>
                <CardMedia
                  variant="outlined"
                  title={title.value}
                  className={classes.cardMedia}
                  onClick={handleOpenDialog}
                  tabIndex={0}
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={
                      thumbnail ||
                      (controlledAccess.value === 'Yes'
                        ? controlledAccessThumbnail
                        : noThumbnail)
                    }
                    alt="Thumbnail"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      flexShrink: '0',
                    }}
                  />
                </CardMedia>{' '}
                <div
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: 0,
                  }}
                >
                  <AssetStatus
                    dateImported={dateImported}
                    dateLastUpdated={dateLastUpdated}
                    cardDetails={cardDetails}
                  />
                </div>
              </div>
              <CardContent className={classes.content}>
                <Subtitle dateEffective={dateEffective} version={version} />
                <Typography variant="caption">
                  Copyright: {copyright.value}
                </Typography>
              </CardContent>
            </CardActionArea>
          </PdfDialog>
          <CardActions className={classes.content}>
            <AssetRating asset={asset} classes={classes} />
          </CardActions>
          <CardActions disableSpacing className={classes.content}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ControlledAccessIcon
                controlledAccess={controlledAccess}
                classes={classes}
              />

              <LightboxButton
                title="Favorites"
                aria-label="Add or remove from Favorites"
                assetId={assetId}
                cardColor={classes}
              />

              <NotificationIconButton
                asset={asset}
                classes={classes}
                cardDetails={cardDetails}
                isSubscribed={isSubscribed}
                toggleSubscription={toggleSubscription}
              />

              <DownloadButton asset={asset} classes={classes} />
              <PdfDialog
                asset={asset}
                classes={{ root: classes.contrastColor }}
              />

              <ShareList asset={asset} classes={classes} />
            </div>

            {abstract.value && (
              <IconButton
                className={classes.expand}
                classes={{ root: classes.contrastColor2 }}
                title="Expand"
                onClick={handleExpandClick}
                size="large"
              >
                <ExpandMore />
              </IconButton>
            )}
          </CardActions>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.content}>
              <RenderAbstract abstract={abstract} classes={classes} />
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </>
  );
};

SimpleCard.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default SimpleCard;
