import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import getNotificationFrequencies from '../../../../../functions/getNotificationFrequencies';
import transformSaveSearchToRows from '../../../../../functions/transformSaveSearchToRows';
import getSavedSearches from '../../../../../functions/getSavedSearches';
import NotificationFrequencySelector from '../../../../notificationFrequencySelector/NotificationFrequencySelector';
import StyledAccordion from '../../../../StyledAccordion/StyledAccordion';
import { fetchSubscriptions } from '../../../../../functions/fetchAssets';
import AssetDataTable from '../../../../assets/AssetDataTable';
import { setOtmmError } from '../../../../../models/otmmModel/actions/otmmActions';

function NotificationSettings({ fetchSubscriptions, assets, setOtmmError }) {
  const [savedSearchesPrivate, setSavedSearchesPrivate] = useState(undefined);
  const [options, setOptions] = useState(undefined);

  const [isLoading, setLoading] = useState({
    SaveSearch: true,
    Subscriptions: true,
  });
  useState(async () => {
    setOptions(await getNotificationFrequencies());
    const rows = await getSavedSearches('private');
    if (rows.error) {
      setOtmmError(rows);
      return;
    }

    setSavedSearchesPrivate(transformSaveSearchToRows(rows));
    await fetchSubscriptions();
    setLoading({ SaveSearch: false, Subscriptions: false });
  });
  const getSearchNotificationRow = useCallback(() => savedSearchesPrivate.map((savedSearch) => savedSearch.frequency !== 'NEVER' ? (
    <TableRow key={savedSearch.id}>
      <TableCell>{savedSearch.name}</TableCell>
      <TableCell>{savedSearch.description}</TableCell>
      <TableCell>
        <NotificationFrequencySelector
          fullWidth
          id={savedSearch.id}
          frequency={savedSearch.frequency}
          options={options}
        />
      </TableCell>
    </TableRow>
  ) : null), [savedSearchesPrivate, options]);

  const hasNotifications = savedSearchesPrivate
    ? savedSearchesPrivate.filter(
      (savedSearch) => savedSearch.frequency !== 'NEVER'
    ).length > 0
    : false;

  const hasSubscriptions = assets && assets.length > 0;

  return (
    <>
      <Card style={{ minHeight: '300px' }}>
        <CardHeader title="Manage Notifications" />
        {isLoading.SaveSearch || isLoading.Subscriptions ? (
          <>
            <LinearProgress color="primary" />
            <Paper
              elevation={5}
              style={{
                minHeight: '600px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isLoading.SaveSearch && isLoading.Subscriptions && (
                <Typography>Getting your notifications...</Typography>
              )}
              {!isLoading.SaveSearch && isLoading.Subscriptions && (
                <Typography>Updating subscriptions...</Typography>
              )}
            </Paper>
          </>
        ) : (
          <CardContent>
            {/* {hasSubscriptions && ( */}
            <StyledAccordion title={<Typography>Subscriptions</Typography>}>
              {isLoading.Subscriptions && <LinearProgress color="primary" />}
              <AssetDataTable />{' '}
              {/*
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Resource Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{getSubscriptionsRow()}</TableBody>
                </Table> */}
            </StyledAccordion>
            {/* )} */}

            {hasNotifications && (
              <StyledAccordion title={<Typography>Save Search Notifications</Typography>}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Save Search Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell>
                        <b>Notification</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{getSearchNotificationRow()}</TableBody>
                </Table>
              </StyledAccordion>
            )}

            {!hasNotifications && !hasSubscriptions && (
              <Typography>You are not receiving any notifications.</Typography>
            )}
          </CardContent>
        )}
      </Card>
    </>
  );
}

NotificationSettings.propTypes = {
  fetchSubscriptions: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired,
  setOtmmError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    assets: state.otmmState.assets,
    pendingUpdate: state.otmmState.pendingUpdate,
  };
}

export default connect(
  mapStateToProps,
  { fetchSubscriptions, setOtmmError }
)(NotificationSettings);
