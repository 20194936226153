import React from 'react';
import _ from 'lodash';
import { Checkbox as MatCheckbox, FormControlLabel } from '@mui/material';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import createFilter from '../../functions/createFilter';
import { setPendingUpdate } from '../../models/otmmModel/actions/otmmActions';
import {
  setSelectedFilters,
  setActivePage,
} from '../../models/otmmModel/functions/updateState';

const getCurrentValues = (filterId, selectedFilters) =>
  _.chain(selectedFilters)
    .find({ filter_id: filterId })
    .defaultTo(createFilter(filterId))
    .value().value;

const isChecked = (filterId, value, selectedFilters) => {
  const currentValues = getCurrentValues(filterId, selectedFilters);
  if (currentValues.length > 0) {
    const labels = currentValues.map((v) => v.text);
    return labels.includes(value);
  }
  return false;
};

const CheckboxFilter = ({
  filterValue,
  selectedFilters,
  setSelectedFilters,
  filter,
  setActivePage,
  setPendingUpdate,
}) => {
  const onChange = (changedFilterId) => (e, data) => {
    const currentFilters = [...selectedFilters];
    const currentFilter = _.chain(currentFilters)
      .remove(({ filter_id: id }) => id === changedFilterId)
      .first()
      .defaultTo(createFilter(changedFilterId, filter.type, [], 'label'))
      .value();

    if (e.target.checked === true || data.checked) {
      currentFilter.value.push(filterValue.value);
    } else {
      _.remove(
        currentFilter.value,
        (value) => value.text === e.target.name || data.value
      );
    }

    if (currentFilter.value.length) {
      currentFilters.push(currentFilter);
    }
    setSelectedFilters(currentFilters);
    setActivePage(1);
    setPendingUpdate(true);
  };
  return (
    <>
      <FormControlLabel
        control={
          <MatCheckbox
            checked={isChecked(
              filter.id,
              filterValue.value.text,
              selectedFilters
            )}
            onChange={onChange(filter.id)}
            name={filterValue.value.text}
          />
        }
        label={`${filterValue.value.text} (${filterValue.count})`}
      />
    </>
  );
};

CheckboxFilter.propTypes = {
  filter: PropTypes.any.isRequired,
  filterValue: PropTypes.any.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setPendingUpdate: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    otmmState: state.otmmState,
    isSearching: state.otmmState.loading,
    filters: state.otmmState.filters.allFilters,
    selectedFilters: state.otmmState.filters.selected,
  });

export default connect(
  mapStateToProps,
  { setSelectedFilters, setPendingUpdate, setActivePage }
)(CheckboxFilter);
