import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import useUserInfo from '../../../../../../auth/hooks/useUserInfo';
import TabPanel from '../../../../TabPanel/TabPanel';
import PasswordChange from '../PasswordChange/PasswordChange';
import UserForm from './UserForm';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function UserSettings() {
  const { getUserInfo } = useUserInfo();

  const user = getUserInfo();

  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const tabs = [
    {
      title: 'User Settings',
      component: user.user_id ? <UserForm user={user} /> : 'Loading...',
    },
    {
      title: 'Change Password',
      component: <PasswordChange />,
    },
  ];

  return (
    <Card>
      <CardHeader title="My Profile" />
      {!user.user_id ? (
        <LinearProgress />
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="user profile settings"
        >
          {tabs.map((tab, i) => (
            <Tab label={tab.title} key={tab.title} {...a11yProps(i)} />
          ))}
        </Tabs>
      )}
      <CardContent>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {tabs.map((tab, i) => (
            <div key={tab.title}>
              <TabPanel value={value} index={i} dir={theme.direction}>
                {tab.component}
              </TabPanel>
            </div>
          ))}
        </SwipeableViews>
      </CardContent>
    </Card>
  );
}

export default UserSettings;
