import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten, SvgIcon, Typography } from '@mui/material';
import Pop from '../pop/Pop';

const VerifiedIcon = ({
  variant: pVariant,
  size: fontSize,
  color,
  reason,
  ...otherProps
}) => {
  const id = new Date();
  const style = {
    fontSize,
    color,
  };
  // Don't try and make a badge for something that doesn't have any ratings...
  if (!color || !reason || color.trim() === '' || reason.trim() === '')
    return null;

  const content = () => {
    if (reason) {
      const reasonList = reason.split('|');
      return (
        <>
          {reasonList.map((res, index) => {
            const isFirst = index === 0;
            const isLast = index === reasonList.length - 1;

            return (
              <div key={res}>
                {isFirst && (
                  <Typography color="primary" variant="h6">
                    <b>{res}</b>
                  </Typography>
                )}
                {!isLast && !isFirst && (
                  <Typography color="inherit" variant="body2">
                    {res}
                  </Typography>
                )}
                {isLast && (
                  <Typography color="secondary" variant="body1">
                    <b> {res}</b>
                  </Typography>
                )}
              </div>
            );
          })}
        </>
      );
    }
    return 'No reason given';
  };

  return (
    <Pop id={`contentBadge${id}`} type="tooltip" content={content()}>
      <SvgIcon style={style} {...otherProps}>
        <defs>
          <linearGradient id={id} y2="1" x2="1" x1="1" y1="0.1433">
            <stop stopColor={darken(color, 0.1)} offset="0" />
            <stop stopColor={lighten(color, 0.3)} offset="0.4817" />
            <stop stopColor={darken(color, 0.1)} offset="1" />
          </linearGradient>
        </defs>
        <g>
          <g>
            <path
              fill={`url(#${id})`}
              d="M18.49,9.88l0.08-0.85l0.18-1.95l-1.9-0.43l-0.84-0.19l-0.44-0.74l-0.99-1.68L12.79,4.8L12,5.14L11.21,4.8 L9.42,4.03L8.43,5.71L7.99,6.45L7.15,6.64l-1.9,0.43l0.18,1.94l0.08,0.85l-0.56,0.65l-1.29,1.48l1.29,1.47l0.56,0.65l-0.08,0.85 l-0.18,1.96l1.9,0.43l0.84,0.19l0.44,0.74l0.99,1.67l1.78-0.77L12,18.85l0.79,0.34l1.78,0.77l0.99-1.68l0.44-0.74l0.84-0.19 l1.9-0.43l-0.18-1.95l-0.08-0.85l0.56-0.65l1.29-1.47l-1.29-1.47L18.49,9.88z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"
            />
            <polygon
              fill={darken(color, 0.2)}
              points="10.09,13.75 7.77,11.42 6.29,12.91 10.09,16.72 17.43,9.36 15.95,7.87"
            />
          </g>
        </g>
      </SvgIcon>
    </Pop>
  );
};

VerifiedIcon.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.number,
  rating: PropTypes.string,
  color: PropTypes.string,
  reason: PropTypes.string,
};
VerifiedIcon.defaultProps = {
  color: undefined,
  reason: undefined,
  rating: '0',
  variant: undefined,
  size: 24,
};

export default VerifiedIcon;
